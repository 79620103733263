var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analysis-data-container",staticStyle:{"width":"100%","margin":"10px 0"}},[_c('DataTable',{staticClass:"p-datatable data-table-container",attrs:{"id":"result-data-table","value":_vm.tableDatas,"scrollable":true,"scrollHeight":'500px',"showGridlines":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"space-between"}},[_c('div',[_c('span',{staticClass:"m-0 table-title"},[_vm._v("분석기간")])]),_c('div',[_c('button',{staticClass:"btn btn-sm btn-outline-secondary",attrs:{"type":"button"},on:{"click":_vm.exportToCSV}},[_vm._v(" Export ")])])])]},proxy:true}])},[_c('Column',{style:({ width: '150px' }),attrs:{"field":"regDt","header":"날짜","reorderableColumn":false,"headerStyle":{ display: 'flex', justifyContent: 'center', alignItems: 'center' },"bodyStyle":{
                'font-size': '13px',
                'font-weight': 'bold',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
            }}}),_vm._l((_vm.dynamicColumns),function(col,index){return _c('Column',{key:index,attrs:{"field":col.key,"reorderableColumn":false,"headerStyle":{ display: 'flex', justifyContent: 'center', alignItems: 'center' },"bodyStyle":{ display: 'flex', justifyContent: 'center', alignItems: 'center' }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[(col.unit)?_c('div',[_vm._v(_vm._s(col.key)+"("+_vm._s(col.unit)+")")]):_vm._e()])]},proxy:true}],null,true)})})],2),_c('div',{staticClass:"splitDiv"}),_c('DataTable',{staticClass:"p-datatable data-table-container",attrs:{"id":"comparison-data-table","value":_vm.comparisonTableDatas,"scrollable":true,"scrollHeight":'500px',"showGridlines":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"space-between"}},[_c('div',[_c('span',{staticClass:"m-0 table-title"},[_vm._v("비교기간")])]),_c('div',[_c('button',{staticClass:"btn btn-sm btn-outline-secondary",attrs:{"type":"button"},on:{"click":_vm.exportComparisonToCSV}},[_vm._v(" Export Comparison ")])])])]},proxy:true}])},[_c('Column',{style:({ width: '150px' }),attrs:{"field":"regDt","header":"날짜","reorderableColumn":false,"headerStyle":{ display: 'flex', justifyContent: 'center', alignItems: 'center' },"bodyStyle":{
                'font-size': '13px',
                'font-weight': 'bold',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
            }}}),_vm._l((_vm.comparisonDynamicColumns),function(col,index){return _c('Column',{key:index,attrs:{"field":col.key,"reorderableColumn":false,"headerStyle":{ display: 'flex', justifyContent: 'center', alignItems: 'center' },"bodyStyle":{ display: 'flex', justifyContent: 'center', alignItems: 'center' }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[(col.unit)?_c('div',[_vm._v(_vm._s(col.key)+"("+_vm._s(col.unit)+")")]):_vm._e()])]},proxy:true}],null,true)})})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }