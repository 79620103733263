<template>
    <div>
        <b-table-simple bordered class="text-center">
            <b-thead>
                <!-- Header rows -->
                <b-tr>
                    <b-td rowspan="2" class="align-middle" :style="{ backgroundColor: '#ECEFF1' }">
                        {{ "독립변수" }}<br />{{ "갯수" }}
                    </b-td>
                    <!-- <b-td rowspan="2" class="align-middle" :style="{ backgroundColor: '#ECEFF1' }">
                    {{ "모델" }}<br />{{ "No." }}
                </b-td> -->
                    <b-td :colspan="resultData.variable.independ.length" :style="{ backgroundColor: '#ECEFF1' }">
                        {{ "P-Value 테스트" }}
                    </b-td>
                    <b-td colspan="2" :style="{ backgroundColor: '#ECEFF1' }">모델</b-td>
                    <b-td rowspan="2" class="align-middle" :style="{ backgroundColor: '#ECEFF1' }">
                        {{ "모델 유의성" }}<br />{{ "평가" }}
                    </b-td>
                    <b-td rowspan="2" class="align-middle" :style="{ backgroundColor: '#ECEFF1' }">
                        {{ "수정된" }}<br />{{ "모델 R2" }}
                    </b-td>
                    <b-td rowspan="2" class="align-middle" :style="{ backgroundColor: '#ECEFF1', width: '28rem' }">
                        {{ "모델식" }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td
                        v-for="(item, index) in resultData.variable.independ"
                        :key="'independ' + index"
                        class="align-middle"
                        :style="{ backgroundColor: '#ECEFF1' }"
                    >
                        {{ item.key }}
                    </b-td>
                    <b-td :style="{ backgroundColor: '#ECEFF1' }"> {{ "모델" }}<br />{{ "P-Value" }} </b-td>
                    <b-td :style="{ backgroundColor: '#ECEFF1' }"> {{ "모델" }}<br />{{ "R2" }} </b-td>
                </b-tr>
            </b-thead>
            <b-tbody v-for="(model, index) in modelList" :key="'model' + index">
                <b-tr>
                    <b-td class="align-middle" :rowspan="model.length + 1" :style="{ backgroundColor: '#ECEFF1' }">
                        {{ `${model[0].independents.length}개` }}
                    </b-td>
                </b-tr>
                <!-- data mapping ...ing -->
                <b-tr
                    v-for="(detail, modelIndex) in model"
                    :key="'detail' + modelIndex"
                    :id="'row-' + index + '-' + modelIndex"
                    :style="{ backgroundColor: evaluationSinificance(detail) ? '#E2F0D9' : '' }"
                    class="significanceTableRow"
                    @mouseover="showPopover(detail, $event)"
                    @mouseleave="hidePopover"
                >
                    <!-- model number 수정 필요 -->
                    <!-- <b-td>{{ index }}</b-td> -->
                    <!-- p-value 테스트 값 들어갈 자리 / 최적화 된 건지 확인 필요 -->
                    <b-td
                        class="align-middle"
                        v-for="(item, independIndex) in resultData.variable.independ"
                        :key="'independ' + independIndex"
                    >
                        {{
                            detail.independents.includes(item.key)
                                ? detail.pValues[detail.independents.indexOf(item.key) + 1]
                                : "-"
                        }}
                    </b-td>
                    <b-td class="align-middle" :style="{ color: detail.modelPValue > cutoff ? 'red' : 'black' }">
                        {{ detail.modelPValue ?? "-" }}
                    </b-td>
                    <b-td class="align-middle" :style="{ color: detail.R2 < 0.5 ? 'red' : 'black' }">
                        {{ detail.R2 ? `${detail.R2.toFixed(3)} ` : "-" }}
                    </b-td>
                    <!-- <b-td>
                    {{ detail.vif[detail.vif.length - 1] ? `${detail.vif[detail.vif.length - 1].toFixed(3)}` : "-" }}
                </b-td> -->
                    <!-- 모델 유의성 평가 -->
                    <b-td class="align-middle">
                        {{ evaluationSinificance(detail) ? "O" : "X" }}
                    </b-td>
                    <b-td class="align-middle" :style="{ color: detail.adjustedR2 < 0.5 ? 'red' : 'black' }">
                        {{ detail.adjustedR2 ? `${detail.adjustedR2.toFixed(3)} ` : "-" }}
                    </b-td>
                    <b-td class="align-middle">{{ detail.equation }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <b-popover :target="popoverTarget" triggers="hover" placement="top" v-if="popoverTarget">
            <template #title>모델 설명</template>
            <div>
                <div v-for="(item, i) in popoverContent" :key="i">{{ item }}</div>
            </div>
        </b-popover>
    </div>
</template>

<script>
export default {
    props: ["resultData", "cutoff", "modelKey"],
    components: {},
    data() {
        return {
            modelList: null,
            model: [],
            popoverTarget: null, // 현재 Popover를 표시할 대상
            popoverContent: null,
        };
    },
    computed: {
        targetInfo() {
            const find = this.resultData.variable.main.find((item) => item.roleType == "Main") ?? {};
            return find;
        },
        // model() {
        //     const model = this.resultData.model[this.targetInfo.key] ?? [];
        //     return model;
        // },
    },
    watch: {},
    created() {},
    async mounted() {
        console.log("modelKey", this.modelKey);
        await this.setModel();
        this.modelList = this.model && this.groupByIndependents(this.model);
    },
    methods: {
        showPopover(detail, event) {
            this.popoverTarget = event.currentTarget.id;
            this.popoverContent = detail.evalResult;
        },
        hidePopover() {
            this.popoverTarget = null;
            this.popoverContent = null;
        },
        setModel() {
            if (!this.resultData || !this.modelKey || !this.resultData.model) {
                console.error(
                    `Invalid resultData structure: ${this.resultData} or
                        modelKey: ${this.modelKey} or model: ${this.resultData.model}`
                );
                return;
            }

            // this.resultData.variable.main.forEach(() => {
            //     const findModel = this.resultData.model[this.modelKey];

            //     if (!findModel) {
            //         console.warn(`Model not found for key: ${this.modelKey}`);
            //         return;
            //     }

            //     if (Array.isArray(findModel) && findModel.length > 0) {
            //         this.model.push(...findModel);
            //     } else {
            //         console.warn(`Empty or invalid model data for key: ${this.modelKey}`, findModel);
            //     }
            // });

            const findModel = this.resultData.model[this.modelKey];
            if (!findModel) {
                console.warn(`Model not found for key: ${this.modelKey}`);
                return;
            }
            if (Array.isArray(findModel) && findModel.length > 0) {
                this.model.push(...findModel);
            } else {
                console.warn(`Empty or invalid model data for key: ${this.modelKey}`, findModel);
            }
        },
        groupByIndependents(data) {
            const groups = {};

            if (!this.isEmpty(data)) {
                data.forEach((item) => {
                    const key = item.independents.length; // 독립변수 배열의 길이를 키로 사용
                    if (!groups[key]) {
                        groups[key] = []; // 그룹이 없으면 새 배열 생성
                    }
                    groups[key].push(item); // 해당 그룹에 추가
                });
            }
            return Object.values(groups); // 객체의 값들을 배열로 변환
        },
        evaluationSinificance(data) {
            // 모델의 p-value 테스트는 model의 pValue로만 하는 것이 맞는 것 같음. 임시로 true 설정 확정은 아님 by badblock.2024-12-25
            // const pValue = data.pValues.every((value) => value < this.cutoff);
            const pValue = true;

            const modelPValue = data.modelPValue < this.cutoff ? true : false;
            // R2값과 adjustR2값이 백분율에서 0 ~ 1의 소수로 변경됨.
            const R2 = data.R2 > 0.5 ? true : false;
            const adjustedR2 = data.adjustedR2 > 0.5 ? true : false;

            // console.warn(`evaluationSinificance model pValue: ${modelPValue}, R2: ${R2}, adjusted R2: ${adjustedR2}`);
            return pValue && modelPValue && R2 && adjustedR2 ? true : false;
        },
    },
};
</script>

<style></style>
