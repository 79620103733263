<template>
    <div class="col-xl-12 xeEnergyCostAnalysis">
        <header-box :title="$t('요금 정보 관리')" />

        <hr class="mt-3 mb-3" />
        <div class="d-flex flex-row" v-bind:style="{ height: '930px' }">
            <div class="bg-white rounded mr-2" :style="{ width: '70%' }">
                <search-box
                    :ref="'searchBox'"
                    @search:data="onSearch"
                    @param:data="getParam"
                    @save="onSave"
                    @deleteSelect="onSelectRemove"
                    @deleteAll="onRemoveAll"
                    @change:costCode="changeCostCodeComboBox"
                />
                <search-box-new v-show="isSearchBoxFlag" :ref="'searchBoxNew'" @insert="insertRegist" />

                <grid-box-1 :ref="'gridBox1'" v-if="isGridBoxLeftFlag" @getCostDvsnCode="getCostDvsnCode" />
            </div>

            <div class="bg-white rounded" :style="{ width: '30%' }">
                <grid-box-2
                    :ref="'gridBox2'"
                    :isGridBoxRightFlag="isGridBoxRightFlag"
                    @getCostDvsnCode="getCostDvsnCode"
                    @getCostMapList="getCostMapList"
                    @getCostTableList="getCostTableList"
                />
            </div>
        </div>
    </div>
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";
import CardColor from "./CardColor.json";

import backEndApi from "@api/backEndApi";

import headerBox from "@views/component/headerBox/management/Header.vue";
import searchBox from "./CostInfoSearch.vue";
import searchBoxNew from "./CostInfoSearchNew.vue";
import gridBox1 from "./CostInfoGridLeft.vue";
import gridBox2 from "./CostInfoGridRight.vue";

import { StatusCodes } from "http-status-codes";
import * as popupMessages from "@src/consts/popupMessageConsts";
import AppErrorHandler from "@src/appErrorHandler";

export default {
    props: ["type"],
    components: {
        headerBox,
        searchBox,
        searchBoxNew,
        gridBox1,
        gridBox2,
    },
    watch: {},
    data() {
        return {
            isSearchBoxFlag: false,
            isGridBoxLeftFlag: false,
            isGridBoxRightFlag: false,
            regIdx: null,
            cardColor: CardColor["PeakColor"],
            popupMessages,
        };
    },
    created() {},
    mounted() {},
    methods: {
        onSearch(data) {
            // 요금달력
            this.$refs.gridBox1.submit(data);
        },
        onSave() {
            this.$refs.gridBox1.save();
        },
        async onSelectRemove() {
            let energySourceCode = this.$refs.searchBox.getEnergySourceCode();

            // 이전 리비전과 소스 에서 아래와 같이 변경함.
            /*           
				↓↓↓↓↓
			*/
            try {
                const popupResult = await this.alertConfirmWarning(popupMessages.COST_DELETE_POPUP_MESSAGE);
                if (!popupResult.value) return;

                await backEndApi.cost.deleteCostData(this.regIdx);

                await this.alertNoti(popupMessages.COST_DELETE_SUCCESS_POPUP_MESSAGE);
                this.$refs.searchBox.reSearch(energySourceCode);
            } catch (err) {
                new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_REMOVE_ERROR_POPUP_MESSAGE).errHandling();
            }
        },
        async onRemoveAll() {
            let energySourceCode = this.$refs.searchBox.getEnergySourceCode();

            // 이전 리비전과 소스 에서 아래와 같이 변경함.
            /*           
				↓↓↓↓↓
			*/
            try {
                const popupResult = await this.alertConfirmWarning(popupMessages.COST_DELETE_POPUP_MESSAGE);
                if (!popupResult.value) return;

                await backEndApi.cost.deleteCostData(this.regIdx);
                await this.alertNoti(popupMessages.COST_DELETE_SUCCESS_POPUP_MESSAGE);
                this.$refs.searchBox.reSearch(energySourceCode);
            } catch (err) {
                await this.alertNoti(popupMessages.COMMON_REMOVE_ERROR_POPUP_MESSAGE);
                new AppErrorHandler(err)
                    .setOtherMessage(popupMessages.COMMON_REMOVE_ERROR_POPUP_MESSAGE, this.alertDanger)
                    .errHandling();
            }
        },
        getParam(type) {
            this.$refs.gridBox1.getParam(type);
        },
        getCostDvsnCode() {
            let costDvsnCode = this.$refs.searchBox.getCostDvsnCode();
            return costDvsnCode;
        },
        // 신규추가
        changeCostCodeComboBox(costCode) {
            if (costCode == "new") {
                // 신규 comboBox 활성화
                this.isSearchBoxFlag = true;
                this.isGridBoxLeftFlag = false;
                this.isGridBoxRightFlag = false;

                let energySourceCode = this.$refs.searchBox.getEnergySourceCode();

                this.$refs.searchBoxNew.getCostBasicCodeList(energySourceCode);
            } else if (costCode == "") {
                this.isSearchBoxFlag = false;
                this.isGridBoxLeftFlag = false;
                this.isGridBoxRightFlag = false;
            } else {
                // 요금정보 일괄조회
                this.isSearchBoxFlag = false;
                this.isGridBoxLeftFlag = true;
                this.isGridBoxRightFlag = true;

                this.regIdx = costCode;

                this.getCostData(this.regIdx);
            }
        },
        async insertRegist() {
            // 요금제 등록
            let param = {
                costBasicCode: this.$refs.searchBoxNew.getCostBasicCode(),
                costDvsnCode: this.$refs.searchBoxNew.getCostDvsnCode(),
                energySourceCode: this.$refs.searchBox.getEnergySourceCode(),
            };

            // 이전 리비전과 소스 에서 아래와 같이 변경함.
            /*           
				↓↓↓↓↓
			*/
            try {
                const result = await backEndApi.cost.insertRegist(param);
                const data = result.data;

                this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
                this.isSearchBoxFlag = false;

                // 사용에너지원 set > 요금제 set > 요금정보 일괄조회
                // 사용에너지원 set
                this.$refs.searchBox.setEnergySourceCode(data.energySourceCode);

                // 요금제 set
                this.$refs.searchBox.setRegIdx(data.regIdx);
            } catch (err) {
                new AppErrorHandler(err)
                    .setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
                    .createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
                    .createBizHandle(
                        this.ERR_KIND.DUPLICATE_DATA,
                        popupMessages.COST_DUP_ERROR_POPUP_MESSAGE,
                        this.alertDanger
                    )
                    .errHandling();
            }
        },
        remapPeakColor(data) {
            // data: backEndApi.cost.getCostData API Result
            /* data.costInfo.tagInfo :
		[
			{ "code": "LowPeak", "codeName": "경부하", "color": "#FEF5ED" },
			{ "code": "MidPeak", "codeName": "중간부하", "color": "#D3E4CD" },
			{ "code": "HighPeak", "codeName": "최대부하", "color": "#99A799" }
		] */
            var that = this;

            if (data.costInfo.hasOwnProperty("tagInfo")) {
                data.costInfo.tagInfo.forEach((tag) => {
                    tag.color = that.cardColor[tag.code];
                });
            } else {
                console.error("tagInfo parameter is not exists in data object");
            }
        },
        async getCostData(regIdx) {
            // 요금정보 일괄조회
            // console.log("1111:", regIdx);

            // 이전 리비전과 소스 에서 아래와 같이 변경함.
            /*           
				↓↓↓↓↓
			*/
            try {
                const result = await backEndApi.cost.getCostData(regIdx);
                const data = result.data;

                this.remapPeakColor(data);

                this.$refs.gridBox1.submit(data);
                this.$refs.gridBox2.submit(data);
            } catch (err) {
                new AppErrorHandler(err)
                    .setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
                    .errHandling();
            }
        },
        async getCostMapList() {
            // 이전 리비전과 소스 에서 아래와 같이 변경함.
            /*           
				↓↓↓↓↓
			*/
            try {
                const result = await backEndApi.cost.getCostData(this.regIdx);
                const data = result.data;

                this.remapPeakColor(data);
                this.$refs.gridBox2.submitCostMapList(data);
            } catch (err) {
                new AppErrorHandler(err)
                    .setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
                    .errHandling();
            }
        },
        async getCostTableList() {
            // 이전 리비전과 소스 에서 아래와 같이 변경함.
            /*           
				↓↓↓↓↓
			*/
            try {
                const result = await backEndApi.cost.getCostData(this.regIdx);
                const data = result.data;
                this.remapPeakColor(data);

                this.$refs.gridBox2.submitCostTableListt(data);
            } catch (err) {
                new AppErrorHandler(err)
                    .setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
                    .errHandling();
            }
        },
    },
};
</script>

<style scoped></style>
