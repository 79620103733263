<template>
    <div class="detail-container">
        <div style="height: 100%">
            <div class="detail-header">
                <div class="col detail-title">
                    <!-- <span class="title">{{ vie/wTitle }}</span> -->
                    <span class="badge title1">{{ detailInfo.modelName ?? "-" }}</span>
                    <span class="badge title2"> {{ detailInfo.category ?? "-" }} </span>
                    <span class="badge others"> {{ targetPtName ?? "-" }} </span>

                    <span class="badge others">
                        {{ `연관 데이터 수 (${detailInfo.variables?.length ?? "-"}개)` }}
                    </span>
                    <span class="badge others">
                        {{ !isEmpty(detailInfo.publish) ? "메뉴 노출 중" : "메뉴 노출 비활성" }}
                    </span>
                </div>
                <div class="detail-control">
                    <div class="btn-group mr-2">
                        <button type="button" class="btn btn-sm btn-danger" @click="deleteAnalysisModel">
                            {{ "삭제" }}
                        </button>
                        <button type="button" class="btn btn-sm btn-white" @click="changeExpandStatus">
                            {{ "목록" }}
                        </button>

                        <button type="button" class="btn btn-sm btn-white" @click="newAnalysisModel">
                            {{ "신규" }}
                        </button>

                        <button type="button" class="btn btn-sm btn-primary" @click="saveData">{{ "저장" }}</button>
                    </div>
                </div>
            </div>
            <div style="width: 100%; height: calc(100% - 50px)">
                <div style="width: 100%">
                    <div block v-b-toggle.setting class="accordion-title">
                        <span>{{ "분석 조건 설정" }}</span>
                        <span class="when-opened">
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </span>
                        <span class="when-closed">
                            <i class="fa fa-chevron-up" aria-hidden="true"></i>
                        </span>
                    </div>
                    <b-collapse
                        v-model="settingCollapse"
                        visible
                        ref="setting"
                        id="setting"
                        class="collapse-body setting-itemview"
                        accordion="setting"
                    >
                        <div ref="exportPDF">
                            <SettingPanel
                                ref="SettingPanel"
                                :detailInfo="detailInfo"
                                :analysisList="analysisList"
                                :resultData="resultData"
                                @exportToPDF="exportToPDF"
                                @changeAnalysisWay="changeAnalysisWayFunc"
                                @result="getAnalysisResultData"
                                @clearResult="clearResultData"
                                @save-modal="saveData"
                                @updateAdjustData="handleAdjustData"
                                @updateFixIndependData="handleFixIndependData"
                            />
                        </div>
                    </b-collapse>
                </div>
                <div
                    style="width: 100%;"
                    :style="{ height: settingCollapse ? 'calc(100% - 240px)' : 'calc(100% - 50px)' }"
                >
                    <div block v-b-toggle.analysisResult class="accordion-title">
                        <span>{{ "분석 결과" }}</span>
                        <span>
                            <span
                                v-if="
                                    detailInfo.analysisMethod === 'Trend' ||
                                        detailInfo.analysisMethod === 'Diff' ||
                                        detailInfo.analysisMethod === 'Comp'
                                "
                                class="status-icon pr-3"
                                style="cursor: pointer"
                                @click="showStatisticModal($event)"
                                v-b-tooltip.hover
                                :title="$t('통계 항목 표시 제어')"
                            >
                                <i class="fas fa-ellipsis-h" aria-hidden="true"></i>
                            </span>
                            <span class="when-opened">
                                <i class="fa fa-chevron-down" aria-hidden="true"></i>
                            </span>
                            <span class="when-closed">
                                <i class="fa fa-chevron-up" aria-hidden="true"></i>
                            </span>
                        </span>
                    </div>
                    <b-collapse
                        visible
                        ref="analysisResult"
                        id="analysisResult"
                        class="collapse-body result-itemview"
                        accordion="analysisResult"
                    >
                        <!-- MNV 숨기기위한 주석. 풀면 분석방법을 mnv로 했을 시 보입니다. 밑에 placeholder를 포함한 태그를 주석처리하면 깔끔하게 보입니다.-->
                        <!-- <div v-if="detailInfo.analysisMethod === 'MNV' && !isEmpty(resultData)" class="w-100 h-100">
                            <mnv-result-panel></mnv-result-panel>
                        </div> -->
                        <div v-if="isLoading" class="analysisSpinner">
                            <b-spinner variant="primary" label="Spinning"></b-spinner>
                        </div>

                        <div v-else-if="!isEmpty(resultData)" ref="result" class="row">
                            <!-- <div v-if="detailInfo.analysisMethod === 'MNV'" class="w-100 h-100">
                                <mnv-result-panel :detailInfo="detailInfo" :resultData="resultData"></mnv-result-panel>
                            </div> -->
                            <!-- <div v-else class="row"> -->
                            <div class="row">
                                <div class="col-12" style="display: flex; justify-content: center;">
                                    <h5>{{ detailInfo.modelName }}</h5>
                                </div>
                                <div :class="detailInfo.analysisMethod === 'MNV' ? 'col-12' : 'col-9 pl-0'">
                                    <ResultChartPanel
                                        :detailInfo="detailInfo"
                                        :chartResize="chartResize"
                                        :analysisMethod="analysisMethod"
                                        :resultData="resultData"
                                        :calendarList="calendarList"
                                        :switchValues="switchValues"
                                        :adjustData="adjustData"
                                        :fixIndependData="fixIndependData"
                                        :mode="'setting'"
                                        @show-property-management-modal="showPropertyManagementModal"
                                    />
                                </div>
                                <div class="col-3 pr-0" v-if="detailInfo.analysisMethod !== 'MNV'">
                                    <ResultInfoPanel
                                        :detailInfo="detailInfo"
                                        :calendarList="calendarList"
                                        @detail="showAnalysisHistory"
                                        @getEnergyCalendarList="getEnergyCalendarList"
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- <div v-else class="list-placeholder">
                            <span class="list-notification-icon">
                                <i class="fas fa-external-link-alt"></i>
                            </span>
                            <span class="list-notification-text">
                                {{ "분석 조건을 설정해서 분석을 실행해주세요." }}
                            </span>
                        </div> -->
                    </b-collapse>
                </div>
            </div>
        </div>
        <b-modal id="statistic-modal" ref="statisticModal" title="통계 항목 표시 제어">
            <template #modal-header>
                <h5 class="mb-0">통계 항목 표시 제어.</h5>
            </template>
            <div>
                <div
                    v-for="(label, key) in switchLabels"
                    :key="key"
                    class="d-flex align-items-center justify-content-between my-2"
                >
                    <label :for="'switch-' + key">{{ label }}</label>
                    <b-form-checkbox v-model="switchValues[key]" :id="'switch-' + key" switch></b-form-checkbox>
                </div>
            </div>

            <template #modal-footer>
                <div style="display: flex; flex: 1">
                    <button
                        type="button"
                        class="btn btn-secondary ctrl-modal-btn"
                        @click="closeStatisticModal"
                        style="flex: 1; text-align: center;"
                    >
                        닫기
                    </button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import SettingPanel from "./panels/SettingPanel.vue";
import ResultChartPanel from "./panels/ResultChartPanel.vue";
// import MnvResultPanel from "@src/views/new/data-analysis/analysis/panels/MnvResultPanel.vue";

import ResultInfoPanel from "./panels/ResultInfoPanel.vue";
import html2pdf from "html2pdf.js";
import moment from "moment";

export default {
    props: ["analysisList"],
    components: {
        SettingPanel,
        ResultChartPanel,
        ResultInfoPanel,
        // MnvResultPanel,
    },
    data() {
        return {
            isLoading: false,
            detailInfo: null,
            analysisWay: "",
            chartResize: false,
            settingCollapse: true,
            showReltnGoal: false,

            resultData: [],
            analysisMethod: null,

            calendarList: [],
            switchValues: {
                mean: false, // 평균
                quartile: false, // 사분위수
                outlier: false, // 이상
                event: false, // 이벤트
            },

            switchLabels: {
                mean: "평균",
                quartile: "사분위수",
                outlier: "이상",
                event: "이벤트",
            },

            //조정값
            adjustData: {},
            fixIndependData: {},
        };
    },
    computed: {
        targetPtName() {
            let find = this.$store.state.pointList.find((point) => point.ptIdx == this.detailInfo.ptIdx);
            return !this.isEmpty(find) ? find.ptName : null;
        },
        // mnv 분석에서 메인 데이터 조정값이 없을 경우 timeDsvn 값에 따라 0으로 초기화화
        notAdjustData() {
            switch (this.detailInfo.timeDsvn) {
                case "HOURBYDAY":
                    return Array.from({ length: 24 }, () => 0); // 0시 ~ 23시
                case "DAYBYMONTH":
                    return Array.from({ length: 31 }, () => 0); // 1일 ~ 30일
                case "MONTHBYYEAR":
                    return Array.from({ length: 12 }, () => 0); // 1월 ~ 12월
                default:
                    return [];
            }
        },
    },
    watch: {
        detailInfo: {
            deep: true,
            handler() {
                this.adjustData = {};
                console.log("adjustData initialized:", this.adjustData);
            },
        },
        "switchValues.mean": function(newVal) {
            if (newVal) {
                this.switchValues.quartile = false;
            }
        },
        "switchValues.quartile": function(newVal) {
            if (newVal) {
                this.switchValues.mean = false;
            }
        },
        "detailInfo.analysisMethod": {
            deep: true,
            handler(method) {
                if (method === "Goal") {
                    return;
                } else {
                    this.initializeSwitchValues();
                }
            },
        },
    },
    created() {
        this.initData();
    },
    mounted() {},
    methods: {
        showPropertyManagementModal() {
            this.$refs["SettingPanel"].showPropertyManagementModal();
        },
        handleAdjustData(data) {
            console.log("adjustData main", data);
            this.adjustData = data;
        },
        handleFixIndependData(data) {
            console.log("fixIndependData ", data);
            this.fixIndependData = data;
        },

        initializeSwitchValues() {
            this.switchValues = {
                mean: true, // 평균
                quartile: false, // 사분위수
                outlier: true, // 이상
                event: true, // 이벤트
            };
        },
        async showStatisticModal(event) {
            // 모달
            await event.stopPropagation();
            this.$refs.statisticModal.show();
        },
        closeStatisticModal() {
            this.$refs.statisticModal.hide();
        },
        setData(modelIdx) {
            if (this.isEmpty(modelIdx)) {
                this.initData();
            } else {
                this.loadData(modelIdx);
            }
        },
        async initData() {
            this.detailInfo = {
                modelName: null,
                category: null,
                icon: null,
                analysisMethod: null,
                dispType: null,
                dispUnit: null,
                ptIdx: null,
                timeDsvn: null,
                fromDate: null,
                toDate: null,
                compareType: null,
                fromDateComp: null,
                toDateComp: null,
                enabled: "Y",
                description: null,
                modelContext: null,

                analysisAlgorithm: "LinearRegression",
                mnvMethod: null,

                variables: [],
                publish: null,
                grants: [],
                report: null,
                comments: [],
                userData: {
                    baseline: {},
                    report: {},
                },
            };
            this.resultData = [];
            this.$nextTick(() => {
                this.$refs["SettingPanel"].setNewMode();
            });
        },
        async loadData(modelIdx) {
            const edit = this.$refs["SettingPanel"].isEditMode;
            if (edit) {
                const confirm = await this.notifyNewAnalModel();
                if (confirm) {
                    this.resultData = [];
                    await this.getAnalysisModel(modelIdx);
                    this.$emit("expand");
                } else return;
            } else {
                await this.getAnalysisModel(modelIdx);
                this.$emit("expand");
            }
        },
        async getAnalysisModel(modelIdx) {
            try {
                let result = await backEndApi.analysisModel.getAnalysisModel(modelIdx);
                if (result.status == 200) {
                    // this.detailInfo = result.data;
                    //저장된 모델을 가져온 후 해당모델에서도 userData를 사용할 수 있기위함
                    this.detailInfo = {
                        ...result.data,
                        userData: {
                            baseline: {},
                            report: {},
                        },
                    };
                    // await this.$refs["SettingPanel"].setViewMode();

                    // await this.$refs["SettingPanel"].setDayTime(this.detailInfo);
                } else {
                    this.alertWarning(`${result.data.message}`);
                }
            } catch (e) {
                console.error(e);
            }
        },
        async notifyNewAnalModel() {
            const result = await this.alertConfirmWarning("편집중인 내용을 무시하고 종료할까요?");
            if (result.value) return true;
            else return false;
        },
        async newAnalysisModel() {
            const edit = this.$refs["SettingPanel"].isEditMode;
            if (edit) {
                const confirm = await this.notifyNewAnalModel();
                if (confirm) {
                    await this.initData();
                } else return;
            } else await this.initData();
        },
        clearData() {
            this.detailInfo = null;
            this.$forceUpdate();
        },
        changeAnalysisWayFunc(val) {
            this.analysisWay = val;
        },
        changeExpandStatus() {
            this.chartResize = !this.chartResize;
            this.$emit("expand");
        },
        exportToPDF() {
            //window.scrollTo(0, 0);
            html2pdf(this.$refs.result, {
                margin: 5,
                filename: "document.pdf",
                image: { type: "png", quality: 1 },
                html2canvas: {
                    scrollY: 0,
                    scale: 1,
                    dpi: 300,
                    letterRendering: true,
                    //pdf에 출력하지 않아야할 dom이 있다면 해당 옵션 사용
                    // ignoreElements: function (element) {
                    //     if (element.id == "pdf-button-area") {
                    //         return true;
                    //     }
                    // }
                },
                jsPDF: {
                    orientation: "landscape",
                    unit: "mm",
                    format: "a3",
                    compressPDF: true,
                },
            });
        },
        async saveData() {
            let complete;

            if (this.isEmpty(this.detailInfo.modelName)) {
                // 분석명을 입력 안했을 경우 설정 관리 모달 띄어줌 by woonsik
                this.$refs.SettingPanel.showSettingMgmtModal();
            } else {
                try {
                    let result = await backEndApi.analysisModel.saveAnalysisModel(this.detailInfo);
                    if (result.status == 200) {
                        complete = true;
                        await this.alertNoti("저장하였습니다.");
                        await this.$emit("refresh");
                        await this.getAnalysisModel(result.data.data.modelIdx);
                        await this.clearResultData();
                    } else {
                        complete = false;
                        this.alertWarning(`${result.data.message}`);
                    }
                } catch (e) {
                    console.error(e);
                }
            }
            return complete;
        },
        async deleteAnalysisModel() {
            if (this.$refs["SettingPanel"].isNewMode) {
                this.alertNoti("신규 입력시에는 삭제할 수 없습니다.");
                return;
            } else {
                let confirm = await this.alertConfirmWarning("해당 분석 모델을 삭제하시겠습니까?");
                if (!confirm.value) return;
                else {
                    try {
                        let result = await backEndApi.analysisModel.deleteAnalysisModel(this.detailInfo.modelIdx);
                        if (result.status == 200) {
                            await this.alertNoti("삭제하였습니다.");
                            await this.initData();
                            await this.$emit("refresh");
                        } else this.alertWarning(`${result.data.message}`);
                    } catch (e) {
                        console.erroe(e);
                    }
                }
            }
        },
        clearResultData() {
            this.resultData = [];
        },
        async getAnalysisResultData() {
            let analysisMethod = this.detailInfo.analysisMethod;

            const searchInfo = {
                ptIdx: this.detailInfo.ptIdx,
                timeDsvn: this.detailInfo.timeDsvn,
                fromDate: this.detailInfo.fromDate,
                toDate: this.detailInfo.toDate,

                variables: [],
                compareType: this.detailInfo.compareType,
                // fromDateComp: "",
                // toDateComp: "",
                datasetMode: "Divided",
                dispUnit: this.detailInfo.dispUnit,
            };

            this.detailInfo.variables.forEach((pt) => {
                searchInfo.variables.push(pt);
            });
            // fromDateComp 설정
            if (this.detailInfo.compareType === "User") {
                searchInfo.fromDateComp = this.detailInfo.fromDateComp;
            } else {
                searchInfo.fromDateComp = null;
            }

            // toDateComp 설정
            if (this.detailInfo.compareType === "User") {
                if (this.detailInfo.toDateComp) {
                    searchInfo.toDateComp = this.detailInfo.toDateComp;
                } else {
                    searchInfo.toDateComp = this.detailInfo.fromDateComp;
                }
            } else {
                searchInfo.toDateComp = null;
            }

            if (this.detailInfo.analysisMethod == "Goal") {
                const data = this.$refs["SettingPanel"].userVariableData;
                if (!this.isEmpty(data)) {
                    console.log("data!!!!!!!!!!!!!!!!!!", data);
                    const transformedData = {};
                    data.forEach((item) => {
                        // 각 객체의 키를 순회
                        Object.keys(item).forEach((key) => {
                            if (!transformedData[key]) {
                                // 키가 없으면 초기화
                                transformedData[key] = [];
                            }
                            // 해당 키 배열에 값 추가
                            transformedData[key].push(item[key]);
                        });
                    });
                    console.log("transformedData", transformedData);
                    searchInfo.userData = transformedData;
                } else searchInfo.userData = null;
            }

            // 에너지 캘린더 목록 조회
            if (analysisMethod == "Trend" || analysisMethod == "Diff" || analysisMethod == "Comp")
                await this.getEnergyCalendarList(searchInfo.fromDate, searchInfo.toDate);

            if (analysisMethod == "Trend") {
                this.showReltnGoal = false;
                this.analysisMethod = analysisMethod;
                await this.getAnalyzeTrend(searchInfo);
            } else if (analysisMethod == "Diff") {
                this.showReltnGoal = false;
                this.analysisMethod = analysisMethod;
                await this.analyzeComparison(searchInfo);
            } else if (analysisMethod == "Comp") {
                this.showReltnGoal = false;
                this.analysisMethod = analysisMethod;
                await this.analyzeComponent(searchInfo);
            } else if (analysisMethod == "Reltn") {
                this.showReltnGoal = true;
                this.analysisMethod = analysisMethod;
                searchInfo["resultFormat"] = "Markdown";
                await this.analyzeCorrelation(searchInfo);
            } else if (analysisMethod == "Goal") {
                this.showReltnGoal = true;
                this.analysisMethod = analysisMethod;
                await this.analyzeGoal(searchInfo);
            } else if (analysisMethod == "Cost") {
                this.showReltnGoal = false;
                this.analysisMethod = analysisMethod;
                this.resultData = [1, 2, 3]; // 임시 테스트 용도
            } else if (analysisMethod == "MNV") {
                const findMainVariable = this.detailInfo.variables.find((item) => item.roleType == "Depend");
                console.log("findMainVariable", findMainVariable);
                // const adjustData = this.$refs["SettingPanel"].adjustData;
                // console.log("adjustData!!!!!!!!!! in analysis editor", adjustData);
                const fixIndependData = this.$refs["SettingPanel"].fixIndependData;
                console.log("adjustData!!!!!!!!!! in analysis editor", fixIndependData);
                const mvnSearchInfo2 = {
                    startOfYear: 1,
                    ptIdx: this.detailInfo.ptIdx,
                    timeDsvn: this.detailInfo.timeDsvn,
                    fromDate: this.detailInfo.fromDate,
                    toDate: this.detailInfo.toDate,
                    variables: [],
                    compareType: this.detailInfo.compareType,
                    fromDateComp: this.detailInfo.fromDateComp,
                    toDateComp: this.detailInfo.toDateComp,
                    datasetMode: "Divided",
                    dispUnit: this.detailInfo.dispUnit,
                    // modelContext: this.detailInfo.modelContext,
                    // baseline: {
                    //     adjustData: {},
                    // },
                    // report: {
                    //     adjustData: {},
                    // },
                    adjustData: null,
                    userData: {
                        baseline: this.detailInfo.userData?.baseline,
                        report: this.detailInfo.userData?.report,
                    },
                    // baseline: null,
                    // report: null,
                };
                this.detailInfo.variables.forEach((pt) => {
                    mvnSearchInfo2.variables.push(pt);
                });

                if (Object.keys(this.adjustData).length > 0) {
                    // mvnSearchInfo2.baseline = {
                    //     adjustData: {},
                    // };
                    // mvnSearchInfo2.report = {
                    //     adjustData: {},
                    // };
                    mvnSearchInfo2.adjustData = this.adjustData;
                    // Object.keys(adjustData).forEach((key) => {
                    //     mvnSearchInfo2[key].adjustData[findMainVariable.name] = adjustData[key];
                    // });
                    // mvnSearchInfo2.baseline.adjustData[findMainVariable.name] = adjustData;
                    console.log("mnvSearchInfo2 is changed", mvnSearchInfo2);
                }

                // if (Object.keys(fixIndependData).length > 0) {
                //     if (!this.isEmpty(fixIndependData.baseline)) {
                //         Object.keys(fixIndependData.baseline).forEach((key) => {
                //             //   mvnSearchInfo2.adjustData.baseline[key] = fixIndependData.baseline[key];
                //             mvnSearchInfo2.baseline[key] = fixIndependData.baseline[key];
                //         });
                //     }
                //     if (!this.isEmpty(fixIndependData.report)) {
                //         Object.keys(fixIndependData.report).forEach((key) => {
                //             mvnSearchInfo2.report[key] = fixIndependData.report[key];
                //         });
                //     }
                // }

                // const data = this.$refs["SettingPanel"].userVariableData;
                // if (!this.isEmpty(data)) {
                //     console.log("userVariableData MNV!!!!!!!!!!!!!", data);
                //     const transformedData = {};
                //     data.forEach((item) => {
                //         // 각 객체의 키를 순회
                //         Object.keys(item).forEach((key) => {
                //             if (!transformedData[key]) {
                //                 // 키가 없으면 초기화
                //                 transformedData[key] = [];
                //             }
                //             // 해당 키 배열에 값 추가
                //             transformedData[key].push(item[key]);
                //         });
                //     });
                //     console.log("transformedData", transformedData);
                //     mvnSearchInfo2.userData = transformedData;
                // } else mvnSearchInfo2.userData = null;
                // console.log("mvnSearchInfo2 analysis", mvnSearchInfo2);

                this.showReltnGoal = true;
                this.analysisMethod = analysisMethod;
                await this.analyzeMNV(mvnSearchInfo2);
            } else {
                this.resultData = [];
                this.alertWarning("분석 결과를 조회할 수 없습니다.");
            }
        },
        async getAnalyzeTrend(searchInfo) {
            this.isLoading = true;
            try {
                let result = await backEndApi.analysisModel.getAnalyzeTrend(searchInfo);
                if (result.status == 200) {
                    this.resultData = result.data;
                } else {
                    this.resultData = [];
                    this.alertWarning(`${result.data.message}`, "", `${result.data.detail ?? ""}`);
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async analyzeComparison(searchInfo) {
            this.isLoading = true;
            try {
                let result = await backEndApi.analysisModel.analyzeComparison(searchInfo);
                if (result.status == 200) {
                    this.resultData = result.data;
                } else {
                    this.resultData = [];
                    this.alertWarning(`${result.data.message}`, "", `${result.data.detail ?? ""}`);
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async analyzeComponent(searchInfo) {
            this.isLoading = true;
            try {
                let result = await backEndApi.analysisModel.analyzeComponent(searchInfo);
                if (result.status == 200) {
                    this.resultData = result.data;
                } else {
                    this.resultData = [];
                    this.alertWarning(`${result.data.message}`, "", `${result.data.detail ?? ""}`);
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async analyzeCorrelation(searchInfo) {
            this.isLoading = true;
            try {
                let result = await backEndApi.analysisModel.analyzeCorrelation(searchInfo);
                if (result.status == 200) {
                    this.resultData = result.data;
                } else {
                    this.resultData = [];
                    this.alertWarning(`${result.data.message}`, "", `${result.data.detail ?? ""}`);
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async analyzeGoal(searchInfo) {
            this.isLoading = true;

            try {
                let result = await backEndApi.analysisModel.analyzeGoal(searchInfo);
                if (result.status == 200) {
                    this.resultData = result.data;
                    if (result.data.analysisStatus == "CheckUserVariables") {
                        await this.alertWarning(
                            "사용자 정의 변수를 사용할 경우 변수 관리를 통해 사용자 정의 변수의 데이터를 정의 한 후 분석을 실행해주시기 바랍니다."
                        );
                        this.$refs["SettingPanel"].showPropertyManagementModal();
                        return;
                    } else this.isLoading = false;
                } else {
                    this.resultData = [];
                    this.isLoading = false;
                    this.alertWarning(`${result.data.message}`, "", `${result.data.detail ?? ""}`);
                }
            } catch (e) {
                console.error(e);
            }
            // finally {
            //     this.isLoading = false;
            // }
        },
        async analyzeMNV(searchInfo) {
            this.isLoading = true;
            try {
                let result = await backEndApi.analysisModel.analyzeMNV(searchInfo);
                if (result.status == 200) {
                    this.resultData = result.data;
                    console.log("MNV Analysis Result Data : ", this.resultData);
                } else {
                    this.resultData = [];
                    this.alertWarning(`${result.data.message}`, "", `${result.data.detail ?? ""}`);
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        // 에너지 캘린더 목록 조회
        async getEnergyCalendarList(fromDate, toDate) {
            this.isLoading = true;
            try {
                let result = await backEndApi.analysisModel.getEnergyCalendarList(fromDate, toDate);
                if (result.status == 200) {
                    this.calendarList = result.data;
                    if (!this.isEmpty(this.calendarList)) {
                        this.calendarList.forEach((calendar) => {
                            calendar.regDate = moment(calendar.regDate).format("YYYY-MM-DD HH:mm:ss");
                            calendar.modDate = moment(calendar.modDate).format("YYYY-MM-DD HH:mm:ss");
                        });
                    }
                } else this.calendarList = [];
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        // 에너지캘린더 조회 (param: id)
        async getEnergyCalendar() {},
        // 에너지캘린더 저장 (param: calendar)
        async saveEnergyCalendar() {},
        // 에너지캘린더 삭제 (param: id)
        async deleteEnergyCalendar() {},
        showAnalysisHistory() {
            this.$refs["SettingPanel"].showAnalysisHistory();
        },
    },
};
</script>

<style scoped>
.analysisSpinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-white {
    background-color: #ffffff;
    color: #000000;
    border: none;
}

.btn-white:hover {
    background-color: #cecece;
    color: #000000;
}
.row {
    width: 100%;
    margin: 0px !important;
}

.detail-container {
    height: 100%;
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    font-size: 11px;
}

.detail-header {
    margin-bottom: 0px !important;
    padding: 8px 16px 8px 16px;
    overflow: hidden;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 6px 6px 0px 0px;
    border: solid #ececec 1px;
    border-bottom: 1px solid #ccc;
    background-color: #f1f1f1;
    font-weight: bold;
}

.detail-header .detail-title {
    padding: 0 !important;

    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.detail-control {
    display: flex;
    justify-content: flex-end;
}

.title {
    /* font-size: 14px; */
    font-weight: 600;
    margin-right: 20px;
}

.badge {
    font-size: 12px;
    font-weight: 500;
    margin-right: 4px;
    display: inline-block;
    min-width: 20px;
    padding: 0px 10px 0px 10px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    /* background-color: #777; */
    line-height: 22px;
    border-radius: 8px;
}

.badge.title1 {
    background-color: #ff6d00;
    color: #fff;
}

.badge.title2 {
    background-color: #546e7a;
    color: #fff;
}

.badge.others {
    background-color: #cfd8dc;
    color: #000;
}

.accordion-title {
    background-color: #ececec;
    padding: 10px;
    margin-bottom: 0px !important;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    border: solid #ececec 1px;
    border-radius: 6px 6px 0px 0px;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
    display: none;
}

.setting-itemview {
    /* height: 83%; */
    width: 100%;
    padding: 0.7rem;
    justify-content: center;
    align-items: center;
    /* overflow-y: scroll; */
    border: solid #ececec 1px;
    border-radius: 0px 0px 6px 6px;
    /* padding: 1rem 2rem; */
}

.result-itemview {
    /* height: 83%; */
    height: calc(100% - 50px);
    overflow-y: auto;
    width: 100%;
    padding: 0.7rem;
    justify-content: center;
    align-items: center;
    /* overflow-y: scroll; */
    border: solid #ececec 1px;
    border-radius: 0px 0px 6px 6px;
    /* padding: 1rem 2rem; */
}

.list-placeholder {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.list-notification-icon {
    padding: 1rem;
    color: #444;
    font-size: 48px;
}

.list-notification-text {
    font-size: 15px;
    font-weight: bold;
}
</style>
