<template>
    <div style="width: 100%; margin: 10px 0px 0px 0px !important;">
        <DataTable
            id="result-data-table"
            ref="result-data-table"
            class="p-datatable data-table-container"
            :value="tableDatas"
            editMode="cell"
            :dataKey="keyField"
            :editingRows.sync="editingRows"
            showGridlines
            :selection.sync="selected"
            :scrollable="scrollable"
            :scrollHeight="scrollHeight"
            @cell-edit-complete="onCellEditComplete"
        >
            <!-- @row-edit-save="onRowEditSave"
        @row-reorder="onRowReorder" -->
            <template #empty>
                <div class="row m-0 justify-content-center">
                    <span class="m-0" style="font-size: 13px; font-weight: bold;">
                        {{ "표시할 데이터가 없습니다." }}
                    </span>
                </div>
            </template>
            <template #header>
                <div style="display: flex; align-items: center; justify-content: space-between;">
                    <div>
                        <span class="m-0 table-title">{{ viewTitle }}</span>
                    </div>
                    <div style="display: flex;">
                        <div style="margin-right: 0.5rem;">
                            <select
                                v-model="selectDate"
                                class="form-control"
                                name="selectDate"
                                id="selectDate"
                                @change="filterRowsBySelectDate"
                            >
                                <option :value="null">{{ "일자 선택" }}</option>
                                <option v-for="(item, index) in dateList" :key="`date-${item}-${index}`" :value="item">
                                    {{
                                        timeDsvn == "DAYBYMONTH"
                                            ? `${item}일`
                                            : timeDsvn == "MONTHBYYEAR"
                                            ? `${item}월`
                                            : item
                                    }}
                                </option>
                            </select>
                        </div>
                        <div v-if="!isEmpty(controls)" class="btn-group">
                            <button
                                v-for="(control, index) in controls"
                                :key="index"
                                type="button"
                                :class="'btn btn-sm ' + control.class"
                                :disabled="!ableExport && !isEditMode"
                                @click="handleButtonClick(control, index)"
                            >
                                {{ control.label }}
                            </button>
                        </div>
                        <div v-for="(control, index) in controls" :key="`file-input-${index}`">
                            <input
                                v-if="control.event == 'import'"
                                type="file"
                                :ref="'fileInput-' + index"
                                accept=".xls,.xlsx,.csv"
                                style="display: none;"
                                @change="importExcel"
                            />
                        </div>
                    </div>
                </div>
            </template>
            <!-- row 순서 이동 버튼 -->
            <!-- <Column
                v-if="isEditMode"
                :rowReorder="true"
                :reorderableColumn="false"
                :styles="{ width: '2%' }"
                :bodyStyle="{ 'text-align': 'center' }"
            /> -->
            <!-- 편집 버튼 -->
            <!-- <Column
                v-if="isEditMode"
                :rowEditor="true"
                :reorderableColumn="false"
                :styles="{ width: '2%' }"
                :bodyStyle="{ 'text-align': 'center' }"
            /> -->
            <!-- 선택 버튼 -->
            <!-- <Column
                v-if="isEditMode"
                selectionMode="multiple"
                :reorderableColumn="false"
                :exportable="false"
                :styles="{ width: '2%' }"
                :bodyStyle="{ 'text-align': 'center' }"
            /> -->
            <!-- table-data-row -->
            <Column
                v-for="col in tableColumns"
                :key="col.field"
                :field="col.field"
                :header="col.header"
                :reorderableColumn="false"
                :styles="{ width: `${col.width ?? ''}` }"
                :bodyStyle="{ 'text-align': 'center', 'font-size': '13px', 'font-weight': 'bold' }"
            >
                <!-- 테이블 내용 -->
                <template #body="{ data }">
                    <div>
                        {{ data[col.field] }}
                    </div>
                </template>
                <!-- 테이블 편집시 각 필드 편집 유형 ex) input, select, date-picker 등 -->
                <template v-if="isEditMode" #editor="slotProps">
                    <!-- <date-picker
                        v-if="slotProps.column.field == 'regDt'"
                        ref="datePicker"
                        v-model="slotProps.data[slotProps.column.field]"
                        :type="datePickerType"
                        :range="false"
                        :valueType="'YYYY-MM-DD'"
                        lang="ko"
                        :placeholder="'일자'"
                        :format="'YYYY-MM-DD'"
                    ></date-picker> -->
                    <InputText
                        v-if="
                            slotProps.column.field !== 'regDt' &&
                                slotProps.column.field !== 'sumAdjust' &&
                                slotProps.column.field !== 'sumValue' &&
                                slotProps.column.field !== 'value'
                        "
                        v-model="slotProps.data[slotProps.column.field]"
                        style="width: 100%; font-size: 0.8rem;"
                        autofocus
                    />
                    <div v-else>
                        {{ slotProps.data[slotProps.column.field] }}
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
// import MultiSelect from "primevue/multiselect";
// import DatePicker from "vue2-datepicker";
// import * as XLSX from "xlsx";

export default {
    props: {
        /**
         * primevue data table 은 column, row data 의 형식이 일치 해야함.
         * 이 컴포넌트를 사용하는 쪽에서 형식에 맞춰 구성해서 넘겨줘야함.
         */
        columns: { type: Array, default: () => [] }, // primevue DataTable 컬럼 형식
        rows: { type: Array, default: () => [] }, // Row-Data
        title: { type: String, default: "" },
        controls: { type: Array, default: () => [] }, // Table Header Control Buttons
        toggleFields: { type: Array, default: () => [] }, // status 필드 리스트
        mode: { type: String, default: "view" }, // Edit Mode (view/edit/new)
        keyField: { type: String, default: "" },
        isExcelData: { type: Boolean, default: false }, // 테이블 데이터 엑셀 export, import 버튼 활성화 여부
        scrollable: { type: Boolean, default: false },
        scrollHeight: { type: String, default: "" },
        newColumns: { type: Array, default: () => [] },
        timeDsvn: { type: String, default: "" },
        type: { type: String, default: "" },
        ableExport: { type: Boolean, default: false },
    },
    components: {
        DataTable,
        Column,
        InputText,
        // MultiSelect,
        // DatePicker,
    },
    data() {
        return {
            tableDatas: [],
            tableColumns: [],
            editingRows: [],
            selected: null,
            filters: {},
            selectDate: null,
            dateList: [],
            originTableDatas: [],
            excludedKeys: ["regDt", "sumValue", "type", "value", "sumAdjust"],
        };
    },
    computed: {
        viewTitle() {
            return this.title;
        },
        isEditMode() {
            return this.mode !== "view";
        },
    },
    watch: {
        columns(newVal) {
            this.tableColumns = [...newVal];
        },
    },
    async created() {
        this.tableDatas = this.rows;
        this.tableColumns = [...this.columns];
        this.originTableDatas = [...this.rows];
        // this.convertTableDataToNumbers();
        console.log(
            "tableDats tableColumns originTableDatas",
            this.tableDatas,
            this.tableColumns,
            this.originTableDatas
        );
        if (this.timeDsvn == "HOURBYDAY") {
            const reg = this.originTableDatas.map((data) => {
                return data.regDt.split("T")[1];
            });
            // console.log(reg);
            const uniqueReg = [...new Set(reg)];
            console.log("uniqueReg!!!!!!!!!!!!!!!!", uniqueReg);
            this.dateList = uniqueReg;
        } else if (this.timeDsvn == "DAYBYMONTH") {
            const reg = this.originTableDatas.map((data) => {
                return data.regDt.split("-")[2];
            });
            const uniqueReg = [...new Set(reg)];
            console.log("uniqueReg!!!!!!!!!!!!!!!!", uniqueReg);
            this.dateList = uniqueReg;
        } else if (this.timeDsvn == "MONTHBYYEAR") {
            const reg = this.originTableDatas.map((data) => {
                return data.regDt.split("-")[1];
            });
            const uniqueReg = [...new Set(reg)];
            console.log("uniqueReg!!!!!!!!!!!!!!!!", uniqueReg);
            this.dateList = uniqueReg;
        } else {
            this.dateList = [];
        }
    },
    mounted() {},
    methods: {
        filterRowsBySelectDate() {
            if (!this.isEmpty(this.selectDate)) {
                if (this.timeDsvn == "HOURBYDAY") {
                    this.tableDatas = this.originTableDatas.filter(
                        (data) => data.regDt.split("T")[1] == this.selectDate
                    );
                } else if (this.timeDsvn == "DAYBYMONTH") {
                    this.tableDatas = this.originTableDatas.filter(
                        (data) => data.regDt.split("-")[2] == this.selectDate
                    );
                } else if (this.timeDsvn == "MONTHBYYEAR") {
                    this.tableDatas = this.originTableDatas.filter(
                        (data) => data.regDt.split("-")[1] == this.selectDate
                    );
                } else this.tableDatas = this.originTableDatas;
            } else this.tableDatas = this.originTableDatas;
        },
        // table header button control function
        handleButtonClick(control, index) {
            console.log("control index", control, index);
            if (control.event == "export") this.excelExport();
            if (control.event == "import") this.triggerFileInput(index);
            else this.$emit("button-click", control.event, control.options);
        },
        triggerFileInput(index) {
            const fileInput = this.$refs[`fileInput-${index}`];
            if (fileInput) {
                fileInput[0].click(); // 배열로 반환되므로 [0] 사용
            }
        },
        importExcel(event) {
            this.$emit("import-data", event, this.type);
        },
        // data status change function
        handleToggle(key, field, status) {
            status = status === true ? "Y" : "N";
            this.$emit("toggle-changed", key, field, status);
        },
        // onRowEditSave(event) {
        //     // const uniqueNewColumns = [...new Set(this.newColumns)];
        //     let { newData, index } = event;

        //     newData.sumAdjust = this.newColumns.reduce((sum, key) => {
        //         const value = parseFloat(newData[key]) || 0;
        //         return sum + value;
        //     }, 0);

        //     newData.sumValue = newData.sumAdjust + newData.value;

        //     this.tableDatas[index] = newData;
        //     this.$emit("setEditTableData", this.tableDatas);
        // },
        convertTableDataToNumbers() {
            this.tableDatas = this.tableDatas.map((row) => {
                const convertedRow = {};
                Object.keys(row).forEach((key) => {
                    if (!this.excludedKeys.includes(key)) {
                        const value = parseFloat(row[key]);
                        convertedRow[key] = isNaN(value) ? 0 : value;
                    } else {
                        convertedRow[key] = row[key];
                    }
                });
                return convertedRow;
            });
        },
        onCellEditComplete(event) {
            let { newData, index } = event;

            if (this.newColumn !== []) {
                newData.sumAdjust = this.newColumns.reduce((sum, key) => {
                    const value = parseFloat(newData[key]) || 0;
                    return sum + value;
                }, 0);

                newData.sumValue = newData.sumAdjust + newData.value;
                this.tableDatas[index] = newData;
                this.convertTableDataToNumbers();
                console.log("tableDatas onCellEditCompelte", this.tableDatas);
                this.$emit("setEditTableData", this.tableDatas, newData.type, event.field);
                // this.$emit("updateTable", { newColumn: this.newColumns, newData: newData, index: index });
            } else this.tableDatas[index] = newData;
        },
        onRowReorder(event) {
            this.tableDatas = event.value;
        },
        onToggle(value) {
            this.tableColumns = this.columns.filter((col) => value.includes(col));
        },
        excelExport() {
            this.$refs["result-data-table"]?.exportCSV();
        },
        // excelExport() {
        //     var excelName = "excel_download.csv";
        //     var excelData = XLSX.utils.table_to_sheet(document.getElementById("result-data-table")); // table id를 넣어주면된다
        //     var workBook = XLSX.utils.book_new(); // 새 시트 생성

        //     XLSX.utils.book_append_sheet(workBook, excelData, excelName); // 시트 명명, 데이터 지정
        //     XLSX.writeFile(workBook, excelName); // 엑셀파일 만듬
        // },
    },
};
</script>

<style scoped>
.data-table-container {
    width: 100%;
    font-size: 0.9rem;
    margin-bottom: 0px !important;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}
.data-collapse-content {
    /* height: 83%; */
    width: 100%;
    padding: 0.5rem;
    /* justify-content: center;
    align-items: center; */
    /* overflow-y: scroll; */
    border: solid #efefef 1px;
    border-radius: 0px 0px 6px 6px;
    margin-bottom: 1rem;
    /* padding: 1rem 2rem; */
}
.table-title {
    font-weight: 600;
    font-size: 12px;
}
.custom-filter-input {
    height: 34px !important;
    font-size: 12px !important;
}
</style>
