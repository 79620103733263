var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font-weight-bold d-flex flex-row",style:({ fontSize: '15px' })},[_c('b-button',{ref:'searchBtn',staticClass:"m-l-5 m-r-5",staticStyle:{"border":"2px solid #348fe2","border-radius":"10px!important"},style:({
            padding: '5px',
            height: '34px',
            textAlign: 'center',
            backgroundColor: '#ffffff',
            alignItems: 'center',
            width: '55px',
            height: '100%',
            padding: 0,
            color: '#348fe2',
        }),attrs:{"squared":"","variant":"#ffffff","size":"md"},on:{"click":function($event){return _vm.onSearch()}}},[_c('span',[_vm._v(_vm._s(_vm.$t("조회")))])]),_c('b-button',{ref:'saveBtn',staticClass:"m-l-5 m-r-5",staticStyle:{"border":"2px solid #348fe2","border-radius":"10px!important"},style:({
            padding: '5px',
            height: '34px',
            textAlign: 'center',
            backgroundColor: '#ffffff',
            alignItems: 'center',
            width: '55px',
            height: '100%',
            padding: 0,
            color: '#348fe2',
        }),attrs:{"squared":"","variant":"#ffffff","size":"md"},on:{"click":function($event){return _vm.onSave()}}},[_c('span',[_vm._v(_vm._s(_vm.$t("저장")))])]),_c('b-button',{ref:'removeBtn',staticClass:"m-l-5 m-r-5",staticStyle:{"border":"2px solid #348fe2","border-radius":"10px!important"},style:({
            padding: '5px',
            height: '34px',
            textAlign: 'center',
            backgroundColor: '#ffffff',
            alignItems: 'center',
            width: '55px',
            height: '100%',
            padding: 0,
            color: '#348fe2',
        }),attrs:{"squared":"","variant":"#ffffff","size":"md"},on:{"click":function($event){return _vm.onRemove()}}},[_c('span',[_vm._v(_vm._s(_vm.$t("삭제")))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }