<template>
    <div class="mt-3">
        <div class="container d-flex">
            <!-- 사용 에너지원 -->
            <energy-source-code-combo-box :ref="'energySourceCodeComboBox'" @ready:data="changeEnergySourceCode" />

            <cost-reg-idx-combo-box :ref="'costRegIdxComboBox'" @changeRegIdx="changeRegIdxComboBox" />

            <cost-info-btn
                :ref="'costInfoBtn'"
                @save="onSave"
                @deleteSelect="onSelectRemove"
                @deleteAll="onRemoveAll"
            />
        </div>
    </div>
</template>

<script>
import energySourceCodeComboBox from "@views/component/comboBox/management/EnergySourceCodeComboBox.vue";
import costRegIdxComboBox from "@views/component/comboBox/management/CostRegIdxComboBox.vue";
import costInfoBtn from "./CostInfoBtn.vue";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
    props: [],
    components: {
        energySourceCodeComboBox,
        costRegIdxComboBox,
        costInfoBtn,
    },
    watch: {},
    data() {
        return {
            smallCodeList: null,
            popupMessages,
        };
    },
    created() {},
    mounted() {},
    methods: {
        changeRegIdxComboBox() {
            let costCode = this.$refs.costRegIdxComboBox.getValue();
            let energySourceCode = this.$refs.energySourceCodeComboBox.getValue();

            if (costCode == "new") {
                if (energySourceCode == "") {
                    this.alertNoti(popupMessages.COST_REGIST_ENERGY_VALID_POPUP_MESSAGE);

                    costCode = "";

                    // 사용 에너지원 포커스 이동
                    this.$refs.costRegIdxComboBox.setValue("");
                    this.$refs.energySourceCodeComboBox.focus();
                }
            }
            this.$emit("change:costCode", costCode);
        },
        getEnergySourceCode() {
            let energySourceCode = this.$refs.energySourceCodeComboBox.getValue();
            return energySourceCode;
        },
        setEnergySourceCode(energySourceCode) {
            this.$refs.energySourceCodeComboBox.setValue(energySourceCode);
        },
        setRegIdx(regIdx) {
            this.$refs.costRegIdxComboBox.setValue(regIdx);
        },
        changeEnergySourceCode(energySourceCode) {
            console.log(energySourceCode);

            this.$refs.costRegIdxComboBox.getRegList(energySourceCode);
        },
        reSearch(energySourceCode) {
            // 삭제 성공 후 요금제 생성내역 재조회
            this.$refs.costRegIdxComboBox.getRegList(energySourceCode);
            this.setRegIdx("");
        },
        onSave() {
            this.$emit("save");
        },
        onSelectRemove() {
            this.$emit("deleteSelect");
        },
        onRemoveAll() {
            this.$emit("deleteAll");
        },
    },
};
</script>

<style scoped></style>
