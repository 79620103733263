<template>
    <div class="row m-0">
        <!-- 스피너 오버레이 -->
        <div v-if="importIsLoading" class="spinner-overlay">
            <b-spinner class="align-middle"> </b-spinner>
        </div>

        <!-- 데이터 테이블 -->
        <div v-for="(key, index) in Object.keys(data)" :key="`${key}-data-table-${index}`" style="width: 100%;">
            <MnvDataTable
                ref="data-table-list"
                :columns="columns"
                :rows="data[key]"
                :title="key == 'baseline' ? '독립 변수 관리 (베이스라인)' : '독립 변수 관리 (보고 기간)'"
                :keyField="'regDt'"
                :type="key"
                :controls="controls"
                :scrollable="true"
                :scrollHeight="'300px'"
                :timeDsvn="detailInfo.timeDsvn"
                :ableExport="true"
                @button-click="handleListButtonClick"
                @import-data="importData"
                @setEditTableData="setEditTableData"
            />
            <!-- 하단에 props는 edit모드를 지원하는 옵션으로 추후에 수정이 가능하다면 상단 컴포넌트에 추가만 해주면됨 -->
            <!--     :mode="'edit'" -->
        </div>
    </div>
</template>

<script>
import MnvDataTable from "../tables/MnvDataTable.vue";
import * as XLSX from "xlsx";
import moment from "moment";

export default {
    props: ["detailInfo", "resultData", "data", "columns"],
    components: {
        MnvDataTable,
    },
    data() {
        return {
            controls: [
                { event: "export", label: "내보내기", class: "btn-primary" },
                // { event: "import", label: "불러오기", class: "btn-danger" },
            ],
            importIsLoading: false,
        };
    },
    computed: {},
    watch: {},
    created() {
        this.getSubPropResultData();
    },
    mounted() {},
    methods: {
        async getSubPropResultData() {
            const findUserRoleType = this.detailInfo.variables.filter((item) => item.srcType == "User");
            if (!this.isEmpty(findUserRoleType) && !this.isEmpty(this.resultData)) {
                findUserRoleType.forEach((item) => {
                    this.columns.push({
                        field: item.name,
                        header: item.name ?? "-",
                    });
                });
                this.subPropResultData = this.resultData.values.regDt.map((date) => {
                    const row = { regDt: this.formatDateData(date) };
                    this.columns.forEach((col) => {
                        if (col.field !== "regDt") {
                            row[col.field] = 0;
                        }
                    });
                    return row;
                });
            } else this.subPropResultData = [];
        },
        formatDateData(regDt) {
            let date;
            if (this.detailInfo.timeDsvn == "HOURBYDAY") {
                date = moment(regDt).format("YYYY-MM-DD HH:mm");
            } else if (this.detailInfo.timeDsvn == "DAYBYMONTH") {
                date = moment(regDt).format("YYYY-MM-DD");
            } else if (this.detailInfotimeDsvn == "MONTHBYYEAR") {
                date = moment(regDt).format("YYYY-MM");
            } else date = "-";
            return date;
        },
        async handleListButtonClick(event, options) {
            switch (event) {
                case "export":
                    await this.exportData();
                    break;
                case "import":
                    await this.importData();
                    break;
                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
        async exportData() {
            this.$refs["data-table-list"].excelExport();
            this.alertNoti("다운로드가 시작됩니다.");
        },
        async importData(event, type) {
            this.importIsLoading = true;
            const file = event.target.files[0];
            if (!file) return;

            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: "array" });

                // 첫 번째 시트를 가져옴
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];

                // 시트 데이터를 JSON으로 변환
                const jsonData = XLSX.utils.sheet_to_json(sheet);
                console.log(jsonData);

                // JSON 데이터를 가공하여 regDt 제외
                const jsonDataKeyFormat = jsonData.map((item) => {
                    let dynamicKeys = {};
                    for (let key in item) {
                        const findField = this.columns.find((col) => col.header == key);
                        if (!findField || findField.field === "regDt") continue; // regDt 제외
                        dynamicKeys[findField.field] = item[key];
                    }
                    return dynamicKeys;
                });

                console.log("Before subPropResultData", this.subPropResultData);
                this.subPropResultData.splice(0, this.subPropResultData.length, ...jsonDataKeyFormat);
                console.log("After subPropResultData", this.subPropResultData);

                // Emit 데이터를 전달
                this.$emit("set-fix-data", this.subPropResultData, type);
                this.$emit("import-independ-excel-data", this.subPropResultData, type);
                this.importIsLoading = false;
            };

            reader.readAsArrayBuffer(file);
            event.target.value = null;
        },
        setEditTableData(data, type, field) {
            this.$emit("set-fix-data", data, type, field);
        },
    },
};
</script>
<style scoped>
.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.1);
    z-index: 9999;
    transition: background-color 0.9s ease;
}
</style>
