<template>
    <div>
        <b-modal centered ref="EquipControl" @hidden="hideControlModal">
            <template #modal-header>
                <span v-if="ctrlType == 'Group'" class="ctrl-modal-header">그룹 운전 제어</span>
                <span v-else-if="ctrlType == 'Select'" class="ctrl-modal-header">멀티 운전 제어</span>
                <span v-else class="ctrl-modal-header">설비 운전 제어</span>
            </template>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button type="button" class="btn btn-primary ctrl-modal-btn" @click="equipCtrl">적용</button>
                    <button type="button" class="btn btn-secondary ctrl-modal-btn" @click="hideControlModal">닫기</button>
                </div>
            </template>
            <div v-if="equipData" class="detail-container mb-3">
                <div class="detail-content">
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <span v-if="ctrlType == 'Group'" style="font-weight: 600; font-size: 0.8rem;">
                                {{ "다음 그룹에 포함된 전체 설비의 운전을 일괄 제어합니다." }}
                            </span>
                            <span v-else-if="ctrlType == 'Select'" style="font-weight: 600; font-size: 0.8rem;">
                                {{ "선택된 설비의 운전을 일괄 제어합니다." }}
                            </span>
                            <span v-else style="font-weight: 600; font-size: 0.8rem;">
                                {{ "선택된 설비의 운전을 제어합니다." }}
                            </span>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="ctrlTarget">제어대상</label>
                            <input v-if="ctrlType == 'Unit'" type="text" v-model="equipData.equipName"
                                class="form-control" id="ctrlTarget" disabled>
                            <input v-else type="text" :value="`${equipData[0].equipName} 등`" class="form-control"
                                id="ctrlTarget" disabled>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="ctrlEquipCnt">제어 설비수</label>
                            <div class="d-flex col-md-12 pl-0">
                                <input v-if="ctrlType == 'Unit'" type="text" class="form-control col-md-10 mr-1"
                                    id="ctrlEquipCnt" :value="'1개'" disabled>
                                <input v-else type="text" class="form-control col-md-10 mr-1" id="ctrlEquipCnt"
                                    :value="`${equipData.length}개`" disabled>
                                <button class="btn btn-secondary" @click="showEquipCntModal">...</button>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="equipType">설비 유형</label>
                            <select v-if="ctrlType == 'Unit'" v-model="equipData.equipType" class="form-control"
                                id="equipType" disabled>
                                <option v-for="(type) in equipTypeList" :key="type.equipType" :value="type.equipType">
                                    {{ type.equipType }}
                                </option>
                            </select>
                            <select v-else v-model="equipData[0].equipType" class="form-control" id="equipType"
                                disabled>
                                <option v-for="(type) in equipTypeList" :key="type.equipType" :value="type.equipType">
                                    {{ type.equipType }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="delayMin">지연 제어 시간</label>
                            <select v-model="delayMin" class="form-control" id="delayMin">
                                <option :value="0">선택</option>
                                <option v-for="(min, index) in delayMinItems" :key="index" :value="min.value">{{
                                    min.text }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="ctrlMode">제어 모드</label>
                            <select v-model="ctrlMode" class="form-control" id="ctrlMode" @change="getProperty">
                                <option value="">제어모드 선택</option>
                                <option v-for="(mode, index) in ctrlModeList" :key="index" :value="mode.ctrlMode">{{
                                    mode.ctrlModeName }}</option>
                            </select>
                        </div>
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="useBasicValue">
                            <label class="mb-0 mt-1" for="useBasicValue">제어 기본값으로 사용</label>
                        </div>
                    </div>
                </div>
                <div v-if="property" class="detail-content mt-3">
                    <div class="form">
                        <p class="mb-2" style="font-weight: 600; font-size: 0.8rem;">제어 설정</p>

                        <div v-for="(prop, index) in property" :key="index">
                            <div v-if="prop.encodeType == 'Enum' && prop.encodeDesc" class="mb-3">
                                <b-input-group>
                                    <b-input-group-prepend class="prepend-style">
                                        <span>{{ prop.propName }}</span>
                                    </b-input-group-prepend>
                                    <b-input-group-append>
                                        <b-button v-for="(item, idx) in JSON.parse(prop.encodeDesc)" :key="idx"
                                            class="btn"
                                            :class="activeButtons[index] === idx ? 'btn-warning' : 'btn-light'"
                                            @click="setActiveButton(index, idx, idx)">
                                            <trans>{{ item }}</trans>
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                            <div v-else-if="(prop.encodeType == 'Range' || prop.encodeType == 'Number') && prop.encodeDesc"
                                class="mb-3">
                                <b-input-group>
                                    <b-input-group-prepend class="prepend-style">
                                        <span>{{ prop.propName }}</span>
                                    </b-input-group-prepend>
                                    <b-form-input type="range" v-model="propValue[index]" :id="prop.propCode"
                                        :min="JSON.parse(prop.encodeDesc).min" :max="JSON.parse(prop.encodeDesc).max"
                                        :step="JSON.parse(prop.encodeDesc).offset"></b-form-input>
                                    <b-input-group-append class="append-style">
                                        <span>{{ propValue[index] ? propValue[index] : '23' }}</span>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                            <div v-else class="mb-3">
                                <b-input-group>
                                    <b-input-group-prepend class="prepend-style">
                                        <span>{{ prop.propName }}</span>
                                    </b-input-group-prepend>
                                    <b-form-input type="range" v-model="propValue[index]" min="16" max="30"
                                        step="0.5"></b-form-input>
                                    <b-input-group-append class="append-style">
                                        <span>{{ propValue[index] ? propValue[index] : '23' }}</span>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <b-modal centered ref="CtrlEquipCnt" size="sm" @hidden="hideEquipCntModal">
                <template #modal-header>
                    <span class="ctrl-modal-header">제어 대상 설비 목록</span>
                </template>
                <template #modal-footer>
                    <div style="display: flex; flex: 1;">
                        <button type="button" class="btn btn-secondary ctrl-modal-btn"
                            @click="hideEquipCntModal">닫기</button>
                    </div>
                </template>
                <div class="form-row">
                    <div v-if="ctrlType !== 'Unit'" class="form-group col-md-12">
                        <label for="ctrlEquipList">설비 번호</label>
                        <div class="list-con">
                            <span v-for="(item, index) in equipData" :key="index">
                                {{ `${item.equipIdx}${index == equipData.length - 1 ? '' : ', '}` }}
                            </span>
                        </div>
                    </div>
                    <div v-else class="form-group col-md-12">
                        <label for="ctrlEquipList">설비 번호</label>
                        <div class="list-con">
                            <span>{{ equipData.equipIdx }}</span>
                        </div>
                    </div>
                </div>
            </b-modal>
        </b-modal>
    </div>
</template>

<script>
import backEndApi from '@src/api/backEndApi';

export default {
    props: ["ctrlType", "equipData", "currentNode"],
    components: {
    },
    data() {
        return {
            ctrlMode: "Basic",
            ctrlModeList: null,
            ctrlPropList: null,
            property: null,
            propValue: [],
            delayMin: 0,
            delayMinItems: [
                { text: "1분", value: 1 },
                { text: "5분", value: 5 },
                { text: "10분", value: 10 },
                { text: "20분", value: 20 },
                { text: "30분", value: 30 },
                { text: "40분", value: 40 },
                { text: "50분", value: 50 },
                { text: "1시간", value: 60 },
            ],
            equipTypeList: null,
            equipStatus: null,
            activeButtons: [],
        }
    },
    watch: {
    },
    computed: {
    },
    async created() {
        // await this.getControlModeList();
        // await this.getEquipTypeList();
    },
    async mounted() {
        await this.getControlModeList();
        await this.getEquipTypeList();
        if (this.ctrlMode) {
            await this.getProperty();
            await this.setProperty();
        }
    },
    destroyed() {
    },
    methods: {
        setActiveButton(groupIndex, buttonIndex, value) {
            this.$set(this.activeButtons, groupIndex, buttonIndex);
            this.$set(this.propValue, groupIndex, value);
        },
        showEquipCntModal() {
            this.$refs.CtrlEquipCnt.show();
        },
        hideEquipCntModal() {
            this.$refs.CtrlEquipCnt.hide();
        },
        showControlModal() {
            this.$refs.EquipControl.show();
        },
        hideControlModal() {
            this.$refs.EquipControl.hide();
            this.$emit("clear-data");
        },
        async equipCtrl() {
            // let ptVal;
            // if (this.ctrlType === "Unit") ptVal = this.equipData.ioProps[0].ptVal

            if (this.ctrlType !== "Group")
                await this.$emit("equip-control", this.equipData, this.property, this.propValue, this.ctrlMode, this.delayMin);
            else await this.$emit("equip-control", this.equipData, this.property, this.propValue, this.ctrlMode, this.delayMin);
            // this.hideControlModal();
        },
        async getControlModeList() {
            const equipType = this.ctrlType == 'Unit' ? this.equipData.equipType : this.equipData[0].equipType;
            try {
                let result = await backEndApi.equipTypes.getEquipType(equipType, "Y");
                if (result.data) {
                    this.ctrlModeList = result.data.ctrlModes;
                    this.ctrlPropList = result.data.ioProps.filter(prop => prop.ioType === 'Both');
                }
            } catch (e) {
                console.error(e);
            }
        },

        // Modified
        
        async getProperty() {
            this.propValue = [];
            this.activeButtons = [];
            if (this.ctrlMode !== "") {
                const findMode = this.ctrlModeList.find(item => item.ctrlMode == this.ctrlMode);
                const matchingProps = findMode.outProps.map(outProp => {
                    return this.ctrlPropList.find(ctrlProp => ctrlProp.propCode === outProp.propCode);
                }).filter(element => element);
                this.property = matchingProps;
            } else {
                this.property = null;
                this.propValue = [];
            }
        },
        async setProperty() {
            if (this.ctrlMode !== "") {
                const findMode = this.ctrlModeList.find(item => item.ctrlMode == this.ctrlMode);
                const matchingProps = findMode.outProps.map(outProp => {
                    return this.ctrlPropList.find(ctrlProp => ctrlProp.propCode === outProp.propCode);
                }).filter(element => element);
                this.property = matchingProps;

                if(this.ctrlType == 'Group' || this.ctrlType == 'Select') {
                    await this.property.forEach((item, index) => {
                        let find = this.equipData[0].ioProps.find(io => io.propCode == item.propCode);
                        if(item.encodeDesc) {
                            this.setActiveButton(index, String(find.ptVal), Object.keys(JSON.parse(item.encodeDesc))[find.ptVal]);
                        } else {
                            this.propValue.push(find.ptVal);
                        } 
                    })

                } else {
                    await this.property.forEach((item, index) => {
                        let find = this.equipData.ioProps.find(io => io.propCode == item.propCode);
                        if(item.encodeDesc) {
                            this.setActiveButton(index, String(find.ptVal), Object.keys(JSON.parse(item.encodeDesc))[find.ptVal]);
                        } else {
                            this.propValue.push(find.ptVal);
                        } 
                    })
                }
            }
        },
        async getEquipTypeList() {
            try {
                let result = await backEndApi.equipTypes.getEquipTypeList("Y");
                if (result.status == 200) {
                    this.equipTypeList = result.data;
                }
            } catch (e) {
                console.error(e)
            }
        },
    },
}
</script>

<style scoped>
.input-group-prepend .input-group-text {
    width: 100%;
    text-align: right;
}

.detail-container {
    height: 100%;
    /* width: 100%; */
    /* border-radius: 8px; */
    /* border: solid #eee 1px; */
    /* background-color: white; */
    /* margin: 1rem; */
    font-size: 12px;
}

.detail-placeholder {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.detail-header {
    background-color: #fff;
    border-radius: 8px 8px 0px 0px;
    padding: 0.8rem 1rem;
    /* margin: 1rem 0rem; */
}

.detail-content {
    height: 88%;
    width: 100%;
    border-radius: 8px;
    border: solid #eee 1px;
    padding: 1rem 1rem;
    /* overflow-y: auto; */
}

.detail-bottom {
    display: flex;
    justify-content: space-between;
}

.form-check {
    display: flex !important;
    align-items: center;
    margin-left: 1rem;
}

.ctrl-modal-header {
    font-size: 14px;
    font-weight: bold;
}

.ctrl-modal-btn {
    flex: 1;
    margin: 0px 5px 0px 5px;
}

.list-con {
    border-radius: 8px;
    border: solid #eee 1px;
    padding: 1rem;
}

.prepend-style {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #999;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: white;
}

.append-style {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #999;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: white;
}
</style>