<template>
    <div v-if="mnvReportData">
        <div
            v-for="(key, index) in mainKey"
            :key="`${key}-analysis-result`"
            :style="index == mainKey.length - 1 ? { borderBottom: '1px solid #ececec' } : {}"
        >
            <span v-if="mainKey.length > 1" class="mb-1" style="font-weight: bold;">
                {{ `${key} 분석 모델 정보` }}
            </span>
            <b-table-simple class="text-center" bordered responsive>
                <b-thead>
                    <b-tr>
                        <b-td class="table-header-bg" colspan="2">{{ "분석 변수" }}</b-td>
                        <b-th>{{ mnvReportData.model[key].dependent }}</b-th>
                    </b-tr>
                    <b-tr>
                        <b-td class="table-header-bg" colspan="2">{{ "분석 모델식" }}</b-td>
                        <b-th :style="{ backgroundColor: '#FBE5D6' }">
                            {{ mnvReportData.model[key].equation }}
                        </b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td colspan="2" class="align-middle table-header-bg">
                            {{ "개선률" }}
                        </b-td>
                        <b-th class="p-0">
                            <b-table-simple class="mb-0">
                                <b-thead>
                                    <b-tr class="table-header-bg">
                                        <b-td
                                            v-for="(item, i) in mnvReportData.analysisOverview[key].report.details"
                                            :key="i"
                                            >{{ localPeriodName[i + 1] }}</b-td
                                        >
                                        <b-td>{{ "전체" }}</b-td>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr>
                                        <b-th
                                            v-for="(item, i) in mnvReportData.analysisOverview[key].report.details"
                                            :key="i"
                                            >{{ item.reduce }} %
                                        </b-th>
                                        <b-th>{{ mnvReportData.analysisOverview[key].report.reduce }} %</b-th>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </b-th>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <b-table-simple class="text-center" bordered responsive>
                <b-thead>
                    <b-tr class="table-header-bg">
                        <b-td :colspan="mnvReportData.model[key].pValues.slice(1).length">
                            {{ "P-Value 테스트" }}
                        </b-td>
                        <b-td class="align-middle" rowspan="2">{{ "모델 P-Value" }}</b-td>
                        <b-td class="align-middle" rowspan="2">{{ "모델 R2" }}</b-td>
                        <b-td class="align-middle" rowspan="2">{{ "수정된 모델 R2" }}</b-td>
                        <b-td class="align-middle" rowspan="2">{{ "모델의 표준 편차 (S)" }}</b-td>
                        <b-td class="align-middle" rowspan="2">{{ "모델의 평균 오차 (MBE)" }}</b-td>
                        <b-td class="align-middle" rowspan="2">{{ "모델의 변동 계수 CvRMSE" }}</b-td>
                        <b-td class="align-middle" rowspan="2">{{ "Method" }}</b-td>
                    </b-tr>
                    <b-tr class="table-header-bg">
                        <b-td v-for="(item, i) in mnvReportData.model[key].independents" :key="i">
                            {{ item }}
                        </b-td>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-th v-for="(item, i) in mnvReportData.model[key].pValues.slice(1)" :key="i">
                            {{ item }}
                        </b-th>
                        <b-th>{{ mnvReportData.model[key].modelPValue }}</b-th>
                        <b-th>{{ mnvReportData.model[key].R2 }}</b-th>
                        <b-th>{{ mnvReportData.model[key].adjustedR2 }}</b-th>
                        <b-th>{{ mnvReportData.model[key].stdDeviation }}</b-th>
                        <b-th>{{ mnvReportData.model[key].meanAbsError }}</b-th>
                        <b-th>{{ mnvReportData.model[key].cvrmse }}</b-th>
                        <b-th>{{ detailInfo.mnvMethod }}</b-th>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>
        <!-- 차트 데이터 합쳐서 하나로 그려야함 by woonsik 250107 -->
        <!-- <div
            v-for="(key, index) in mainKey"
            :key="`${key}-chart-info`"
            class="mt-3"
            :style="index == mainKey.length - 1 ? { borderBottom: '1px solid #ececec' } : {}"
        >
            <span v-if="mainKey.length > 1" class="mb-1" style="font-weight: bold;">
                {{ `${key} 분석 차트` }}
            </span>
            <div class="row" style="margin: 0px 0px 1rem 0px !important; height: 450px;">
                <div class="col-9" style="height: 100%;">
                    <MnvChart :mnvReportData="mnvReportData" :periodName="periodName" :reportKey="key" />
                </div>
                <div class="col-3 pr-0" style="height: 100%;">
                    <div class="col-12 p-0" style="height: 50%; ">
                        <MnvResultSavingChartPanel :mnvReportData="mnvReportData" :reportKey="key" />
                    </div>
                    <div class="col-12 p-0" style="height: 50%;">
                        <MnvResultImproveChartPanel :mnvReportData="mnvReportData" :reportKey="key" />
                    </div>
                </div>
            </div>
        </div> -->
        <div class="row" style="margin: 1rem 0px 0px 0px !important; height: 450px; border-bottom: 1px solid #ececec;">
            <div class="col-9" style="height: 100%;">
                <MnvChart :mnvReportData="mnvReportData" :periodName="periodName" />
            </div>
            <div class="col-3 pr-0" style="height: 100%;">
                <div class="col-12 p-0" style="height: 50%; ">
                    <MnvResultSavingChartPanel :mnvReportData="mnvReportData" />
                </div>
                <div class="col-12 p-0" style="height: 50%;">
                    <MnvResultImproveChartPanel :mnvReportData="mnvReportData" />
                </div>
            </div>
        </div>

        <b-table-simple bordered class="text-center mt-3">
            <thead>
                <tr class="table-header-bg">
                    <th style="width: 200px;">{{ "보고 기간" }}</th>
                    <th>{{ "주 분석 대상" }}</th>
                    <th>{{ "베이스라인 소비량" }}</th>
                    <th>{{ "소비량" }}</th>
                    <th>{{ "절감량" }}</th>
                    <th>{{ "누적 절감량" }}</th>
                    <th>{{ "개선률" }}</th>
                    <th>{{ "누적 개선률" }}</th>
                    <th>{{ "온실가스 절감량" }}</th>
                    <th>{{ "누적 온실가스 절감량" }}</th>
                    <th>{{ "에너지 절감비용" }}</th>
                    <th>{{ "누적 에너지 절감비용" }}</th>
                </tr>
            </thead>
            <tbody
                v-for="(item, index) in mnvReportData.analysisOverview[mainKey[0]].report.details"
                :key="`report-table-${index}`"
            >
                <tr>
                    <!-- 보고 기간 -->
                    <td class="align-middle" :rowspan="mainKey.length > 1 ? 7 : 0">
                        <p class="mb-0">{{ `${periodName[index + 1]}` }}</p>
                        <p class="mb-0">
                            {{
                                `(${mnvReportData.data.report[index].regDt[0]} ~ ${
                                    mnvReportData.data.report[index].regDt[
                                        mnvReportData.data.report[index].regDt.length - 1
                                    ]
                                })`
                            }}
                        </p>
                    </td>
                </tr>
                <tr v-for="(key, keyIndex) in mainKey" :key="`${key}-table-row-${keyIndex}`">
                    <!-- 주 분석 대상 -->
                    <td class="align-middle">{{ key ?? "-" }}</td>
                    <!-- 베이스라인 소비량 -->
                    <td class="align-middle">
                        {{ mnvReportData.analysisOverview[key].baseline.measure ?? "-" }}
                        {{ getConvertUint(key) }}
                    </td>
                    <!-- 소비량 -->
                    <td class="align-middle">
                        {{ mnvReportData.analysisOverview[key].report.details[index].measure ?? "-" }}
                        {{ getConvertUint(key) }}
                    </td>
                    <!-- 절감량 -->
                    <td class="align-middle">
                        {{ mnvReportData.analysisOverview[key].report.details[index].offset ?? "-" }}
                        {{ getConvertUint(key) }}
                    </td>
                    <!-- 누적 절감량 -->
                    <td class="align-middle">
                        {{ mnvReportData.analysisOverview[key].report.details[index].accOffset ?? "-" }}
                        {{ getConvertUint(key) }}
                    </td>
                    <!-- 개선률 -->
                    <td class="align-middle">
                        {{ mnvReportData.analysisOverview[key].report.details[index].reduce ?? "-" }} {{ "%" }}
                    </td>
                    <!-- 누적 개선률 -->
                    <td class="align-middle">
                        {{ mnvReportData.analysisOverview[key].report.details[index].accReduce ?? "-" }} {{ "%" }}
                    </td>
                    <!-- 온실가스 절감량 -->
                    <td class="align-middle">
                        {{ mnvReportData.analysisOverview[key].report.details[index].CO2Emit ?? "-" }}
                        {{ getCO2Unit(key) }}
                    </td>
                    <!-- 누적 온실가스 절감량 -->
                    <td class="align-middle">
                        {{ mnvReportData.analysisOverview[key].report.details[index].accCO2Emit ?? "-" }}
                        {{ getCO2Unit(key) }}
                    </td>
                    <!-- 에너지 절감비용 -->
                    <td class="align-middle">
                        {{ mnvReportData.analysisOverview[key].report.details[index].cost ?? "-" }} {{ "원" }}
                    </td>
                    <!-- 누적 에너지 절감비용 -->
                    <td class="align-middle">
                        {{ mnvReportData.analysisOverview[key].report.details[index].accCost ?? "-" }} {{ "원" }}
                    </td>
                </tr>
                <tr v-if="mainKey.length > 1">
                    <td class="font-weight-bold">{{ "합계" }}</td>
                    <!-- 베이스라인 소비량 -->
                    <td>
                        {{ mnvReportData.analysisOverview["summary"].baseline.measure ?? "-" }}
                        {{ getConvertUint(mainKey[0]) }}
                    </td>
                    <!-- 소비량 -->
                    <td>
                        {{ mergedDetails[index].measure.toFixed(1) ?? "-" }}
                        {{ getConvertUint(mainKey[0]) }}
                    </td>
                    <!-- 절감량 -->
                    <td>
                        {{ mergedDetails[index].offset.toFixed(1) ?? "-" }}
                        {{ getConvertUint(mainKey[0]) }}
                    </td>
                    <!-- 누적 절감량  -->
                    <td>
                        {{ mergedDetails[index].accOffset.toFixed(1) ?? "-" }}
                        {{ getConvertUint(mainKey[0]) }}
                    </td>
                    <!-- 개선률 -->
                    <td>
                        {{ (mergedDetails[index].predict / mergedDetails[index].offset).toFixed(1) ?? "-" }} {{ "%" }}
                    </td>
                    <!-- 누적 개선률 -->
                    <td>
                        {{ (mergedDetails[index].predict / mergedDetails[index].offset).toFixed(1) ?? "-" }} {{ "%" }}
                    </td>
                    <!-- 온실가스 절감량 -->
                    <td>
                        {{ mergedDetails[index].CO2Emit.toFixed(6) ?? "-" }}
                        {{ getCO2Unit(mainKey[0]) }}
                    </td>
                    <!-- 누적 온실가스 절감량 -->
                    <td>
                        {{ mergedDetails[index].accCO2Emit.toFixed(6) ?? "-" }}
                        {{ getCO2Unit(mainKey[0]) }}
                    </td>
                    <!-- 에너지 절감비용 -->
                    <td>{{ mergedDetails[index].cost.toFixed(1) ?? "-" }} {{ "원" }}</td>
                    <!-- 누적 에너지 절감비용 -->
                    <td>{{ mergedDetails[index].accCost.toFixed(1) ?? "-" }} {{ "원" }}</td>
                </tr>
            </tbody>
        </b-table-simple>
    </div>
</template>

<script>
import MnvChart from "../charts/MnvChart.vue";
import MnvResultSavingChartPanel from "../charts/MnvResultSavingChartPanel.vue";
import MnvResultImproveChartPanel from "../charts/MnvResultImproveChartPanel.vue";

export default {
    props: ["mnvReportData", "resultData", "detailInfo", "periodName"],
    components: {
        MnvChart,
        MnvResultSavingChartPanel,
        MnvResultImproveChartPanel,
    },
    data() {
        return {
            localPeriodName: [],
        };
    },
    computed: {
        slicedPvalues() {
            return this.mnvReportData.model.pValues.slice(1);
        },
        mainKey() {
            return this.resultData.variable.main.map((item) => item.key);
        },
        allDetails() {
            let array = [];
            if (this.mainKey.length > 1) {
                array = this.mainKey.map((key) => {
                    return this.mnvReportData.analysisOverview[key]?.report?.details || [];
                });
            }
            return array;
        },
        mergedDetails() {
            const maxLength = Math.max(...this.allDetails.map((details) => details.length));
            return Array.from({ length: maxLength }).map((_, index) => {
                const mergedItem = {};

                this.allDetails.forEach((details) => {
                    const item = details[index] || {};
                    Object.keys(item).forEach((key) => {
                        mergedItem[key] = (mergedItem[key] || 0) + (item[key] || 0);
                    });
                });

                return mergedItem;
            });
        },
    },
    watch: {
        periodName: {
            immediate: true,
            handler(newVal) {
                this.localPeriodName = [...newVal];
            },
        },
    },
    created() {},
    mounted() {},
    methods: {
        getConvertUint(key) {
            return this.mnvReportData.variable.main.find((item) => item.key == key)
                ? this.mnvReportData.variable.main.find((item) => item.key == key).factor.cnvtUnit
                : "-";
        },
        getCO2Unit(key) {
            return this.mnvReportData.variable.main.find((item) => item.key == key)
                ? this.mnvReportData.variable.main.find((item) => item.key == key).factor.CO2Unit
                : "-";
        },
    },
};
</script>

<style scoped>
.table-header-bg {
    background-color: #eceff1;
}
</style>
