<template>
    <div class="row m-0 d-flex justify-content-around">
        <div class="col-lg-3 mr-2">
            <div class="row align-items-center" style="margin: 0px 0px 10px 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="ptIdx">{{ "분석 대상 : " }}</label>
                </div>
                <div class="col-9 pr-0">
                    <div class="input-group">
                        <input
                            class="form-control"
                            type="text"
                            :value="
                                detailInfo.analysisMethod !== 'MNV'
                                    ? targetInfo
                                        ? `${targetInfo.name} (${targetInfo.ptIdx})`
                                        : '-'
                                    : '-'
                            "
                            :placeholder="targetInfo ? '' : '데이터를 선택하세요.'"
                            readonly
                        />
                        <div class="input-group-append">
                            <button
                                class="btn btn-secondary"
                                type="button"
                                :disabled="disableTargetBtn"
                                @click="handleMethodModal('target')"
                            >
                                {{ "선택" }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row align-items-center" style="margin: 0px 0px 10px 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="analysisMethod">{{ "분석 유형 : " }}</label>
                </div>
                <div class="col-9 pr-0">
                    <select class="form-control" id="analysisMethod" v-model="detailInfo.analysisMethod">
                        <option :value="null">{{ "분석 유형 선택" }}</option>
                        <option
                            v-for="method in $store.state.commonCodes.analysisMethod"
                            :key="method.value"
                            :value="method.value"
                        >
                            {{ method.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row align-items-center" style="margin: 0px 0px 10px 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="analysisAlgorithm">{{ "분석 방법 : " }}</label>
                </div>
                <div class="col-9 pr-0">
                    <div class="row m-0">
                        <div class="col-6 p-0">
                            <select class="form-control" id="analysisAlgorithm">
                                <option
                                    v-for="type in $store.state.commonCodes.analysisAlgorithm"
                                    :key="type.value"
                                    :value="type.value"
                                >
                                    {{ type.text }}
                                </option>
                            </select>
                        </div>
                        <div class="col-6 pr-0">
                            <select
                                v-model="detailInfo.mnvMethod"
                                class="form-control"
                                :disabled="detailInfo.analysisMethod !== 'MNV'"
                            >
                                <option :value="null">{{ "MNV 분석 선택" }}</option>
                                <option
                                    v-for="type in $store.state.commonCodes.MnvMethod"
                                    :key="type.value"
                                    :value="type.value"
                                >
                                    {{ type.text }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row align-items-center" style="margin: 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="dispType">{{ "표시 방법 : " }}</label>
                </div>
                <div class="col-9 pr-0">
                    <div class="row m-0">
                        <div class="col-6 p-0">
                            <select
                                v-model="detailInfo.dispType"
                                class="form-control"
                                name="dispType"
                                :disabled="detailInfo.analysisMethod == 'MNV'"
                            >
                                <option :value="null">{{ "차트 유형 선택" }}</option>
                                <option
                                    v-for="type in $store.state.commonCodes.dispType"
                                    :key="type.value"
                                    :value="type.value"
                                >
                                    {{ type.text }}
                                </option>
                            </select>
                        </div>
                        <div class="col-6 pr-0">
                            <select v-model="detailInfo.dispUnit" class="form-control" name="dispUnit">
                                <option :value="null">{{ "표시 단위 선택" }}</option>
                                <option
                                    v-for="type in $store.state.commonCodes.dispUnit"
                                    :key="type.value"
                                    :value="type.value"
                                >
                                    {{ type.text }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2">
            <div class="row m-0">
                <div class="col-12 p-0 mb-2 d-flex justify-content-between align-items-center">
                    <div>
                        <label class="m-0">{{ refDataTitle }}</label>
                    </div>
                    <div class="btn-group">
                        <button
                            class="btn btn-sm btn-warning"
                            type="button"
                            @click="handleMethodModal('firstVar')"
                            :disabled="detailInfo.analysisMethod == 'Trend' || detailInfo.analysisMethod == 'Reltn'"
                        >
                            {{ "추가" }}
                        </button>
                        <button
                            class="btn btn-sm btn-danger"
                            type="button"
                            :disabled="detailInfo.analysisMethod == 'Trend' || detailInfo.analysisMethod == 'Reltn'"
                            @click="deleteComponentPoint"
                        >
                            {{ "삭제" }}
                        </button>
                        <!--       @click="showSideBar('member')" -->
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="point-list-ctn">
                        <div
                            v-for="(member, index) in mamberPtList"
                            :key="member.ptIdx"
                            class="form-check"
                            style="margin-bottom: 0.2rem; display: flex; align-items: center;"
                        >
                            <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="checkCompPoint[index]"
                                :id="member.ptIdx"
                            />
                            <label class="form-check-label" :for="member.ptIdx">
                                <span class="badge mr-1">{{ member.srcType ?? "-" }}</span>
                                <span @click.stop="showChangePropertyName(member)" style="cursor: pointer;">
                                    <!-- {{
                                        pointList.find((point) => point.ptIdx == member.ptIdx)
                                            ? pointList.find((point) => point.ptIdx == member.ptIdx).ptName
                                            : member.name
                                            ? member.name
                                            : "-"
                                    }} -->
                                    {{
                                        member.name
                                            ? member.name
                                            : pointList.find((point) => point.ptIdx == member.ptIdx)
                                            ? pointList.find((point) => point.ptIdx == member.ptIdx).ptName
                                            : "-"
                                    }}
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 ml-2">
            <div class="row m-0">
                <div class="col-12 p-0 mb-2 d-flex justify-content-between align-items-center">
                    <div>
                        <label class="m-0">{{ refDataTitle2 }}</label>
                    </div>
                    <div class="btn-group">
                        <button class="btn btn-sm btn-warning" type="button" @click="handleMethodModal('secondVar')">
                            {{ "추가" }}
                        </button>
                        <button class="btn btn-sm btn-danger" type="button" @click="deleteReltnPoint">
                            {{ "삭제" }}
                        </button>
                        <!--   @click="showSideBar('factor')" -->
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="point-list-ctn">
                        <div
                            v-for="(factor, index) in factorPtList"
                            :key="factor.ptIdx"
                            class="form-check"
                            style="margin-bottom: 0.2rem; display: flex; align-items: center;"
                        >
                            <!-- v-model="type.checked" -->
                            <!-- @change="onEquipTypeChange(type.equipType, type.checked, type.equipTypeName)" -->
                            <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="checkReltnPoint[index]"
                                :id="factor.ptIdx"
                            />
                            <label class="form-check-label" :for="factor.ptIdx">
                                <span class="badge mr-1">{{ factor.srcType }}</span>
                                <span @click.stop="showChangePropertyName(factor)" style="cursor: pointer;">
                                    <!-- {{
                                        pointList.find((point) => point.ptIdx == factor.ptIdx)
                                            ? pointList.find((point) => point.ptIdx == factor.ptIdx).ptName
                                            : factor.name
                                            ? factor.name
                                            : "-"
                                    }} -->
                                    {{
                                        factor.name
                                            ? factor.name
                                            : pointList.find((point) => point.ptIdx == factor.ptIdx)
                                            ? pointList.find((point) => point.ptIdx == factor.ptIdx).ptName
                                            : "-"
                                    }}
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 custom-col mr-2">
            <div class="row align-items-center" style="margin: 0px 0px 10px 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="timeDsvn">{{ "데이터 유형 : " }}</label>
                </div>
                <div class="col-9 pr-0">
                    <select class="form-control" id="timeDsvn" v-model="detailInfo.timeDsvn">
                        <option :value="null">{{ "데이터 유형 선택" }}</option>
                        <option v-for="dateType in filterdTimeDsvn" :key="dateType.value" :value="dateType.value">
                            {{ dateType.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row align-items-center" style="margin: 0px 0px 10px 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="analysisDate">{{ `${calendarName[0]} : ` }}</label>
                </div>
                <calendar-component
                    start="true"
                    class="col-9 pr-0"
                    v-model="baseDayTime"
                    :calendarType="detailInfo.timeDsvn"
                    @input="getBaseDayTime"
                    placeholder="기본 기간 선택"
                    :detailInfo="detailInfo"
                    :rangeBooleanSuper="rangeBooleanlocal"
                />
            </div>
            <!--    :disabledCalendar="!isEditMode" -->
            <div class="row align-items-center" style="margin: 0px 0px 10px 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="compareType">{{ "비교 방법 : " }}</label>
                </div>
                <div class="col-9 pr-0">
                    <select
                        class="form-control"
                        id="compareType"
                        :class="{ 'disabled-select': !detailInfo.timeDsvn }"
                        v-model="detailInfo.compareType"
                        :disabled="
                            !detailInfo.timeDsvn ||
                                detailInfo.analysisMethod === 'Goal' ||
                                detailInfo.analysisMethod === 'MNV'
                        "
                    >
                        <option :value="null">{{ "비교 없음" }}</option>
                        <option v-for="comp in compareTypeArr" :key="comp.value" :value="comp.value">
                            {{ comp.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row align-items-center" style="margin: 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="comparePeriod">{{ `${calendarName[1]} : ` }}</label>
                </div>
                <!-- <date-picker class="col-9 pr-0" range valueType="YYYY-MM-DD" lang="ko"
                    placeholder="비교 기간 선택"></date-picker> -->
                <calendar-component
                    end="true"
                    class="col-9 pr-0"
                    v-model="compareDayTime"
                    :calendarType="detailInfo.timeDsvn"
                    :baseDayTime="baseDayTime"
                    :compareType="detailInfo.compareType"
                    @input="getCompareDayTime"
                    placeholder="비교 기간 선택"
                    :detailInfo="detailInfo"
                    :rangeBooleanSuper="rangeBooleanlocal"
                />
            </div>
        </div>

        <div class="settingButtonGroup">
            <div>
                <!-- 첫 번째 행 -->
                <div class="row">
                    <!-- 아이콘 -->
                    <div class="col-md-6">
                        <div class="icon-ctn" @click="toggleIconSelect">
                            <i :class="!isEmpty(detailInfo.icon) ? detailInfo.icon : 'fa fa-times'"></i>
                            <icon-select
                                v-if="isIconSelect"
                                :current="''"
                                :hideText="true"
                                :positionLeft="'-5rem'"
                                @on-change="onChangeIcon"
                            />
                        </div>
                    </div>
                    <!-- 분석 실행 -->
                    <div class="col-md-6">
                        <button
                            type="button"
                            class="analysisButton btn btn-primary btn-square"
                            @click="getAnalResultData"
                        >
                            <span>{{ "분석" }}</span>
                            <span v-if="detailInfo.analysisMethod === 'MNV'">{{ "준비" }}</span>
                            <span v-else>{{ "실행" }}</span>
                        </button>
                    </div>
                </div>
                <!-- 두 번째 행 -->
                <div class="row mt-2">
                    <!-- 보고서 저장 -->
                    <div class="col-md-6 ">
                        <button
                            type="button"
                            class="btn btn-sm btn-outline-secondary w-100 buttonHeight"
                            :disabled="isEmpty(resultData)"
                            @click="exportPDF"
                        >
                            <span>{{ "다운로드" }}</span>
                        </button>
                    </div>
                    <!-- 데이터 조회 -->
                    <!-- :disabled="isEmpty(detailInfo.modelIdx) || isEmpty(resultData)" -->
                    <div class="col-md-6">
                        <button
                            type="button"
                            class="btn btn-sm btn-outline-secondary w-100 buttonHeight"
                            v-if="detailInfo.analysisMethod == 'MNV' || detailInfo.analysisMethod == 'Goal'"
                            :disabled="
                                isEmpty(detailInfo.modelIdx) ||
                                    (detailInfo.analysisMethod !== 'MNV' && detailInfo.analysisMethod !== 'Goal') ||
                                    isEmpty(resultData)
                            "
                            @click="() => $refs['property-management-modal'].show()"
                        >
                            <span>{{ "변수 관리" }}</span>
                        </button>
                        <button
                            type="button"
                            class="btn btn-sm btn-outline-secondary w-100 buttonHeight"
                            v-else
                            :disabled="isEmpty(detailInfo.modelIdx) || isEmpty(resultData)"
                            @click="() => $refs['anaysisDataModal'].show()"
                        >
                            <span>{{ "데이터" }}</span>
                        </button>
                    </div>
                </div>

                <!-- 세 번째 행 -->
                <div class="row mt-2">
                    <!-- 설정 관리 -->
                    <div class="col-md-4">
                        <button
                            class="btn btn-sm btn-outline-secondary w-100"
                            type="button"
                            @click="() => $refs['setting-mgmt'].show()"
                        >
                            <span>{{ "설정" }}</span>
                        </button>
                    </div>
                    <!-- 노출 설정 -->
                    <div class="col-md-4">
                        <button
                            class="btn btn-sm btn-outline-secondary w-100"
                            type="button"
                            :disabled="isEmpty(detailInfo.modelIdx)"
                            @click="() => $refs['set-menu'].show()"
                        >
                            <span>{{ "노출" }}</span>
                        </button>
                    </div>
                    <!-- 기록 관리 -->
                    <div class="col-md-4">
                        <button
                            class="btn btn-sm btn-outline-secondary w-100"
                            type="button"
                            :disabled="isEmpty(detailInfo.modelIdx)"
                            @click="showAnalysisHistory"
                        >
                            <span>{{ "기록" }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- 타겟설정(관제점으로 선택, 사용자 지정, 분석목록에서 선택) 모달 -->
        <b-modal
            ref="modalTarget"
            centered
            size="lg"
            :title="methodModalTitle"
            id="modal-target"
            @hidden="closeTargetMethodModal"
        >
            <div class="targetContainer">
                <div class="methodList">
                    <ul class="methodListUl">
                        <li class="methodListLi" @click="handleSeveralTargetMethod('exist')">
                            {{ "분석 모델에서 찾기" }}
                            <i class="fas fa-chevron-right rightArrowI" />
                        </li>
                        <li
                            v-show="methodType !== 'target'"
                            class="methodListLi"
                            @click="handleSeveralTargetMethod('user')"
                        >
                            {{ "사용자 변수 추가" }}
                            <i class="fas fa-chevron-right rightArrowI" />
                        </li>
                        <li class="methodListLi" @click="handleSeveralTargetMethod('point')">
                            {{ "관제점목록에서 선택" }}
                        </li>
                    </ul>
                </div>
                <div class="detailMethod">
                    <div v-if="severalTargetMethod === 'exist'" class="variablesContainer">
                        <div class="withoutButton">
                            <div>
                                <div class="mb-1">
                                    {{ "분석 모델" }}
                                </div>
                                <b-form-select
                                    v-model="selectedExist"
                                    :options="[{ value: null, text: '분석모델을 선택하세요' }, ...modelOptions]"
                                    @change="fetchAnalysisModel"
                                ></b-form-select>
                            </div>
                            <div class="mt-3">
                                <b-table
                                    :items="items"
                                    :fields="fields"
                                    :head-variant="'light'"
                                    :bordered="true"
                                    responsive="sm"
                                    hover
                                    show-empty
                                    style="text-align: center;"
                                >
                                    <template #cell(checkbox)="row">
                                        <b-form-checkbox v-model="row.item.selected" />
                                    </template>
                                    <template #empty="scope">
                                        <span>{{ "표시할 데이터가 없습니다." }}</span>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                        <button type="button" class="btn btn-primary" @click="selectModelVariables">선택하기</button>
                    </div>
                    <div v-else-if="severalTargetMethod === 'user'" class="variablesContainer">
                        <div class="withoutButton">
                            <div class="userVariablesRow">
                                <div class="userVariablesTitle">{{ "변수명" }}</div>
                                <input v-model="addUserName" class="form-control" placeholder="변수명을 입력하세요" />
                            </div>
                            <div class="userVariablesRow">
                                <div class="userVariablesTitle">{{ "단위유형" }}</div>
                                <b-form-select
                                    v-model="addUserUnitType"
                                    :options="[{ value: '', text: '단위유형을 선택하세요' }, ...$store.state.unitTypes]"
                                    placeholder="단위유형을 선택하세요"
                                    @change="() => (this.addUserUnit = '')"
                                ></b-form-select>
                            </div>
                            <div class="userVariablesRow">
                                <div class="userVariablesTitle">{{ "단위" }}</div>
                                <b-form-select
                                    v-model="addUserUnit"
                                    :options="[{ value: '', text: '단위를 선택하세요' }, ...userVariableUnit]"
                                    placeholder="단위를 선택하세요"
                                ></b-form-select>
                            </div>
                        </div>
                        <button type="button" class="btn btn-primary" @click="addUserProperty">{{ "저장하기" }}</button>
                    </div>
                    <div v-else-if="severalTargetMethod === 'point'" class="ifPointSelectContainer">
                        <b-spinner
                            v-if="isSidebarVisible"
                            style="width: 3rem; height: 3rem;"
                            label="Large Spinner"
                        ></b-spinner>
                    </div>
                    <div v-else class="list-placeholder">
                        <span class="list-notification-icon">
                            <i class="fas fa-external-link-alt"></i>
                        </span>
                        <span class="list-notification-text">
                            {{ "왼쪽에서 변수 추가 방법을 선택해주세요." }}
                        </span>
                    </div>
                </div>
            </div>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button class="btn btn-secondary" style="flex: 1;" @click="closeTargetMethodModal">
                        {{ "닫기" }}
                    </button>
                </div>
            </template>
        </b-modal>
        <!-- 관제점 조회 사이드바 -->
        <b-sidebar
            v-model="isSidebarVisible"
            ref="PointSideBar"
            id="pointSideBar"
            bg-variant="white"
            backdrop-variant="secondary"
            width="45vw"
            @hidden="closeSidebar"
            no-header
            shadow
            right
            backdrop
            class="pointSearchSidebar"
        >
            <div style="padding: 1rem; height: 100%">
                <template v-if="currentSidebarType === 'target'">
                    <SearchComponent @close="closeSidebar" @select-point="setPointInfoData" :mode="'single'" />
                </template>

                <template v-else-if="currentSidebarType === 'firstVar'">
                    <SearchComponent
                        @close="closeSidebar"
                        @select-point="setPointInfoData"
                        :mode="'multi'"
                        :pointType="makeVarFormat(this.detailInfo.analysisMethod)[0]"
                    />
                </template>

                <template v-else-if="currentSidebarType === 'secondVar'">
                    <SearchComponent
                        @close="closeSidebar"
                        @select-point="setPointInfoData"
                        :mode="'multi'"
                        :pointType="makeVarFormat(this.detailInfo.analysisMethod)[1]"
                    />
                </template>
                <template v-else>
                    <div>잘못된 사이드바 타입입니다.</div>
                </template>
            </div>
        </b-sidebar>
        <!-- 설정 관리 모달 -->
        <b-modal ref="setting-mgmt" centered @hidden="closeSettingMgmtModal">
            <template #modal-header>
                <h5 class="m-0">{{ "설정 관리" }}</h5>
            </template>
            <SettingMgmtModalPanel ref="SettingMgmtModalPanel" :detailInfo="detailInfo" :analysisList="analysisList" />
            <template #modal-footer>
                <div style="display: flex; flex: 1">
                    <button
                        class="btn btn-primary"
                        type="button"
                        style="flex: 1; margin-right: 0.5rem"
                        @click="getSettingMgmt"
                    >
                        {{ "저장" }}
                    </button>
                    <button
                        class="btn btn-secondary"
                        type="button"
                        style="flex: 1; margin-left: 0.5rem"
                        @click="closeSettingMgmtModal"
                    >
                        {{ "취소" }}
                    </button>
                </div>
            </template>
        </b-modal>
        <!-- 노출 설정 모달 -->
        <b-modal ref="set-menu" centered @hidden="() => $refs['set-menu'].hide()">
            <template #modal-header>
                <h5 class="m-0">{{ "노출 설정" }}</h5>
            </template>
            <SetMenuModalPanel ref="SetMenuModalPanel" :detailInfo="detailInfo" :mode="mode" />
            <template #modal-footer>
                <div style="display: flex; flex: 1">
                    <button
                        class="btn btn-primary"
                        type="button"
                        style="flex: 1; margin-right: 0.5rem"
                        @click="saveDisplayMenu"
                    >
                        {{ "적용" }}
                    </button>
                    <button
                        class="btn btn-secondary"
                        type="button"
                        style="flex: 1; margin-left: 0.5rem"
                        @click="() => $refs['set-menu'].hide()"
                    >
                        {{ "닫기" }}
                    </button>
                </div>
            </template>
        </b-modal>
        <!-- 분석 기록 관리 모달 -->
        <b-modal ref="analysis-history" size="lg" centered @hidden="() => $refs['analysis-history'].hide()">
            <template #modal-header>
                <h5 class="m-0">{{ "분석 기록 관리" }}</h5>
            </template>
            <HistoryMgmtModalPanel
                :detailInfo="detailInfo"
                :resultData="resultData"
                @show-history="showAnalysisHistory"
            />
            <template #modal-footer>
                <div style="display: flex; flex: 1">
                    <button
                        class="btn btn-secondary"
                        type="button"
                        style="flex: 1"
                        @click="() => $refs['analysis-history'].hide()"
                    >
                        {{ "닫기" }}
                    </button>
                </div>
            </template>
        </b-modal>
        <!-- 분석 결과 데이터 보기 -->
        <!-- <b-modal
            ref="analysis-data"
            body-class="analysis-data-modal-body"
            size="xl"
            centered
            @hidden="() => $refs['analysis-data'].hide()"
        >
            <template #modal-header>
                <h5 class="m-0">{{ "데이터 보기" }}</h5>
            </template>
            <div style="height: 40rem; overflow-y: auto;">
                <div style="padding: 1rem;">
                    <AnalysisDataModalPanel :resultData="resultData" />
                </div>
            </div>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button
                        class="btn btn-secondary"
                        type="button"
                        style="flex: 1;"
                        @click="() => $refs['analysis-data'].hide()"
                    >
                        {{ "닫기" }}
                    </button>
                </div>
            </template>
        </b-modal> -->
        <!-- 변수 관리 모달 -->
        <b-modal
            id="property-management"
            body-class="property-management-modal-body"
            ref="property-management-modal"
            centered
            @hidden="() => $refs['property-management-modal'].hide()"
        >
            <template #modal-header>
                <h5 class="m-0">{{ "변수 관리" }}</h5>
            </template>
            <div style="width: 100%; padding: 1rem !important; height: 45rem;">
                <b-card no-body style="height: 100%;">
                    <b-tabs card style="height: 100%;" content-class="property-tabs-content">
                        <b-tab :title="detailInfo.analysisMethod == 'MNV' ? '종속 변수 관리' : '주 변수 관리'" active>
                            <div class="row m-0">
                                <!-- 주 변수 정보 테이블 -->
                                <div class="col-12 p-0 mb-4">
                                    <MainPropertyTablePanel :detailInfo="detailInfo" />
                                </div>
                                <!-- 데이터 보정 테이블 -->

                                <div v-for="(depend, key) in depends" :key="key" class="col-12 p-0 mb-4 ">
                                    <div class="splitLine"></div>
                                    <!-- Key를 보여주는 태그 -->
                                    <div class="row mb-2">
                                        <div class="col-12">
                                            <h5>{{ key }}</h5>
                                        </div>
                                    </div>

                                    <!-- AdjustDataTablePanel -->
                                    <div class="col-12 p-0">
                                        <AdjustDataTablePanel
                                            :adjustList="depend.dependAdjustList"
                                            :keyName="key"
                                            @add-adjust-list="(adjust) => handleAddDependAdjustList(key, adjust)"
                                            @add-adjust-column="(column) => handleAddAdjustColumn(key, column)"
                                        />
                                    </div>

                                    <!-- MainPropDataTablePanel -->
                                    <div class="col-12 p-0">
                                        <MainPropDataTablePanel
                                            ref="DependDataTablePanel"
                                            :detailInfo="detailInfo"
                                            :resultData="resultData"
                                            :columns="depend.dependColumns"
                                            :data="depend.dependResultData"
                                            :newColumns="depend.dependNewColumns"
                                            :keyName="key"
                                            @set-adjust-data="(data, type) => setDependAdjustData(key, data, type)"
                                            @import-excel-data="importExcelData"
                                        />
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab :title="detailInfo.analysisMethod == 'MNV' ? '독립 변수 관리' : '보조 변수 관리'">
                            <SubPropertyTablePanel :detailInfo="detailInfo" :resultData="resultData" />

                            <SubPropertyDataTablePanel
                                :detailInfo="detailInfo"
                                :resultData="resultData"
                                :data="independResultData"
                                :columns="indepentDataColumns"
                                @set-fix-data="setIndependAdjustData"
                                @import-independ-excel-data="importIndependData"
                            />
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button
                        class="btn btn-secondary"
                        style="flex: 1;"
                        @click="$refs['property-management-modal'].hide()"
                    >
                        {{ "닫기" }}
                    </button>
                </div>
            </template>
        </b-modal>
        <!-- 변수명 변경 -->
        <b-modal ref="change-prop-name-modal" centered>
            <template #modal-header>
                <h5 class="mb-0">{{ "변수명 변경" }}</h5>
            </template>
            <div v-if="propertyInfo" class="row m-0">
                <div class="col-12 p-0">
                    <label for="property-name">{{ "변수명" }}</label>
                    <input v-model="propertyInfo.name" class="form-control" id="property-name" type="text" />
                </div>
            </div>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button
                        class="btn btn-sm btn-primary"
                        style="flex: 1; margin-right: 0.5rem;"
                        @click="changePropertyName"
                    >
                        {{ "저장" }}
                    </button>
                    <button
                        class="btn btn-sm btn-secondary"
                        style="flex: 1; margin-left: 0.5rem;"
                        @click="hideChangePropertyName"
                    >
                        {{ "닫기" }}
                    </button>
                </div>
            </template>
        </b-modal>
        <!-- 데이터 테이블 모달 -->
        <b-modal ref="anaysisDataModal" size="xl" title="분석 데이터" hide-footer class="analysisDataTable" centered>
            <analysis-data-table :resultData="resultData" :detailInfo="detailInfo"></analysis-data-table>
        </b-modal>
    </div>
</template>

<script>
import SearchComponent from "@src/views/component/v2.1/PointSearch/Search.vue";
import SettingMgmtModalPanel from "./SettingMgmtModalPanel.vue";
import SetMenuModalPanel from "./SetMenuModalPanel.vue";
import HistoryMgmtModalPanel from "./HistoryMgmtModalPanel.vue";
// 데이터 보기 모달 패널 삭제 예정..?
// import AnalysisDataModalPanel from "./AnalysisDataModalPanel.vue";
import CalendarComponent from "@src/views/playground/CalendarComponent.vue";
import backEndApi from "@src/api/backEndApi";
// 변수 관리 주 변수 페널
import MainPropertyTablePanel from "./MainPropertyTablePanel.vue";
import AdjustDataTablePanel from "./AdjustDataTablePanel.vue";
import MainPropDataTablePanel from "./MainPropDataTablePanel.vue";
// 변수 관리 보조 변수 패널
import SubPropertyTablePanel from "./SubPropertyTablePanel.vue";
import SubPropertyDataTablePanel from "./SubPropertyDataTablePanel.vue";
//데이터 모달
import AnalysisDataTable from "../tables/AnalysisDataTable.vue";

import { mapState } from "vuex";

import moment from "moment";
import dayjs from "dayjs";

export default {
    props: ["detailInfo", "analysisList", "resultData"],
    components: {
        SearchComponent,
        SettingMgmtModalPanel,
        SetMenuModalPanel,
        HistoryMgmtModalPanel,
        // 데이터 보기 모달 패널 삭제 예정..?
        // AnalysisDataModalPanel,
        CalendarComponent,
        MainPropertyTablePanel,
        AdjustDataTablePanel,
        MainPropDataTablePanel,
        SubPropertyTablePanel,
        SubPropertyDataTablePanel,
        AnalysisDataTable,
    },
    data() {
        return {
            methodType: "",
            methodModal: false,
            methodModalTitle: "",
            severalTargetMethod: "",
            sideBarBtnType: null,
            detailModel: null,
            isLoadingFetchModel: false,
            baseDayTime: [],
            compareDayTime: [],
            rangeBooleanlocal: false,

            isSidebarVisible: false, // 현재 사이드바 표시 여부
            currentSidebarType: null,

            compareTypeArr: [],
            mode: "new",
            isIconSelect: false,

            checkCompPoint: [],
            checkReltnPoint: [],
            filterdTimeDsvn: this.timeDsvn,
            sideBarMode: "single",
            fixPointListSidebarOptions: false,
            targetModalState: null,

            //분석모델에서 찾기 테이블 임시 데이터입니다.
            fields: [
                { key: "checkbox", label: "", sortable: false },
                { key: "name", label: "변수명" },
                { key: "srcType", label: "유형" },
                { key: "unit", label: "단위" },
            ],
            items: [],
            selectedExist: null,
            addUserUnitType: "",
            addUserUnit: "",
            addUserName: "",
            // 주 변수 조정값
            adjustData: {},
            // 보조 변수 조정값
            fixIndependData: {},
            // 분석 대상 선택 버튼 활성화 여부
            disableTargetBtn: true,
            // 주변수 조정 내역
            dependAdjustList: [],
            // 주변수 조정 내역 데이터 테이블 컬럼 추가 변수
            dependNewColumns: [],

            dependColumns: [
                {
                    field: "regDt",
                    header: "일자",
                },
                {
                    field: "value",
                    header: "측정값",
                },
                {
                    field: "sumAdjust",
                    header: "조정 합계",
                },
                {
                    field: "sumValue",
                    header: "최종값",
                },
            ],

            dependResultData: null,
            independResultData: null,
            indepentDataColumns: [],

            propertyInfo: null,
            excludedKeys: ["regDt", "sumValue", "type", "value", "sumAdjust"],
            totalAdjustData: {
                baseline: {},
                report: {},
            },

            // depends:[{
            //     name:
            //     dependAdjustList:
            //     dependColumns:
            //     dependResultData:
            //     dependNewColumns:
            // }]
            // depends:{
            //     8층:{
            //         dependAdjustList:
            //         dependColumns:
            //         dependResultData:
            //         dependNewColumns:
            //     }
            // }
            depends: {},
        };
    },
    computed: {
        ...mapState({
            pointList: (state) => state.pointList,
            timeDsvnStore: (state) => state.commonCodes.timeDsvn,
            compareType: (state) => state.commonCodes.compareType,
        }),
        mainKey() {
            // Ensure resultData and the required properties exist
            if (!this.resultData || !this.resultData.variable || !this.resultData.variable.main) {
                return null;
            }

            // Ensure main array has at least one element
            if (this.resultData.variable.main.length === 0) {
                console.error("The main array in resultData.variable.main is empty");
                return null;
            }

            // Safely return the key
            return this.resultData.variable.main[0]?.key || null;
        },
        calendarName() {
            if (this.detailInfo.analysisMethod === "MNV") {
                return ["베이스라인 기간", "보고 기간"];
            } else {
                return ["분석 기간", "비교 기간"];
            }
        },
        modelOptions() {
            return this.analysisList.map((item) => {
                return { value: item.modelIdx, text: item.modelName };
            });
        },
        targetInfo() {
            let find = this.detailInfo.variables.find((item) => item.roleType == "Main");
            return !this.isEmpty(find) ? find : null;
        },

        mamberPtList() {
            return this.detailInfo.variables?.filter(
                (item) =>
                    item.roleType !== "Influence" &&
                    item.roleType !== "Main" &&
                    item.roleType !== "Predict" &&
                    item.roleType !== "Independ"
            );
        },
        factorPtList() {
            return this.detailInfo.variables?.filter(
                (item) => item.roleType == "Predict" || item.roleType == "Influence" || item.roleType == "Independ"
            );
        },
        isEditMode: {
            get() {
                return this.mode === "edit" || this.mode === "new";
            },
            set(value) {
                if (value) {
                    if (this.mode !== "new") this.mode = "edit";
                } else {
                    this.mode = "new"; // 잘못된 경우 기본값으로 "new" 설정
                }
            },
        },
        isNewMode() {
            return this.mode == "new";
        },
        refDataTitle() {
            let title = "";
            if (this.detailInfo.analysisMethod == "Trend") title = "참조 데이터";
            else if (this.detailInfo.analysisMethod == "Diff") title = "비교 대상";
            else if (this.detailInfo.analysisMethod == "Comp") title = "구성 요소";
            else if (this.detailInfo.analysisMethod == "Reltn") title = "참조 데이터";
            else if (this.detailInfo.analysisMethod == "Goal") title = "운영 목표";
            else if (this.detailInfo.analysisMethod == "MNV") title = "종속 변수";
            else title = "참조 데이터";
            return title;
        },
        refDataTitle2() {
            let title = "";
            if (this.detailInfo.analysisMethod == "MNV") title = "독립 변수";
            else if (this.detailInfo.analysisMethod == "Goal") title = "예측량";
            else title = "영향 인자";
            return title;
        },
        userVariableUnit() {
            let unit = [];
            if (!this.isEmpty(this.addUserUnitType)) unit = this.$store.state.unitCodes[this.addUserUnitType];
            else unit = [];
            return unit;
        },
    },
    watch: {
        baseDayTime() {
            this.initUserData();
        },
        compareDayTime() {
            this.initUserData();
        },
        detailInfo: {
            deep: true,
            handler() {
                this.initializeDependResultData(this.resultData); // 초기화
                // this.$nextTick(() => {
                //     this.initializeDependResultData(this.resultData); // 초기화
                // });
                this.adjustData = {};
                this.dependAdjustList = [];
                this.dependNewColumns = [];
                this.dependColumns = [
                    {
                        field: "regDt",
                        header: "일자",
                    },
                    {
                        field: "value",
                        header: "측정값",
                    },
                    {
                        field: "sumAdjust",
                        header: "조정 합계",
                    },
                    {
                        field: "sumValue",
                        header: "최종값",
                    },
                ];
            },
        },

        adjustData: {
            deep: true,
            handler(data) {
                this.$emit("updateAdjustData", data);
            },
        },
        fixIndependData: {
            deep: true,
            handler(data) {
                this.$emit("updateFixIndependData", data);
            },
        },
        "detailInfo.modelIdx": {
            deep: true,
            handler(modelIdx) {
                if (modelIdx) {
                    this.dependAdjustList = [];
                    this.dependNewColumns = [];
                    this.dependResultData = null;
                    this.independResultData = null;
                    this.indepentDataColumns = [];
                    this.dependColumns = [
                        {
                            field: "regDt",
                            header: "일자",
                        },
                        {
                            field: "value",
                            header: "측정값",
                        },
                        {
                            field: "sumAdjust",
                            header: "조정 합계",
                        },
                        {
                            field: "sumValue",
                            header: "최종값",
                        },
                    ];
                    this.setDayTime(this.detailInfo);
                }
            },
        },
        "detailInfo.timeDsvn": {
            deep: true,
            handler(type) {
                this.customCompareType(type);

                if (type === null) {
                    this.initDate();
                }

                // 사용자 정의 변수 데이터 초기화
                // if (type) this.userVariableData = null;
            },
        },
        "detailInfo.compareType": {
            deep: true,
            handler(compareType) {
                if (compareType === null) {
                    this.detailInfo.fromDateComp = null;
                    this.detailInfo.toDateComp = null;
                }
            },
        },
        "detailInfo.analysisMethod": {
            deep: true,
            handler(method) {
                if (method) {
                    this.switchVariables(method);
                }
                // 분석 대상 선택 버튼 활성화 여부
                if (method === "MNV") this.disableTargetBtn = true;
                else this.disableTargetBtn = false;

                if (method === "MNV") {
                    this.detailInfo.compareType = "Baseline";
                    this.filterdTimeDsvn = this.timeDsvnStore.filter((item) => {
                        return item.options.type === "Type2";
                    });
                } else if (method === "Goal") {
                    this.filterdTimeDsvn = this.timeDsvnStore.filter((item) => {
                        return ["DAYBYMONTH", "MONTHBYYEAR"].includes(item.value);
                    });
                } else {
                    this.filterdTimeDsvn = this.timeDsvnStore.filter((item) => {
                        return item.options.type === "Type1";
                    });
                }
            },
        },
        sideBarBtnType(newVal) {
            if (newVal === "member" && this.detailInfo.analysisMethod === "Goal") {
                this.fixPointListSidebarOptions = true;
            } else {
                this.fixPointListSidebarOptions = false;
            }
        },
        // baseDayTime(newVal) {
        //     if (newVal) this.userVariableData = null;
        // },
        resultData: {
            deep: true,
            handler(newVal) {
                if (!this.isEmpty(newVal)) {
                    if (this.detailInfo.analysisMethod !== "MNV") {
                        this.dependResultData = [];

                        const findMainHeader = newVal.headers.find((header) => header.roleType == "Main");
                        if (!this.isEmpty(findMainHeader) && !this.isEmpty(newVal.values.regDt)) {
                            newVal.values.regDt.forEach((reg, index) => {
                                this.dependResultData.push({
                                    idx: index,
                                    regDt: this.formatDateData(reg) ?? "-",
                                    value: newVal.values[findMainHeader.key][index] ?? 0,
                                    sumAdjust: 0,
                                    sumValue: 0,
                                });
                            });
                        }
                    } else {
                        this.initializeDepends(newVal);
                        this.initializeDependResultData(newVal); // 종속 변수 초기화 초기화
                        this.initializeindependResultData(newVal); // 독립 변수 초기화
                        this.applyDependAdjustments(newVal); // 종속변수에대한 조정 데이터 적용
                        this.applyIndependAdjustments(newVal); // 독립변수에대한 조정 데이터 적용
                    }
                }
            },
        },
        // newColumns 변경 시 동적으로 컬럼과 초기 데이터 추가
        // 각 항목별 dependNewColumns 변경 시 처리

        depends: {
            deep: true,
            handler(newDepends) {
                Object.keys(newDepends).forEach((key) => {
                    const depend = newDepends[key];
                    if (depend.dependNewColumns && Array.isArray(depend.dependNewColumns)) {
                        // "조정 합계" 컬럼의 인덱스를 찾음
                        const sumAdjustIndex = depend.dependColumns.findIndex((col) => col.field === "sumAdjust");
                        depend.dependNewColumns.forEach((columnName) => {
                            // 이미 존재하지 않는 경우에만 추가
                            if (!depend.dependColumns.find((col) => col.field === columnName)) {
                                // 새 컬럼을 "조정 합계" 컬럼 앞에 삽입
                                depend.dependColumns.splice(sumAdjustIndex, 0, {
                                    field: columnName,
                                    header: columnName,
                                });

                                // dependResultData.baseline과 dependResultData.report에 데이터 추가
                                ["baseline", "report"].forEach((dataset) => {
                                    if (depend.dependResultData?.[dataset]) {
                                        depend.dependResultData[dataset].forEach((row) => {
                                            this.$set(row, columnName, 0); // 초기값을 0으로 설정
                                        });
                                    }
                                });
                            }
                        });

                        // 테이블 강제 업데이트
                        // if (this.$refs["DependDataTablePanel"]) {
                        //     this.$refs["DependDataTablePanel"].tableVisibleControl();
                        // }
                        this.$refs["DependDataTablePanel"].forEach((panel, index) => {
                            if (panel && typeof panel.tableVisibleControl === "function") {
                                panel.tableVisibleControl();
                            } else {
                                console.warn(`Panel #${index} does not have tableVisibleControl.`);
                            }
                        });
                    }
                });
            },
        },
    },
    created() {},
    mounted() {
        this.customCompareType();
    },
    methods: {
        importIndependData(data, type) {
            this.independResultData[type]?.forEach((item, index) => {
                Object.keys(item).forEach((smallItem) => {
                    if (smallItem !== "regDt" && smallItem !== "type") {
                        item[smallItem] = data[index][smallItem];
                    }
                });
            });
        },

        // processExcelData(arr) {
        //     // let data=arr;
        // },
        importExcelData(data, type, name) {
            if (data && type && name) {
                const existingData = this.depends[name]?.dependResultData?.[type] || [];

                const updatedData = data.map((newItem, index) => {
                    const existingItem = existingData[index] || {};

                    const sumAdjust = Object.keys(newItem).reduce((acc, key) => {
                        if (!this.excludedKeys.includes(key) && typeof newItem[key] === "number") {
                            return acc + newItem[key];
                        }
                        return acc;
                    }, 0);

                    return {
                        ...existingItem,
                        ...newItem,
                        regDt: existingItem.regDt || newItem.regDt,
                        sumAdjust,
                        sumValue: sumAdjust + (newItem.value || 0),
                    };
                });

                this.$set(this.depends, name, {
                    ...this.depends[name],
                    dependResultData: {
                        ...this.depends[name].dependResultData,
                        [type]: updatedData,
                    },
                });

                this.$refs["DependDataTablePanel"].forEach((panel, index) => {
                    if (panel && typeof panel.tableVisibleControl === "function") {
                        panel.tableVisibleControl();
                    } else {
                        console.warn(`Panel #${index} does not have tableVisibleControl.`);
                    }
                });
            } else {
                console.log("import excel data in importExcelData func", data, type, name);
            }
        },
        emitTotalAdjustData(key, result, type) {
            this.totalAdjustData[type][key] = result;
            this.$emit("updateAdjustData", this.totalAdjustData);
        },
        calculateDurations({ timeDsvn, fromDate, toDate, fromDateComp, toDateComp }) {
            const baseStart = dayjs(fromDate);
            const baseEnd = dayjs(toDate);
            const compStart = dayjs(fromDateComp);
            const compEnd = dayjs(toDateComp);

            let baseDuration = 0;
            let compDuration = 0;
            let unit = "day";
            let unitDate = 1;

            switch (timeDsvn) {
                case "HOURBYDAY":
                    unit = "day";
                    unitDate = 24;
                    baseDuration = baseEnd.diff(baseStart, "day") + 1;
                    break;
                case "DAYBYMONTH":
                    unit = "month";
                    unitDate = 30;
                    baseDuration = baseEnd.diff(baseStart, "month") + 1;
                    break;
                case "MONTHBYYEAR":
                    unit = "year";
                    unitDate = 12;
                    baseDuration = baseEnd.diff(baseStart, "year") + 1;
                    break;
                default:
                    console.warn("Invalid timeDsvn value:", timeDsvn);
                    return null; // 유효하지 않은 경우 처리
            }

            compDuration = compEnd.diff(compStart, unit) + 1;

            return {
                baseDuration,
                compDuration,
                baseDateLength: baseDuration * unitDate,
                compDateLength: compDuration * unitDate,
            };
        },
        initUserData() {
            // const period = ["baseline", "report"];
            const userVarList = this.detailInfo.variables
                .filter((item) => item.srcType === "User")
                .map((item) => item.name);

            if (userVarList.length === 0) return;

            const { timeDsvn, fromDate, toDate, fromDateComp, toDateComp } = this.detailInfo;

            const durations = this.calculateDurations({ timeDsvn, fromDate, toDate, fromDateComp, toDateComp });

            if (!durations) return;

            const { baseDateLength, compDateLength } = durations;

            if (timeDsvn && fromDate && toDate) {
                userVarList.forEach((userVar) => {
                    this.$set(
                        this.detailInfo.userData.baseline,
                        userVar,
                        Array.from({ length: baseDateLength }, () => 0)
                    );
                });
            }

            if (timeDsvn && fromDateComp && toDateComp) {
                userVarList.forEach((userVar) => {
                    this.$set(
                        this.detailInfo.userData.report,
                        userVar,
                        Array.from({ length: compDateLength }, () => 0)
                    );
                });
            }
        },
        applyIndependAdjustments(newVal) {
            const independKeys = newVal.variable?.independ?.map((item) => item.key) || [];
            if (!independKeys.length) return;

            let adjustedFlag = false;
            // Check if adjustment data exists
            ["baseline", "report"].forEach((type) => {
                if (newVal.adjustData?.[type]) {
                    Object.keys(newVal.adjustData[type]).forEach((key) => {
                        if (independKeys.includes(key)) {
                            adjustedFlag = true;
                        }
                    });
                }
            });

            if (!adjustedFlag) return;

            const applyToDataset = (dataset, adjustData, adjustKey) => {
                if (!dataset || !adjustData?.[adjustKey]) return;

                dataset.forEach((item, index) => {
                    if (index < adjustData[adjustKey].length) {
                        item[adjustKey] = adjustData[adjustKey][index];
                    }
                });
            };

            independKeys.forEach((adjustKey) => {
                ["baseline", "report"].forEach((type) => {
                    if (this.independResultData?.[type]) {
                        applyToDataset(this.independResultData[type], newVal.adjustData[type], adjustKey);
                    }
                });
            });
        },
        initializeDepends(newVal) {
            // newVal.variable.main 배열을 순회하여 depends 객체를 초기화
            newVal.variable.main.forEach((mainVar) => {
                // depends 객체에 mainVar.key를 키로 하고 빈 객체 {}로 초기화
                if (!this.depends.hasOwnProperty(mainVar.key)) {
                    this.$set(this.depends, mainVar.key, {}); // Vue 2에서는 $set을 사용
                }
            });
        },
        initializeindependResultData(newVal) {
            // 독립 변수 데이터셋 정의
            this.indepentDataColumns = [];
            this.independResultData = {
                baseline: [],
                report: [],
            };
            this.indepentDataColumns.push({
                field: "regDt",
                header: "일자",
            });
            newVal.variable.independ.forEach((item) => {
                this.indepentDataColumns.push({
                    field: item.key,
                    header: item.key,
                });
            });
            const independKeys = newVal.variable.independ.map((item) => item.key);
            this.independResultData.baseline = newVal.data.baseline.regDt.map((reg, index) => {
                const entry = { regDt: reg, type: "baseline" };
                independKeys.forEach((independ) => {
                    entry[independ] = newVal.data.baseline[independ] ? newVal.data.baseline[independ][index] : 0;
                });
                return entry;
            });
            this.independResultData.report = newVal.data.report
                .map((dataset) => {
                    return dataset.regDt.map((date, index) => {
                        const entry = { regDt: date, type: "report" };
                        independKeys.forEach((key) => {
                            entry[key] = dataset[key] ? dataset[key][index] : 0;
                        });
                        return entry;
                    });
                })
                .flat();
        },
        initializeDependResultData(newVal) {
            if (!newVal || !newVal.variable || !newVal.variable.main) {
                console.error("Invalid newVal structure:", newVal);
                return;
            }

            newVal.variable.main.forEach((mainVar) => {
                if (!mainVar.key) {
                    console.error("Invalid mainVar.key:", mainVar);
                    return;
                }
                // 안전하게 depends 초기화
                if (!this.depends[mainVar.key]) {
                    this.$set(this.depends, mainVar.key, {
                        dependResultData: { baseline: [], report: [] },
                        // dependColumns: [...this.dependColumns],
                    });
                }

                // dependColums 초기화
                this.$set(this.depends[mainVar.key], "dependColumns", [...this.dependColumns]);
                // dependResultData 초기화
                let dependData = this.depends[mainVar.key].dependResultData;

                // dependResultData가 없으면 초기화
                if (!dependData) {
                    this.$set(this.depends[mainVar.key], "dependResultData", { baseline: [], report: [] });
                    dependData = this.depends[mainVar.key].dependResultData; // 새로 설정된 객체를 참조
                }

                // baseline과 report가 없으면 초기화
                if (!dependData.baseline) {
                    this.$set(dependData, "baseline", []);
                }
                if (!dependData.report) {
                    this.$set(dependData, "report", []);
                }

                // baseline과 report를 초기화
                dependData.baseline = [];
                dependData.report = [];

                // Baseline 데이터 추가
                if (newVal.data?.baseline?.regDt && Array.isArray(newVal.data.baseline.regDt)) {
                    newVal.data.baseline.regDt.forEach((reg, index) => {
                        dependData.baseline.push({
                            regDt: reg ?? "-",
                            value: newVal.data.baseline[mainVar.key]?.[index] ?? 0,
                            sumAdjust: 0,
                            sumValue: newVal.data.baseline[mainVar.key]?.[index] ?? 0,
                            type: "baseline",
                        });
                    });
                } else {
                    console.warn(`Baseline data missing or invalid for key: ${mainVar.key}`);
                }

                // Report 데이터 추가
                if (Array.isArray(newVal.data?.report)) {
                    newVal.data.report.forEach((item) => {
                        if (Array.isArray(item.regDt)) {
                            item.regDt.forEach((reg, index) => {
                                dependData.report.push({
                                    regDt: reg ?? "-",
                                    value: item[mainVar.key]?.[index] ?? 0,
                                    sumAdjust: 0,
                                    sumValue: item[mainVar.key]?.[index] ?? 0,
                                    type: "report",
                                });
                            });
                        } else {
                            console.warn(`Report regDt missing or invalid for key: ${mainVar.key}`);
                        }
                    });
                } else {
                    console.warn(`Report data missing or invalid for key: ${mainVar.key}`);
                }
            });
        },

        applyToDataset(dataset, adjustData, type, mainVarKey) {
            if (!dataset || !adjustData || !adjustData[mainVarKey]) {
                console.warn(`Skipping adjustments for ${mainVarKey} - invalid dataset or adjustData.`);
                return;
            }

            const adjustKeys = Object.keys(adjustData[mainVarKey]);

            adjustKeys.forEach((key) => {
                const depend = this.depends[mainVarKey];
                if (!depend.dependColumns.find((col) => col.field === key)) {
                    const sumAdjustIndex = depend.dependColumns.findIndex((col) => col.field === "sumAdjust");
                    depend.dependColumns.splice(sumAdjustIndex, 0, {
                        field: key,
                        header: key,
                    });

                    ["baseline", "report"].forEach((datasetType) => {
                        if (depend.dependResultData?.[datasetType]) {
                            depend.dependResultData[datasetType].forEach((row) => {
                                this.$set(row, key, 0);
                            });
                        }
                    });

                    if (this.$refs["DependDataTablePanel"]) {
                        this.$refs["DependDataTablePanel"].forEach((panel, index) => {
                            if (panel && typeof panel.tableVisibleControl === "function") {
                                panel.tableVisibleControl();
                            } else {
                                console.warn(`Panel #${index} does not have tableVisibleControl.`);
                            }
                        });
                    }
                }

                dataset.forEach((item, index) => {
                    const value = adjustData[mainVarKey][key][index] || 0;
                    this.$set(item, key, value);
                    item.sumAdjust += value;
                });
            });

            dataset.forEach((item) => {
                item.sumValue = item.value + item.sumAdjust;
            });

            this.$nextTick(() => {
                console.log("UI 업데이트 완료");
            });
        },
        applyDependAdjustments(newVal) {
            if (!newVal || !newVal.variable || !newVal.variable.main) {
                console.error("Invalid newVal structure:", newVal);
                return;
            }

            newVal.variable.main.forEach((mainVar) => {
                if (!mainVar.key || !this.depends[mainVar.key]) {
                    console.warn(`Skipping adjustments for invalid mainVar key: ${mainVar.key}`);
                    return;
                }

                const dependData = this.depends[mainVar.key]?.dependResultData;

                if (!dependData) {
                    console.error(`Depend data missing for key: ${mainVar.key}`);
                    return;
                }

                // baseline에 조정 적용
                this.applyToDataset(dependData.baseline, newVal.adjustData?.baseline, "baseline", mainVar.key);

                // report에 조정 적용
                this.applyToDataset(dependData.report, newVal.adjustData?.report, "report", mainVar.key);
            });
        },

        // Adjust 리스트 추가 함수
        handleAddDependAdjustList(key, adjust) {
            if (!this.depends[key]?.dependAdjustList) {
                this.$set(this.depends[key], "dependAdjustList", []);
            }
            this.depends[key].dependAdjustList.push({ ...adjust });
        },

        // Adjust 컬럼 추가 함수
        handleAddAdjustColumn(key, column) {
            if (!this.depends[key]?.dependNewColumns) {
                this.$set(this.depends[key], "dependNewColumns", []);
            }
            this.depends[key].dependNewColumns.push(column);
        },

        formatDateData(regDt) {
            let date;
            if (this.detailInfo.timeDsvn == "HOURBYDAY") {
                date = moment(regDt).format("YYYY-MM-DD HH:mm");
            } else if (this.detailInfo.timeDsvn == "DAYBYMONTH") {
                date = moment(regDt).format("YYYY-MM-DD");
            } else if (this.detailInfotimeDsvn == "MONTHBYYEAR") {
                date = moment(regDt).format("YYYY-MM");
            } else date = "-";
            return date;
        },
        initDate() {
            this.baseDayTime = null;
            this.compareDayTime = null;
            this.detailInfo.timeDsvn = null;
            this.detailInfo.compareType = null;
        },
        switchVariables(method) {
            if (method === "Trend") {
                this.detailInfo.variables = this.detailInfo.variables.filter(
                    (item) => item.roleType === "Influence" || item.roleType === "Main"
                );
            } else if (method === "Diff") {
                this.detailInfo.variables.forEach((item) => {
                    if (item.roleType !== "Influence" && item.roleType !== "Main") {
                        item.roleType = "Compare";
                    }
                });
            } else if (method === "Comp") {
                this.detailInfo.variables.forEach((item) => {
                    if (item.roleType !== "Influence" && item.roleType !== "Main") {
                        item.roleType = "Component";
                    }
                });
            } else if (method === "Reltn") {
                this.detailInfo.variables = this.detailInfo.variables.filter(
                    (item) => item.roleType === "Influence" || item.roleType === "Main"
                );
            } else if (method === "Goal") {
                this.detailInfo.variables = this.detailInfo.variables.filter(
                    (item) => item.roleType === "Goal" || item.roleType === "Main" || item.roleType === "Predict"
                );
            } else if (method === "MNV") {
                // this.detailInfo.variables = this.detailInfo.variables.filter(
                //     (item) => item.roleType === "Independ" || item.roleType === "Main"
                // );
                this.detailInfo.variables = this.detailInfo.variables.filter(
                    (item) => item.roleType === "Depend" || item.roleType === "Independ"
                );
            }
        },
        makeVarFormat(method) {
            if (method === "Trend") {
                return [null, "Influence"];
            } else if (method === "Diff") {
                return ["Compare", "Influence"];
            } else if (method === "Comp") {
                return ["Component", "Influence"];
            } else if (method === "Reltn") {
                return [null, "Influence"];
            } else if (method === "Goal") {
                return ["Goal", "Predict"];
            } else if (method === "MNV") {
                return ["Depend", "Independ"];
            }
        },
        async fetchAnalysisModel(modelIdx) {
            if (modelIdx == null) {
                this.items = [];
                return;
            }
            this.isLoadingFetchModel = true;
            try {
                let result = await backEndApi.analysisModel.getAnalysisModel(modelIdx);
                if (result.status == 200) {
                    this.detailModel = result.data;
                    this.items = [];
                    if (!this.isEmpty(this.detailModel.variables)) {
                        let filter;
                        if (this.methodType == "target") {
                            filter = this.detailModel.variables.filter((item) => item.roleType == "Main");
                        } else if (this.methodType == "firstVar") {
                            filter = this.detailModel.variables.filter(
                                (item) => item.roleType !== "Main" && item.roleType !== "Influence"
                            );
                        } else if (this.methodType == "secondVar") {
                            filter = this.detailModel.variables.filter((item) => item.roleType == "Influence");
                        } else filter = this.detailModel.variables;

                        if (!this.isEmpty(filter)) {
                            filter.forEach((value) => {
                                let findUnit = this.$store.state.units.find((type) => type.value == value.unit);
                                let findSrcType = this.$store.state.commonCodes.srcType.find(
                                    (type) => type.value == value.srcType
                                );
                                value.unit = findUnit ? findUnit.text : value.unit;
                                value.srcType = findSrcType ? findSrcType.text : value.srcType;
                                this.items.push(value);
                            });
                        } else this.item = [];
                    } else this.items = [];
                } else {
                    this.alertWarning(`${result.data.message}`);
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoadingFetchModel = false;
            }
        },

        handleMethodModal(type) {
            if (type !== "target" && this.isEmpty(this.detailInfo.analysisMethod)) {
                this.alertNoti("분석 방법을 선택하세요");
                return;
            }
            if (type === "target") {
                this.methodModalTitle = "분석 대상";
            } else if (type === "firstVar") {
                if (this.detailInfo.analysisMethod == "Trend") {
                    this.methodModalTitle = "참조 데이터";
                } else if (this.detailInfo.analysisMethod == "Diff") {
                    this.methodModalTitle = "비교 대상";
                } else if (this.detailInfo.analysisMethod == "Comp") {
                    this.methodModalTitle = "구성 요소";
                } else if (this.detailInfo.analysisMethod == "Reltn") {
                    this.methodModalTitle = "참조 데이터";
                } else if (this.detailInfo.analysisMethod == "Goal") {
                    this.methodModalTitle = "운영 목표";
                } else this.methodModalTitle = "참조 데이터";
            } else if (type === "secondVar") {
                if (this.detailInfo.analysisMethod == "Goal") this.methodModalTitle = "예측량";
                else this.methodModalTitle = "영향 인자";
            }
            this.methodType = type;
            this.$refs.modalTarget.show();
        },
        closeTargetMethodModal() {
            this.severalTargetMethod = "";
            // 분석 모델에서 찾기 v-model 변수 초기화
            this.clearSelectModelVariables();
            // 사용자 변수 추가 v-model 변수 초기화
            this.clearAddUser();
            this.$refs.modalTarget.hide();
        },
        clearSelectModelVariables() {
            this.selectedExist = null;
            this.items = [];
        },
        clearAddUser() {
            this.addUserName = "";
            this.addUserUnitType = "";
            this.addUserUnit = "";
        },
        handleSeveralTargetMethod(method) {
            this.severalTargetMethod = method;
            if (method === "point") {
                this.showSideBar(this.methodType);
            }
        },
        showSideBar(type) {
            this.currentSidebarType = type;
            this.sideBarMode = type === "target" ? "single" : "multi";
            this.isSidebarVisible = true;
        },
        closeSidebar() {
            this.isSidebarVisible = false;
            this.currentSidebarType = null;
        },

        customCompareType(type) {
            const compareTypeArr = this.compareType;
            const filterConditions = {
                YEAR: (value) => value === "User",
                MONTH: (value) => ["LastYear", "Avg3Year", "Avg5Year", "Avg10Year", "User"].includes(value),
                DAY: (value) =>
                    ["LastYear", "LastMonth", "LastWeek", "Avg3Year", "Avg5Year", "Avg10Year", "User"].includes(value),
                HOUR: (value) =>
                    [
                        "LastDay",
                        "LastYear",
                        "LastMonth",
                        "LastWeek",
                        "Avg3Day",
                        "Avg7Day",
                        "Avg30Day",
                        "Avg3Month",
                        "User",
                    ].includes(value),
                QUARTER: (value) =>
                    [
                        "LastDay",
                        "LastYear",
                        "LastMonth",
                        "LastWeek",
                        "Avg3Day",
                        "Avg7Day",
                        "Avg30Day",
                        "Avg3Month",
                        "User",
                    ].includes(value),
            };

            const customCompareTypeArr = compareTypeArr.filter((item) => {
                const condition = filterConditions[type];
                return condition ? condition(item.value) : true;
            });
            this.compareTypeArr = customCompareTypeArr;
        },

        exportPDF() {
            this.$emit("exportToPDF");
        },
        getAnalResultData() {
            this.$emit("result");
        },
        setPointInfoData(info) {
            if (this.currentSidebarType === "target") {
                this.addToTarget(info);
            } else if (this.currentSidebarType === "firstVar") {
                this.addToFirstVar(info);
            } else if (this.currentSidebarType === "secondVar") {
                this.addToSecondVar(info);
            }
            this.closeSidebar();
            this.closeTargetMethodModal();
        },
        addToTarget(info) {
            this.detailInfo.ptIdx = info.ptIdx;
            const targetObj = { name: info.ptName, ptIdx: info.ptIdx, roleType: "Main", srcType: "Point" };
            let hasMain = false;
            const updateVariables = this.detailInfo.variables.map((item) => {
                if (item.roleType == "Main") {
                    hasMain = true;
                    return { ...targetObj };
                }
                return item;
            });

            if (!hasMain) updateVariables.push(targetObj);

            this.detailInfo.variables = updateVariables;
        },
        addToFirstVar(info) {
            const newEntries = info.map((element) => {
                return {
                    name: element.ptName,
                    ptIdx: element.ptIdx,
                    roleType: this.makeVarFormat(this.detailInfo.analysisMethod)[0],
                    srcType: element.ptIdx ? "Point" : "User",
                };
            });

            const existingEntries = new Set(this.detailInfo.variables?.map((map) => `${map.ptIdx}-${map.roleType}`));
            const uniqueEntries = newEntries.filter(
                (entry) => !existingEntries.has(`${entry.ptIdx}-${entry.roleType}`)
            );

            this.detailInfo.variables = [...this.detailInfo?.variables, ...uniqueEntries];
        },
        // 버전2
        addToSecondVar(info) {
            const newEntries = info.map((element) => ({
                name: element.ptName,
                ptIdx: element.ptIdx,
                roleType: this.makeVarFormat(this.detailInfo.analysisMethod)[1],
                srcType: element.dataSourceCode === "Meas" ? "Point" : "User",
            }));

            const existingEntries = new Set(this.detailInfo.variables?.map((map) => `${map.ptIdx}-${map.roleType}`));
            const uniqueEntries = newEntries.filter(
                (entry) => !existingEntries.has(`${entry.ptIdx}-${entry.roleType}`)
            );

            this.detailInfo.variables = [...this.detailInfo?.variables, ...uniqueEntries];
        },
        setDayTime(detail) {
            console.log(
                "detail in setDayTime",
                [detail.fromDate, detail.toDate],
                [detail.fromDateComp, detail.toDateComp]
            );
            this.rangeBooleanlocal =
                (["MONTH", "DAY", "YEAR", "HOURBYDAY", "DAYBYMONTH", "MONTHBYYEAR"].includes(detail.timeDsvn) &&
                    detail.analysisMethod !== "Goal") ||
                detail?.analysisMethod === "MNV";

            if (detail.fromDate && detail.toDate && this.rangeBooleanlocal) {
                console.log("base range");

                this.baseDayTime = [detail.fromDate, detail.toDate];
            } else if (detail.fromDate && detail.toDate && !this.rangeBooleanlocal) {
                console.log("base single");
                this.baseDayTime = detail.fromDate;
            } else {
                this.baseDayTime = null;
            }
            if (detail.fromDateComp && detail.toDateComp && this.rangeBooleanlocal) {
                console.log("comp range");
                this.compareDayTime = [detail.fromDateComp, detail.toDateComp];
            } else if (detail.fromDateComp && detail.toDateComp && !this.rangeBooleanlocal) {
                console.log("comp single");
                this.compareDayTime = detail.fromDateComp;
            } else {
                this.compareDayTime = null;
            }
        },
        getBaseDayTime(baseDay) {
            if (!this.isEmpty(baseDay) && baseDay.length === 2) {
                this.detailInfo.fromDate = baseDay[0];
                this.detailInfo.toDate = baseDay[1];
            } else if (typeof baseDay === "string") {
                this.detailInfo.fromDate = baseDay;
                this.detailInfo.toDate = baseDay;
            } else return;
        },
        getCompareDayTime(compDay) {
            if (!this.isEmpty(compDay) && compDay.length === 2) {
                this.detailInfo.fromDateComp = compDay[0];
                this.detailInfo.toDateComp = compDay[1];
            } else if (typeof compDay === "string") {
                this.detailInfo.fromDateComp = compDay;
                this.detailInfo.toDateComp = compDay;
            } else return;
        },
        setEditMode() {
            this.mode = "edit";
        },
        setNewMode() {
            this.mode = "new";
        },
        toggleIconSelect() {
            this.isIconSelect = !this.isIconSelect;
        },
        onChangeIcon(value) {
            if (value) {
                this.detailInfo.icon = value.icon;
                this.toggleIconSelect();
            } else return;
        },
        async getSettingMgmt() {
            const setting = this.$refs["SettingMgmtModalPanel"].settingInfo;
            if (!this.isEmpty(setting)) {
                this.detailInfo.modelName = setting.modelName;
                this.detailInfo.category = setting.category;
                this.detailInfo.enabled = setting.enabled;
                this.detailInfo.description = setting.description;
                await this.$emit("save-modal");
                await this.$refs["setting-mgmt"].hide();
            }
        },
        closeSettingMgmtModal() {
            this.$refs["setting-mgmt"].hide();
        },
        async deleteComponentPoint() {
            if (this.checkCompPoint.length === 0 || this.checkCompPoint.every((item) => item === false)) {
                this.alertNoti("선택된 항목이 없습니다.");
                return;
            }
            const confirm = await this.alertConfirmWarning("선택한 항목을 삭제하시겠습니까?");
            if (!confirm.value) return;

            // 체크된 항목의 ptIdx를 저장
            const foundMemberPt = this.mamberPtList
                .filter((_, index) => this.checkCompPoint[index]) // 체크된 항목만 필터링
                .map((member) => member.ptIdx); // ptIdx만 추출
            // detailInfo.ptMaps에서 foundMemberPt에 포함되지 않은 항목만 남김
            this.detailInfo.variables = this.detailInfo.variables.filter(
                (ptMap) => !foundMemberPt.includes(ptMap.ptIdx)
            );
            // checkCompPoint 배열 초기화
            this.checkCompPoint = [];
        },
        async deleteReltnPoint() {
            if (this.checkReltnPoint.length === 0 || this.checkReltnPoint.every((item) => item === false)) {
                this.alertNoti("선택한 항목이 없습니다.");
                return;
            }

            const confirm = await this.alertConfirmWarning("선택한 항목을 삭제하시겠습니까?");
            if (!confirm.value) return;

            const foundFactorPt = this.factorPtList
                .filter((_, index) => this.checkReltnPoint[index])
                .map((factor) => factor.ptIdx);
            this.detailInfo.variables = this.detailInfo.variables.filter(
                (ptMap) => !foundFactorPt.includes(ptMap.ptIdx)
            );
            this.checkReltnPoint = [];
        },
        showAnalysisHistory() {
            this.$refs["analysis-history"].show();
        },
        showSettingMgmtModal() {
            this.$refs["setting-mgmt"].show();
        },
        // 노출 설정 모달에 분석 정보 저장 API 적용 by woonsik
        async saveDisplayMenu() {
            const publish = this.$refs.SetMenuModalPanel.settingPublish;
            if (!this.isEmpty(publish)) {
                this.detailInfo.publish = publish;
                let result = await this.$emit("save-modal");
                if (result) await this.$refs["set-menu"].hide();
            }
        },
        async selectModelVariables() {
            const select = this.items.filter((item) => item.selected);
            if (this.methodType == "target") this.detailInfo.ptIdx = select[0].ptIdx;
            select.forEach((item) => {
                let roleType;
                if (item.roleType !== "Main") {
                    if (this.methodType == "firstVar") roleType = this.makeVarFormat(this.detailInfo.analysisMethod)[0];
                    else if (this.methodType == "secondVar")
                        roleType = this.makeVarFormat(this.detailInfo.analysisMethod)[1];
                    else roleType = item.roleType;
                } else roleType = item.roleType;
                this.detailInfo.variables.push({
                    name: item.name ? item.name : null,
                    ptIdx: item.ptIdx ? item.ptIdx : null,
                    roleType: roleType,
                    srcType: "Point",
                });
            });
            await this.closeTargetMethodModal();
        },
        changeUnitType() {
            if (this.isEmpty(this.addUserUnitType)) this.addUserUnit = "";
        },
        addUserData(name) {
            const { timeDsvn, fromDate, toDate, fromDateComp, toDateComp } = this.detailInfo;

            if (timeDsvn && fromDate && toDate && fromDateComp && toDateComp) {
                const durations = this.calculateDurations({ timeDsvn, fromDate, toDate, fromDateComp, toDateComp });

                if (!durations) return;

                const { baseDateLength, compDateLength } = durations;

                this.detailInfo.userData.baseline[name] = Array.from({ length: baseDateLength }, () => 0);
                this.detailInfo.userData.report[name] = Array.from({ length: compDateLength }, () => 0);
            } else {
                console.warn("날짜가 아직 입력되지 않았습니다.");
            }
        },
        async addUserProperty() {
            let roleType;
            if (this.methodType == "firstVar") roleType = this.makeVarFormat(this.detailInfo.analysisMethod)[0];
            else if (this.methodType == "secondVar") roleType = this.makeVarFormat(this.detailInfo.analysisMethod)[1];
            this.detailInfo.variables.push({
                name: this.addUserName,
                ptIdx: null,
                roleType: roleType,
                srcType: "User",
                unit: this.addUserUnit,
                unitType: this.addUserUnitType,
            });
            this.addUserData(this.addUserName);
            await this.closeTargetMethodModal();
        },
        showPropertyManagementModal() {
            this.$refs["property-management-modal"].show();
        },
        // setUserVariabledData(data) {
        //     this.userVariableData = data;
        // },
        showChangePropertyName(data) {
            this.propertyInfo = { ...data };
            this.$nextTick(async () => {
                await this.$refs["change-prop-name-modal"].show();
            });
        },
        async hideChangePropertyName() {
            this.propertyInfo = null;
            await this.$refs["change-prop-name-modal"].hide();
        },
        async changePropertyName() {
            const find = this.detailInfo.variables.find((item) => item.ptIdx == this.propertyInfo.ptIdx);
            if (!this.isEmpty(find)) find.name = this.propertyInfo.name;
            await this.hideChangePropertyName();
        },
        // Adjust 데이터 설정 함수
        setDependAdjustData(key, data, type) {
            if (!this.depends[key]?.dependAdjustList) {
                this.$set(this.depends[key], "dependAdjustList", []);
            }

            try {
                if (!this.depends[key]?.adjustData) {
                    this.$set(this.depends[key], "adjustData", {});
                }

                if (!this.depends[key].adjustData[type]) {
                    this.$set(this.depends[key].adjustData, type, {});
                }

                const result = data.reduce((acc, item) => {
                    Object.keys(item).forEach((key) => {
                        if (!["regDt", "sumValue", "type", "value", "sumAdjust"].includes(key)) {
                            if (!acc[key]) {
                                acc[key] = [];
                            }
                            acc[key].push(item[key]);
                        }
                    });
                    return acc;
                }, {});

                this.$set(this.depends[key].adjustData, type, result);
                this.emitTotalAdjustData(key, result, type);
            } catch (error) {
                console.error(`Error in setDependAdjustData for key "${key}" and type "${type}"`, error);
                this.$set(this.depends[key].adjustData, type, {});
            }
        },
        setIndependAdjustData(data, type) {
            const result = data.reduce((acc, item) => {
                Object.keys(item).forEach((key) => {
                    if (!this.excludedKeys.includes(key)) {
                        if (!acc[key]) {
                            acc[key] = [];
                        }
                        acc[key].push(item[key]);
                    }
                });
                return acc;
            }, {});
            if (!this.isEmpty(data)) {
                try {
                    // if (!this.fixIndependData[type]) {
                    //     this.$set(this.fixIndependData, type, {});
                    // }
                    const tempData = { ...this.adjustData[type], ...result };
                    // this.$set(
                    //     this.fixIndependData[type],
                    //     field,
                    //     data.map((item) => Number(item[field]) || 0)
                    // );
                    this.$set(this.adjustData, type, tempData);
                } catch (e) {
                    console.error("Error in setDependAdjustData:", e);
                    // Optionally clear only the specific type instead of resetting the entire object
                    this.$set(this.adjustData, type, {});
                }
            }
        },
    },
};
</script>

<style scoped>
.splitLine {
    width: 100%;
    height: 1px;
    background-color: #eeeeee;
    margin: 0 auto;
    margin-bottom: 20px;
}
.ifPointSelectContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.userVariablesTitle {
    margin-bottom: 7px;
}
.userVariablesRow {
    margin: 10px 0;
}
.variablesContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.pointSearchSidebar {
    z-index: 1041;
}
.rightArrowI {
    color: #bdbdbd;
    font-size: 10px;
}
.methodListUl {
    list-style: none;
    margin: 0;
    padding: 0;
}
.methodListLi {
    pointer-events: auto;
    height: 2.2rem;
    font-size: 14px;
    font-weight: bold;
    border-radius: 8px;
    margin: 3px 7px;
    padding: 0 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.methodListLi:hover {
    background-color: #f5f5f5;
}
.targetContainer {
    width: 100%;
    height: 40vh;
    display: flex;
}
.methodList {
    width: 35%;
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.detailMethod {
    width: 65%;
    height: 100%;
    padding: 0.5rem 1rem;
}
.custom-col {
    max-width: 22%; /* 너비를 줄임 */
    flex: 0 0 22%; /* Bootstrap flex-basis를 수정 */
}
.analysisButton {
    display: flex;
    flex-direction: column;
}
.buttonStyle {
    font-size: 0.7rem;
}
.iconTag {
    font-size: 15px;
}

.saveButton {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.nomalButton {
    height: 100%;
    width: 100%;
}

.container {
    height: 100%;
    padding: 0 !important;
    margin: 0 !important;
}
.row {
    margin: 1px 0px !important;
    padding: 0 !important;
}
.col-md-4,
.col-md-6,
.col-lg-2,
.col-lg-3 {
    padding: 2px !important;
}

.settingButtonGroup {
    padding: 0 !important;
}
.point-list-ctn {
    width: 100%;
    height: 130px;
    overflow-y: auto;
    border: 1px solid #ececec;
    border-radius: 5px;
    padding: 0.5rem;
    background-color: white;
}
.disabled-select {
    cursor: not-allowed;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #f3f3f3;
    border-color: #ccc;
}
/* 여기부터 */
.saveButton .button-group-vertical {
    display: flex;
    flex-direction: column;
    gap: 0.3rem; /* 버튼 간격 */
}

.saveButton .btn {
    font-size: 0.9rem; /* 버튼 텍스트 크기 조정 */
    padding: 0.5rem 1rem; /* 버튼 안쪽 여백 */
    text-align: center;
}
.icon-ctn {
    width: 5rem;
    height: 5rem;
    background-color: #eceff1;
    cursor: pointer;
    font-size: 2rem;
    color: #455a64;
    border-radius: 5px;
    border: 1px solid #cfd8dc;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
}

.icon-ctn:hover {
    color: #eceff1;
    background-color: #455a64;
}

.btn-square {
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-align: center;
    border-radius: 5px;
}

.list-placeholder {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.list-notification-icon {
    padding: 1rem;
    color: #444;
    font-size: 30px;
}
.buttonHeight {
    height: 45px;
}
.list-notification-text {
    font-size: 13px;
    font-weight: bold;
}
/* 여기까지 */
/* .icon-ctn {
    width: 100%;
    height: 100%;
    background-color: #eceff1;
    cursor: pointer;
    font-size: 2rem;
    color: #455a64;
    border-radius: 5px;
    border-left: solid 1px #cfd8dc;
    border-top: solid 1px #cfd8dc;
    border-right: solid 1px #90a4ae;
    border-bottom: solid 1px #90a4ae;
    transition: background-color 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
} */
/* .icon-ctn:hover {
    color: #eceff1;
    background-color: #455a64;
} */
</style>
