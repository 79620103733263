import { render, staticRenderFns } from "./LightAnalysisCards.vue?vue&type=template&id=04e5fdca&scoped=true&"
import script from "./LightAnalysisCards.vue?vue&type=script&lang=js&"
export * from "./LightAnalysisCards.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04e5fdca",
  null
  
)

export default component.exports