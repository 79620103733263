<template>
    <div>
        <!-- 스피너 오버레이 -->
        <div v-if="isExcelLoading" class="spinner-overlay">
            <b-spinner class="align-middle"> </b-spinner>
        </div>

        <!-- 데이터 테이블 -->
        <div v-if="data" class="row m-0">
            <div v-if="Array.isArray(data)" style="width: 100%;">
                <MnvDataTable
                    v-if="tableVisible"
                    ref="data-table-list"
                    :columns="columns"
                    :rows="data"
                    :title="'주 변수 데이터'"
                    :keyField="'regDt'"
                    :mode="'edit'"
                    :newColumns="newColumns"
                    :controls="controls"
                    :scrollable="true"
                    :scrollHeight="'300px'"
                    :timeDsvn="detailInfo.timeDsvn"
                    @button-click="handleListButtonClick"
                    @import-data="importData"
                    @setEditTableData="setEditTableData"
                />
            </div>

            <div v-else style="width: 100%;">
                <div v-for="key in Object.keys(data)" :key="`${key}-data-table`" style="width: 100%;">
                    <MnvDataTable
                        v-if="tableVisible"
                        :ref="`${key}-data-table`"
                        :columns="columns"
                        :rows="data[key]"
                        :title="key == 'baseline' ? '종속 변수 관리 (베이스라인)' : '종속 변수 관리 (보고 기간)'"
                        :keyField="'regDt'"
                        :mode="'edit'"
                        :newColumns="newColumns"
                        :controls="controls"
                        :scrollable="true"
                        :scrollHeight="'300px'"
                        :type="key"
                        :timeDsvn="detailInfo.timeDsvn"
                        @button-click="handleListButtonClick"
                        @import-data="importData"
                        @setEditTableData="setEditTableData"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MnvDataTable from "../tables/MnvDataTable.vue";
import * as XLSX from "xlsx";
// import moment from "moment";

export default {
    props: ["detailInfo", "resultData", "columns", "data", "newColumns", "keyName"],
    components: {
        MnvDataTable,
    },
    data() {
        return {
            controls: [
                { event: "export", label: "내보내기", class: "btn-primary" },
                { event: "import", label: "불러오기", class: "btn-danger" },
            ],
            tableVisible: true,
            mainPropResultData: [],
            isExcelLoading: false,
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        console.log("this.data", this.data);
    },
    methods: {
        tableVisibleControl() {
            this.tableVisible = false;
            this.$nextTick(() => {
                this.tableVisible = true;
            });
        },
        async handleListButtonClick(event, options) {
            switch (event) {
                case "export":
                    await this.exportData();
                    break;
                case "import":
                    this.importData();
                    break;
                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
        async exportData() {
            console.log("exportData");
            console.log(this.$refs);
            this.$refs["data-table-list"]?.excelExport();
            this.alertNoti("다운로드가 시작됩니다.");
        },
        importData(event, type) {
            this.isExcelLoading = true;
            console.log("importData", event);
            const file = event.target.files[0];
            if (!file) return;

            const reader = new FileReader();
            reader.onload = async (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: "array" });

                // 첫 번째 시트를 가져옴
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                // 시트 데이터를 JSON
                const jsonData = XLSX.utils.sheet_to_json(sheet);
                console.log("json Data", jsonData);
                console.log("columns", this.columns);
                const jsonDataKeyFormat = jsonData.map((item) => {
                    let dynamicKeys = {};
                    for (let key in item) {
                        console.log("key in loop", key);
                        const findField = this.columns.find((col) => col.header == key);
                        console.log("columns in jsonDataKeyFormat func", findField);
                        const value = item[key];
                        dynamicKeys[findField.field] = value;
                    }
                    return {
                        ...dynamicKeys,
                    };
                });

                console.log("jsonDataKeyFormat", jsonDataKeyFormat);
                console.log("Before mainPropResultData", this.mainPropResultData);
                this.mainPropResultData.splice(0, this.mainPropResultData.length, ...jsonDataKeyFormat);
                console.log("After mainPropResultData", this.mainPropResultData);
                this.$emit("set-adjust-data", this.mainPropResultData, type);
                this.$emit("import-excel-data", this.mainPropResultData, type, this.keyName);
                this.isExcelLoading = false;
            };
            reader.readAsArrayBuffer(file);

            event.target.value = null;
        },

        setEditTableData(data, type) {
            console.log("setEditTableData", data, type);
            this.$emit("set-adjust-data", data, type);
        },

        convertExcelDateToISO(serial) {
            let jsDate;
            if (this.detailInfo.timeDsvn == "DAYBYMONTH" || this.detailInfo.timeDsvn == "HOURBYDAY") {
                // Excel 날짜 기준: 1900년 1월 1일
                let excelStartDate = new Date(1900, 0, 1);
                // Excel 날짜는 1일부터 시작하고 1900년 2월 29일(가상의 날짜)로 인해 1일을 추가로 빼줍니다.
                let date = new Date(excelStartDate.getTime() + (serial - 2) * 24 * 60 * 60 * 1000);
                // ISO 날짜 형식 (YYYY-MM-DD)
                jsDate = date.toISOString().split("T")[0];
            } else {
                jsDate = serial;
            }
            return jsDate;
        },
    },
};
</script>
<style scoped>
.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.1);
    z-index: 9999;
    transition: background-color 0.9s ease;
}
</style>
