<template>
    <div class="markdownContainerR2">
        <markdown-it-vue :content="resultFormula" class="markdownTag"></markdown-it-vue>
    </div>
</template>

<script>
import MarkdownItVue from "markdown-it-vue";
import "markdown-it-vue/dist/markdown-it-vue.css";

export default {
    props: ["R2AdjR2"],
    components: {
        MarkdownItVue,
    },
    data() {
        return {
            resultFormula: "",
        };
    },
    watch: {
        R2AdjR2: {
            immediate: true,
            handler() {
                this.updateResultFormula();
            },
        },
    },
    methods: {
        wrapWithLaTeX([R2, adjR2]) {
            return `
$$
\\begin{aligned}
\\footnotesize
\\mathbf{
\\begin{pmatrix}
R^2 & : ${R2} \\\\ 
R^2_{\\text{adj}} & : ${adjR2} \\\\ 
\\end{pmatrix}}
\\end{aligned}
$$ `;
        },

        updateResultFormula() {
            if (this.R2AdjR2 && Array.isArray(this.R2AdjR2) && this.R2AdjR2.length === 2) {
                this.resultFormula = this.wrapWithLaTeX(this.R2AdjR2);
            }
        },
    },
};
</script>

<style scoped>
.markdownContainerR2 {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: flex-end;
    width: 100%;
}
.markdownTag {
    width: 50%;
}
</style>
