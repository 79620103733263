<template>
    <div class="container">
        <div ref="barChart" class="barChart"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";

export default {
    props: ["resultData", "detailInfo"],
    data() {
        return {
            chart: null,
        };
    },
    created() {},
    mounted() {
        this.renderChart();
        window.addEventListener("resize", this.resizeChart);
    },
    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }
        window.removeEventListener("resize", this.resizeChart);
    },
    methods: {
        renderChart() {
            this.chart = echarts.init(this.$refs.barChart);

            const option = {
                grid: {
                    left: "10%",
                    right: "15%",
                    top: "10%",
                    bottom: "25%",
                    containLabel: true,
                },
                tooltip: {
                    formatter: (params) => {
                        return `<div style="font-size: 12px;  width: 140px;display: flex; justify-content: space-between;">
                                    <div>
                                    ${params.marker}
                                    <span> ${params.data.label}</span>
                                     </div>
                                     <div>
                                    <span style="font-weight: bold;"> ${params.data.value}&nbsp;${this.detailInfo.dispUnit}</span>
                                    </div>
                                </div> `;
                    },
                },
                xAxis: {
                    type: "category",
                    data: this.propList,
                    axisLabel: {
                        show: false,
                        rotate: 90,
                        fontSize: 12,
                    },
                },
                yAxis: {
                    type: "value",
                    axisLabel: {
                        fontSize: 12,
                        rotate: 90,
                    },
                },
                series: [
                    {
                        type: "bar",
                        data: this.resultData.value.map((item) => ({
                            value: item[1],
                            label: this.resultData.xSeries[item[0]],
                        })),
                        barWidth: "100%",
                        colorBy: "data",
                        label: {
                            show: true,
                            position: "insideBottom",
                            formatter: (params) => {
                                return `{labelStyle|${params.data.label}} {valueStyle|${params.data.value}}`;
                            },
                            emphasis: {
                                focus: "self",
                            },
                            fontSize: 12,

                            rotate: 90,
                            offset: [45, 6],
                            rich: {
                                labelStyle: {
                                    fontSize: 12,
                                    color: "white",
                                    fontWeight: "bold",
                                },
                                valueStyle: {
                                    fontSize: 10,
                                    color: "white",
                                },
                            },
                        },
                    },
                ],
            };

            this.chart.setOption(option);
        },
        resizeChart() {
            if (this.chart) {
                this.chart.resize();
            }
        },
    },
};
</script>

<style scoped>
.container {
    width: 100%;
}
.barChart {
    width: 100%;
    height: 400px;
}
</style>
