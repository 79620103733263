<template>
    <div class="ld-container" style="height: 90vh; width: 100%">
        <div class="row" style="height: 100%">
            <!-- Sidebar -->
            <b-sidebar
                id="sidebar"
                :visible="!expand"
                :backdrop="true"
                bg-variant="light"
                width="33%"
                shadow
                @hidden="expand = true"
                @shown="expand = false"
                class="list-area"
            >
                <div class="sidebarContainer">
                    <div class="row ">
                        <div class="sidebarHeader">
                            <HeaderBox
                                sideMargin="5"
                                :title="`${$t('데이터 분석')} > ${$t('에너지 데이터 분석')}   `"
                            />
                        </div>
                    </div>
                    <div class="sidebarBody">
                        <div v-if="!isEmpty(analysisList)">
                            <TableList
                                ref="TableList"
                                :title="'에너지 데이터 분석 목록'"
                                :columns="columns"
                                :rows="analysisList"
                                :keyField="'modelIdx'"
                                :groupField="'category'"
                                :toggleFields="['enabled']"
                                :transCodes="transCodes"
                                :controls="controls"
                                :mode="'edit'"
                                :useMaxHeight="true"
                                :fixedHeader="false"
                                @button-click="handleListButtonClick"
                                @row-click="handleItemClick"
                                @toggle-changed="handleItemToggle"
                            />
                        </div>
                        <div v-else class="list-placeholder">
                            <span class="list-notification-icon">
                                <i class="fas fa-external-link-alt"></i>
                            </span>
                            <span class="list-notification-text">
                                {{ "등록된 분석 정보가 없습니다." }}
                            </span>
                            <span class="list-notification-text">
                                {{ "분석 정보를 추가하세요." }}
                            </span>
                        </div>
                    </div>
                </div>
            </b-sidebar>

            <!-- Detail Area -->
            <div class="detail-area col-12">
                <AnalysisEditor
                    ref="AnalysisEditor"
                    :analysisList="analysisList"
                    @expand="setExpand"
                    @refresh="getAnalysisModelList"
                />
            </div>
        </div>
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import HeaderBox from "@views/component/headerBox/analysis/Header.vue";
import TableList from "@views/component/v2.1/ListDetailView/TableList.vue";
import AnalysisEditor from "./AnalysisEditor.vue";

export default {
    props: [],
    components: {
        HeaderBox,
        TableList,
        AnalysisEditor,
    },
    data() {
        return {
            analysisList: [],
            expand: true,
            controls: [
                { event: "delete", label: "삭제", class: "btn-danger" },
                { event: "status", label: "사용", class: "bg-white text-dark", options: { enabled: "Y" } },
                { event: "status", label: "미사용", class: "bg-white text-dark", options: { enabled: "N" } },
                { event: "regist", label: "추가", class: "btn-primary" },
            ],
            transCodes: [
                {
                    field: "enabled",
                    codes: this.$store.state.commonCodes.enabled,
                },
                {
                    field: "analysisMethod",
                    codes: this.$store.state.commonCodes.analysisMethod,
                },
            ],
            columns: [
                {
                    label: this.$t("데이터 분석명"),
                    field: "rowTitle",
                    type: "text",
                    compositionFields: ["modelName", "modelIdx"],
                },
                {
                    label: this.$t("카테고리"),
                    field: "category",
                    type: "text",
                },
                // {
                //     label: this.$t("분석 대상"),
                //     field: "targetPtInfo",
                //     type: "text",
                //     compositionFields: ["ptName", "ptIdx"],
                // },
                {
                    label: this.$t("분석 유형"),
                    field: "analysisMethod",
                    type: "text",
                },
                {
                    label: this.$t("사용 여부"),
                    field: "enabled",
                    type: "text",
                },
            ],
        };
    },
    computed: {
        // 필요한 computed 속성 추가 가능
    },
    watch: {
        // 추가적인 watch 로직 삽입 가능
    },
    async created() {
        await this.getAnalysisModelList();
        // 뷰모드에서 설정 페이지로 이동 할 경우 modelIdx 를 쿼리로 받아서 데이터 로드
        if (!this.isEmpty(this.$route.params.modelIdx)) {
            console.log("in to view page");
            //  await this.$refs["AnalysisEditor"].viewModeSetting();
            await this.$refs["AnalysisEditor"].getAnalysisModel(this.$route.params.modelIdx);
        }
    },
    mounted() {
        // 필요한 초기 설정 로직 삽입 가능
    },
    methods: {
        async getAnalysisModelList() {
            try {
                let result = await backEndApi.analysisModel.getAnalysisModelList();
                if (result.status == 200) {
                    this.analysisList = result.data;
                    this.analysisList.forEach((anal) => {
                        let find = this.$store.state.pointList.find((point) => point.ptIdx == anal.ptIdx);
                        if (find) anal.ptName = find.ptName;
                    });
                } else {
                    this.alertWarning(`${result.data.message}`);
                }
            } catch (e) {
                console.error(e);
            }
            return this.analysisList;
        },
        setExpand() {
            this.expand = !this.expand;
        },
        async handleListButtonClick(event, options) {
            switch (event) {
                case "regist":
                    await this.registNew();
                    break;

                case "delete":
                    await this.deleteChecked();
                    break;

                case "status":
                    await this.changeStatusChecked(options);
                    break;

                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
        async registNew() {
            this.$refs["AnalysisEditor"].initData();
        },
        async deleteChecked() {
            try {
                let confirm = await this.alertConfirmWarning("선택한 목록을 삭제하시겠습니까?");
                if (!confirm.value) return;

                const checked = this.$refs["TableList"].getCheckedRows();
                if (!this.isEmpty(checked)) {
                    checked.forEach(async (item) => {
                        let result = await backEndApi.analysisModel.deleteAnalysisModel(item.modelIdx);
                        if (result.status !== 200) {
                            this.alertWarning(`${result.data.message}`);
                            return;
                        }
                    });
                    await this.alertNoti("선택한 목록을 삭제하였습니다.");
                    let clear = await this.getAnalysisModelList();
                    if (!this.isEmpty(clear)) await this.$refs["AnalysisEditor"].initData();
                    else {
                        this.analysisList = [];
                        await this.$refs["AnalysisEditor"].initData();
                    }
                } else {
                    this.alertNoti("목록을 선택해주세요.");
                }
            } catch (e) {
                this.alertWarning("삭제에 실패하였습니다.");
                console.error(e);
            }
        },
        async changeStatusChecked(options) {
            let data = options;

            try {
                const checked = this.$refs["TableList"].getCheckedRows();
                if (!this.isEmpty(checked)) {
                    checked.forEach(async (item) => {
                        if (!this.isEmpty(data)) {
                            data.modelIdx = item.modelIdx;

                            if (data.hasOwnProperty("enabled")) {
                                let result = await backEndApi.analysisModel.modelSetEnabled(data);
                                if (result.status !== 200) {
                                    this.alertWarning(`${result.data.message}`);
                                    return;
                                }
                            } else {
                                console.warn("Unknown Status Name");
                            }
                        }
                    });
                    await this.alertNoti("변경하였습니다.");
                    await this.getAnalysisModelList();
                } else {
                    this.alertNoti("상태 변경할 목록을 선택해주세요.");
                }
            } catch (e) {
                this.alertWarning("상태 변경에 실패하였습니다.");
                console.error(e);
            }
        },
        async handleItemToggle(key, field, status) {
            await this.changeStatus(key, field, status);
        },
        async changeStatus(key, field, status) {
            const data = {};
            data.modelIdx = key;
            data.field = status;

            try {
                let result = await backEndApi.analysisModel.modelSetEnabled();
                if (result.status == 200) {
                    await this.alertNoti("변경하였습니다.");
                    await this.getAnalysisModelList();
                } else {
                    this.alertWarning(`${result.data.message}`);
                }
            } catch (e) {
                console.error(e);
            }
        },
        async handleItemClick(row, idx, toggle, check) {
            if (toggle || check) return;
            this.$refs["AnalysisEditor"].setData(row.modelIdx);
        },
    },
};
</script>

<style scoped>
.sidebarHeader {
    background-color: white;
    border-radius: 8px;
    margin: 5px 15px;
}
.sidebarBody {
    height: calc(100% - 40px);
    overflow-y: auto;
    background-color: #ffffff;
    padding: 5px 10px 15px 10px;
    border-radius: 8px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}
.sidebarContainer {
    padding: 0px 15px;
}
.ld-container {
    height: 90vh;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    padding: 10px 30px 10px 20px;
}

.list-placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.list-notification-icon {
    padding: 1rem;
    color: #444;
    font-size: 48px;
}

.list-notification-text {
    font-size: 15px;
    font-weight: bold;
}

.detail-area {
    height: 100%;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 10px;
    border: 1px solid #e7e7e7;
}
</style>
