<template>
    <div class="mnv-datatable-container">
        <b-table-simple bordered class="text-center">
            <!-- 첫 번째 행: 헤더 -->
            <b-thead>
                <b-tr :style="{ backgroundColor: '#ECEFF1' }">
                    <b-th>분석 대상</b-th>
                    <b-th>단위</b-th>
                    <b-th>환산계수</b-th>
                    <b-th>환산계수 단위</b-th>
                    <b-th>에너지 단가</b-th>
                    <b-th>에너지 단가 단위 </b-th>
                    <b-th>배출계수</b-th>
                    <b-th>배출계수 단위 </b-th>
                </b-tr>
            </b-thead>
            <!-- 두 번째 행: 데이터 (수정 가능) -->
            <b-tbody>
                <b-tr v-for="(row, rowIndex) in editableRows" :key="row.id">
                    <b-td>{{ row.ptName }}</b-td>
                    <b-td @click="enableEdit(rowIndex, 'targetUnit')" class="clickable-area">
                        <template v-if="editingField.row === rowIndex && editingField.field === 'targetUnit'">
                            <input
                                v-model="editableRows[rowIndex].factor.targetUnit"
                                @blur="saveRowEdit(rowIndex, 'targetUnit')"
                                @keydown.enter="saveRowEdit(rowIndex, 'targetUnit')"
                                type="text"
                                class="form-control"
                            />
                        </template>
                        <template v-else>
                            {{ editableRows[rowIndex].factor.targetUnit }}
                        </template>
                    </b-td>

                    <b-td @click="enableEdit(rowIndex, 'cnvtFactor')" class="clickable-area">
                        <template v-if="editingField.row === rowIndex && editingField.field === 'cnvtFactor'">
                            <input
                                v-model.number="editableRows[rowIndex].factor.cnvtFactor"
                                @blur="saveRowEdit(rowIndex, 'cnvtFactor')"
                                @keydown.enter="saveRowEdit(rowIndex, 'cnvtFactor')"
                                type="number"
                                step="1"
                                class="form-control"
                            />
                        </template>
                        <template v-else>
                            {{ editableRows[rowIndex].factor.cnvtFactor }}
                        </template>
                    </b-td>
                    <b-td>
                        {{ editableRows[rowIndex].factor.cnvtUnit }}
                    </b-td>
                    <b-td @click="enableEdit(rowIndex, 'unitCost')" class="clickable-area">
                        <template v-if="editingField.row === rowIndex && editingField.field === 'unitCost'">
                            <input
                                v-model.number="editableRows[rowIndex].factor.unitCost"
                                @blur="saveRowEdit(rowIndex, 'unitCost')"
                                @keydown.enter="saveRowEdit(rowIndex, 'unitCost')"
                                type="number"
                                step="100"
                                class="form-control"
                            />
                        </template>
                        <template v-else>
                            {{ editableRows[rowIndex].factor.unitCost }}
                        </template>
                    </b-td>
                    <b-td>
                        {{ editableRows[rowIndex].factor.costUnit + "/" + editableRows[rowIndex].factor.targetUnit }}
                    </b-td>
                    <b-td @click="enableEdit(rowIndex, 'CO2Factor')" class="clickable-area">
                        <template v-if="editingField.row === rowIndex && editingField.field === 'CO2Factor'">
                            <input
                                v-model.number="editableRows[rowIndex].factor.CO2Factor"
                                @blur="saveRowEdit(rowIndex, 'CO2Factor')"
                                @keydown.enter="saveRowEdit(rowIndex, 'CO2Factor')"
                                type="number"
                                step="0.000001"
                                class="form-control"
                            />
                        </template>
                        <template v-else>
                            {{ editableRows[rowIndex].factor.CO2Factor }}
                        </template>
                    </b-td>
                    <b-td>
                        {{ editableRows[rowIndex].factor.CO2Unit + "/" + editableRows[rowIndex].factor.targetUnit }}
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>

<script>
export default {
    props: ["resultData"],
    data() {
        return {
            editableRows: JSON.parse(JSON.stringify(this.resultData.variable.main)),
            editingField: { row: null, field: null },
        };
    },
    methods: {
        enableEdit(rowIndex, field) {
            this.editingField = { row: rowIndex, field: field };
        },
        saveRowEdit(rowIndex, field) {
            console.log("field:", field);
            const data = this.editableRows[rowIndex];

            const updatedFactor = {
                targetUnit: data.factor.targetUnit,
                cnvtUnit: data.factor.targetUnit,
                cnvtFactor: data.factor.cnvtFactor,
                unitCost: data.factor.unitCost,
                // costUnit: data.factor.costUnit,
                CO2Factor: data.factor.CO2Factor,
                // CO2Unit: data.factor.CO2Unit,
            };

            this.editingField = { row: null, field: null };

            this.$emit("updateFactor", updatedFactor, data.key);
        },
    },
    watch: {
        resultData: {
            immediate: true,
            deep: true,
            handler(newVal) {
                this.editableRows = JSON.parse(JSON.stringify(newVal.variable.main));
            },
        },
    },
};
</script>

<style scoped>
.mnv-datatable-container .table-bordered,
.mnv-datatable-container .table-bordered td,
.mnv-datatable-container .table-bordered th {
    border: 1px solid #e4e7ea;
}
.mnv-datatable-container td {
    max-width: 10px;
}
.form-control {
    height: 25px;
}
.text-center {
    text-align: center;
}
.clickable-area {
    cursor: pointer;
}
.clickable-area:hover {
    background-color: #fff9c4;
}
</style>
