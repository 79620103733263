<template>
    <div class="MnvPeriodTable">
        <!-- 테이블을 감싸는 스크롤 컨테이너 -->
        <div class="table-scroll-container">
            <b-table-simple bordered class="text-center mnv-period-table-box">
                <!-- 첫 번째 행: 기간 제목 (수정 가능) -->
                <b-thead>
                    <b-tr>
                        <b-th
                            v-for="(name, index) in periodName"
                            :key="'header-' + index"
                            :colspan="colspanVal.daybymonth[index] || 3"
                            class="align-middle clickable-area"
                            :class="{ blueGreyBackground: index === 0 }"
                        >
                            <template v-if="editingIndex === index">
                                <input
                                    v-model="editablePeriodName[index]"
                                    @blur="savePeriodName(index)"
                                    @keydown.enter="savePeriodName(index)"
                                    type="text"
                                    class="form-control"
                                />
                            </template>
                            <template v-else>
                                <div @click="enableEditing(index)">
                                    {{ editablePeriodName[index] }}
                                </div>
                            </template>
                        </b-th>
                    </b-tr>
                </b-thead>
                <!-- 두 번째 행: 월 정보 -->
                <b-tbody>
                    <b-tr>
                        <b-td
                            v-for="(date, index) in periodDate"
                            :key="'date-' + index"
                            :colspan="colspanVal.daybymonth[index] || 3"
                        >
                            {{ date }}
                        </b-td>
                    </b-tr>
                    <!-- 세 번째 행: 날짜 정보 -->
                    <b-tr>
                        <b-td v-for="(detailDate, index) in periodDetailDate" :key="'detail-' + index">
                            {{ detailDate }}
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>
    </div>
</template>

<script>
export default {
    props: ["resultData", "detailInfo"],
    data() {
        return {
            periodName: [],
            editablePeriodName: [],
            editingIndex: null,
            periodDate: [],
            periodDetailDate: [],
            colspanVal: {
                hourbyday: 7,
                daybymonth: [],
                monthbyyear: 7,
            },
            periodSpan: {
                hourbyday: ["00", "01", "02", "...", "21", "22", "23"],
                daybymonth: null,
                monthbyyear: ["1", "2", "3", "...", "10", "11", "12"],
            },
        };
    },
    watch: {
        resultData: {
            handler() {
                this.generatePeriodHeader();
                this.generatePeriod();
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        generatePeriodHeader() {
            this.periodName = [];
            this.editablePeriodName = [];
            this.periodName.push("베이스라인 기간");
            this.resultData.data.report.forEach((item, i) => {
                this.periodName.push(`보고기간 ${i + 1}`);
            });
            this.editablePeriodName = [...this.periodName];
        },
        generatePeriod() {
            if (this.detailInfo.timeDsvn === "HOURBYDAY") {
                this.periodDate = [];
                this.periodDetailDate = [];
                this.colspanVal.daybymonth = [];

                this.periodDate.push(this.resultData.data.baseline.regDt[0].split("T")[0]);
                this.resultData.data.report.forEach((item) => {
                    this.periodDate.push(item.regDt[0].split("T")[0]);
                });

                this.colspanVal.daybymonth.push(this.periodSpan.hourbyday.length);
                this.resultData.data.report.forEach(() => {
                    this.colspanVal.daybymonth.push(this.periodSpan.hourbyday.length);
                });

                this.periodDetailDate = [...this.periodSpan.hourbyday];
                this.resultData.data.report.forEach(() => {
                    this.periodDetailDate.push(...this.periodSpan.hourbyday);
                });
            } else if (this.detailInfo.timeDsvn === "DAYBYMONTH") {
                this.periodDate = [];
                this.periodDetailDate = [];
                this.colspanVal.daybymonth = [];

                this.periodDate.push(
                    this.resultData.data.baseline.regDt[0]
                        .split("-")
                        .slice(0, 2)
                        .join("-")
                );
                this.colspanVal.daybymonth.push(7);

                this.resultData.data.report.forEach((item) => {
                    this.periodDate.push(
                        item.regDt[0]
                            .split("-")
                            .slice(0, 2)
                            .join("-")
                    );
                    this.colspanVal.daybymonth.push(7);
                });

                this.periodDetailDate = [
                    ...this.resultData.data.baseline.regDt.map((item) => item.split("-")[2]).slice(0, 3),
                    "...",
                    ...this.resultData.data.baseline.regDt.map((item) => item.split("-")[2]).slice(-3),
                ];

                this.resultData.data.report.forEach((reportItem) => {
                    this.periodDetailDate.push(
                        ...reportItem.regDt.map((item) => item.split("-")[2]).slice(0, 3),
                        "...",
                        ...reportItem.regDt.map((item) => item.split("-")[2]).slice(-3)
                    );
                });
            } else if (this.detailInfo.timeDsvn === "MONTHBYYEAR") {
                this.periodDate = [];
                this.periodDetailDate = [];
                this.colspanVal.daybymonth = [];

                this.periodDate.push(this.resultData.data.baseline.regDt[0].split("-")[0]);
                this.colspanVal.daybymonth.push(this.periodSpan.monthbyyear.length);

                this.resultData.data.report.forEach((item) => {
                    this.periodDate.push(item.regDt[0].split("-")[0]);
                    this.colspanVal.daybymonth.push(this.periodSpan.monthbyyear.length);
                });

                this.periodDetailDate = [...this.periodSpan.monthbyyear];
                this.resultData.data.report.forEach(() => {
                    this.periodDetailDate.push(...this.periodSpan.monthbyyear);
                });
            }
        },
        enableEditing(index) {
            this.editingIndex = index; // 편집 모드 활성화
        },
        savePeriodName(index) {
            this.editingIndex = null; // 편집 모드 비활성화
            this.periodName[index] = this.editablePeriodName[index]; // 변경된 값 저장
            this.$emit("updatePeriodName", this.periodName);
        },
    },
};
</script>

<style scoped>
.blueGreyBackground {
    background-color: #cfd8dc !important;
}
.blueGreyBackground:hover {
    background-color: #fff9c4 !important;
}
.MnvPeriodTable {
    width: 100%;
}
.table-scroll-container {
    overflow-x: auto;
    width: 100%;
    display: block;
}
.mnv-period-table-box {
    width: max-content; /* 테이블이 콘텐츠 크기만큼 확장 */
    min-width: 100%; /* 테이블이 최소 너비를 가짐 */
}
.MnvPeriodTable .table-bordered,
.MnvPeriodTable .table-bordered td,
.MnvPeriodTable .table-bordered th {
    border: 1px solid #e4e7ea;
}
.form-control {
    height: 25px;
}
.text-center {
    text-align: center;
}
.clickable-area {
    background-color: #eceff1;
    cursor: pointer;
}
.clickable-area:hover {
    background-color: #fff9c4;
}
</style>
