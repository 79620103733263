<template>
    <div>
        <div class="baseTable">
            <b-table-simple bordered class="text-center baseSimpleTable">
                <b-thead class="sticky-header">
                    <b-tr>
                        <b-td rowspan="2" class="align-middle" :style="{ backgroundColor: '#ECEFF1' }">일자</b-td>
                        <b-td :colspan="resultData.variable.main.length" class="dependVar"> 종속변수</b-td>
                        <b-td :colspan="independVar.length" :style="{ backgroundColor: '#ECEFF1' }">독립 변수</b-td>
                    </b-tr>
                    <b-tr>
                        <!-- <b-td class="dependVar">
                            {{ "측정값" }}
                            {{ `(${resultData.variable.main[0].factor.targetUnit})` }}</b-td
                        >
                        <b-td class="dependVar">조정값</b-td>
                        <b-td class="dependVar">
                            조정된 측정값{{ `(${resultData.variable.main[0].factor.targetUnit})` }}
                        </b-td> -->
                        <b-td v-for="main in resultData.variable.main" :key="`${main.key}`" class="dependVar">
                            <!-- 환산값 {{ `(${resultData.variable.main[0].factor.cnvtUnit})` }} -->
                            {{ main.key }} {{ `(${main.factor.targetUnit})` }}
                        </b-td>
                        <b-td v-for="(item, i) in independVar" :key="i" :style="{ backgroundColor: '#ECEFF1' }">
                            {{ item.key }} {{ `(${units(item.unit)})` }}
                        </b-td>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr
                        v-for="(item, i) in resultData.data.baseline.regDt"
                        :key="i"
                        :class="{ errorBackground: calcFalsyValue(resultData.data.baseline[mainKey][i]) }"
                    >
                        <b-td>{{ detailInfo.timeDsvn === "HOURBYDAY" ? item.split("T")[1] : item }}</b-td>
                        <!-- <b-td>{{ resultData.data.baseline[mainKey][i] }}</b-td>
                        <b-td>
                            {{ sumAdjustData[i]?.total || 0 }}
                        </b-td>
                        <b-td>{{ resultData.data.baseline[mainInfo.adjusted][i] }}</b-td> -->
                        <!-- <b-td>{{ resultData.data.baseline[mainInfo.converted][i] }}</b-td> -->
                        <b-td v-for="(main, j) in resultData.variable.main" :key="`${main.key}_${j}`">
                            {{
                                resultData.data.baseline[mainInfo.find((info) => info.key == main.key).converted][i] ??
                                    "-"
                            }}
                        </b-td>
                        <b-td v-for="(independ, o) in independVar" :key="o">
                            {{ resultData.data.baseline[independ.key][i] ?? "-" }}
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>
        <!-- <div class="smallTitle">
            {{ "베이스라인 기간 비일상적 조정" }}
        </div>

        <div>
            <b-table-simple bordered class="text-center">
                <b-thead>
                    <b-tr>
                        <b-td rowspan="2" class="align-middle" :style="{ backgroundColor: '#ECEFF1' }">일자</b-td>
                        <b-td
                            :colspan="adjustedNames.length !== 0 ? adjustedNames.length : 1"
                            :style="{ backgroundColor: '#ECEFF1' }"
                        >
                            비일상적 조정 상세 내역
                        </b-td>
                        <b-td rowspan="2" class="align-middle " :style="{ backgroundColor: '#ECEFF1' }">
                            합계 ({{ resultData.variable.main[0].factor.targetUnit }})
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td
                            v-for="(name, nameIndex) in adjustedNames"
                            :key="nameIndex"
                            :style="{ backgroundColor: '#ECEFF1' }"
                            >{{ name }}</b-td
                        >
                    </b-tr>
                </b-thead>
                <b-tbody v-if="baselineAdjustData.length > 0">
                    <b-tr v-for="(data, index) in baselineAdjustData" :key="index">
                        <b-td>{{ data.regDt }}</b-td>
                        <b-td v-for="(name, i) in adjustedNames" :key="i">
                            {{ data[name] || "-" }}
                        </b-td>
                        <b-td>{{ data.sum || "-" }}</b-td>
                    </b-tr>
                    <b-tr class="total-row boldFont">
                        <b-td class="text-center" :style="{ backgroundColor: '#e3f2fd' }">합계</b-td>
                        <b-td
                            v-for="(name, i) in adjustedNames"
                            :key="i"
                            class="text-center"
                            :style="{ backgroundColor: '#e3f2fd' }"
                        >
                            {{ calculateColumnSum(name) }}
                        </b-td>
                        <b-td class="text-center" :style="{ backgroundColor: '#e3f2fd' }">
                            {{ calculateTotalSumAdjust() }}
                        </b-td>
                    </b-tr>
                </b-tbody>

                <b-tbody v-else>
                    <b-tr>
                        <b-td :colspan="adjustedNames.length !== 0 ? adjustedNames.length + 3 : 3" class="text-center">
                            데이터가 없습니다.
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div> -->
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    props: ["resultData", "detailInfo"],
    data() {
        return {
            adjustedName: [],
            excludedKeys: ["regDt", "sumValue", "type", "value"],
            baselineAdjustData: [],
            sumAdjustData: [],
        };
    },
    watch: {
        resultData: {
            handler(newData) {
                this.transformAdjustData(newData);
                this.sumAdjustDataByRegDt(newData.adjustData);
            },
            deep: true,
        },
    },
    computed: {
        ...mapState({
            envUnit: (state) => state.units,
        }),

        adjustedNames() {
            // report 객체와 mainKey 데이터가 있는지 확인
            const baselineData = this.resultData.adjustData?.baseline?.[this.mainKey];
            return baselineData ? Object.keys(baselineData) : []; // 데이터가 없으면 빈 배열 반환
        },
        mainKey() {
            return this.resultData.variable.main[0].key;
        },
        mainInfo() {
            return this.resultData.variable.main.map((item) => {
                return {
                    key: item.key,
                    adjusted: `${item.key}_adjusted`,
                    converted: `${item.key}_converted`,
                };
            });
            // return {
            //     original: this.mainKey,
            //     adjusted: `${this.mainKey}_adjusted`,
            //     converted: `${this.mainKey}_converted`,
            // };
        },
        independVar() {
            return this.resultData.variable.independ.map((item) => {
                return {
                    key: item.key,
                    unit: item.unitSmallCode,
                };
            });
        },
    },
    methods: {
        sumAdjustDataByRegDt(adjustData) {
            if (!adjustData || !adjustData.baseline || !this.mainKey) {
                console.error("Invalid adjustData format or mainKey is missing");
                return [];
            }

            const baseline = adjustData.baseline[this.mainKey];
            if (!baseline) {
                console.error("No data found for the specified mainKey");
                return [];
            }

            const result = [];
            const regDtList = this.resultData.data.baseline.regDt; // 날짜 리스트

            for (let i = 0; i < regDtList.length; i++) {
                const summedRow = { regDt: regDtList[i], total: 0 };

                Object.keys(baseline).forEach((adjustKey) => {
                    const value = baseline[adjustKey][i] || 0; // 해당 regDt의 adjust 값 가져오기
                    summedRow.total += value;
                });
                result.push(summedRow);
            }

            console.log("Summed Adjust Data:", result);
            this.sumAdjustData = result;
            return;
        },
        transformAdjustData(resultData) {
            if (!resultData.adjustData || !resultData.adjustData.baseline) {
                return;
            }
            const regDt = resultData.data.baseline.regDt;
            const adjustments = resultData.adjustData.baseline[this.mainKey];
            // 조정식 키를 동적으로 추출
            const adjustmentKeys = Object.keys(adjustments);
            const transformedData = regDt
                .map((date, index) => {
                    // 각 조정식 값을 동적으로 추가
                    const adjustmentValues = adjustmentKeys.reduce((acc, key) => {
                        acc[key] = adjustments[key][index] || 0;
                        return acc;
                    }, {});

                    // 합계 계산
                    const sum = adjustmentKeys.reduce((total, key) => total + (adjustmentValues[key] || 0), 0);

                    // 객체 생성
                    const rowData = {
                        regDt: date,
                        ...adjustmentValues, // 동적으로 추가된 조정식 값들
                        sum,
                    };

                    // 모든 값이 0인지 확인 (regDt와 sum은 제외)
                    const allZero = adjustmentKeys.every((key) => adjustmentValues[key] === 0);

                    // 모든 값이 0이 아니면 포함
                    return allZero ? null : rowData;
                })
                .filter(Boolean); // null 값 제거

            console.log("transformedData", transformedData);
            this.baselineAdjustData = transformedData;
            return;
        },
        calculateColumnSum(name) {
            // 특정 컬럼(name)의 합계를 계산
            return this.baselineAdjustData.reduce((total, row) => {
                const value = parseFloat(row[name]) || 0;
                return total + value;
            }, 0);
        },

        calculateTotalSumAdjust() {
            // 합계(sum) 컬럼의 전체 합계를 계산
            return this.baselineAdjustData.reduce((total, row) => {
                const value = parseFloat(row.sum) || 0;
                return total + value;
            }, 0);
        },

        filterCustomKeys(object) {
            const filteredObject = Object.fromEntries(
                Object.entries(object).filter(([key]) => !this.excludedKeys.includes(key))
            );
            return filteredObject;
        },
        units(unitValue) {
            const foundUnit = this.envUnit.find((item) => item.value === unitValue);
            return foundUnit ? foundUnit.text : "Unknown Unit";
        },
        calcFalsyValue(value) {
            return value == undefined || value === 0 || value == null ? 1 : 0;
        },
    },
    mounted() {
        this.sumAdjustDataByRegDt(this.resultData.adjustData);
        this.transformAdjustData(this.resultData);
    },
};
</script>

<style scoped>
.dependVar {
    background-color: #cfd8dc !important;
}
.errorBackground {
    background-color: #e57373;
    color: white;
}
.smallTitle {
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 5px;
}
.baseTable {
    max-height: 420px;
    overflow-y: auto;
    margin-bottom: 15px;
}

.sticky-header {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #eceff1;
}
.baseSimpleTable {
    border-collapse: separate;
    border-spacing: 0;
}
.boldFont {
    font-weight: bold;
}
</style>
