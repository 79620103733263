<template>
    <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
        <b-button
            :ref="'saveBtn'"
            squared
            class="m-l-10"
            variant="#ffffff"
            size="md"
            @click="onSave()"
            style="border:2px solid #348fe2; border-radius: 10px!important;"
            :style="{
                padding: '5px',
                height: '34px',
                textAlign: 'center',
                backgroundColor: '#ffffff',
                alignItems: 'center',
                width: '55px',
                height: '100%',
                padding: 0,
                color: '#348fe2',
            }"
        >
            <span>{{ $t("저장") }}</span>
        </b-button>

        <b-button
            :ref="'removeSelectBtn'"
            squared
            class=" m-l-10"
            variant="#ffffff"
            size="md"
            @click="onSelectRemove()"
            style="border:2px solid #348fe2; border-radius: 10px!important;"
            :style="{
                padding: '5px',
                height: '34px',
                textAlign: 'center',
                backgroundColor: '#ffffff',
                alignItems: 'center',
                width: '55px',
                height: '100%',
                padding: 0,
                color: '#348fe2',
            }"
        >
            <span>{{ $t("삭제") }}</span>
        </b-button>
        <!-- 
    <b-button :ref="'removeAllBtn'" squared class="fa-3x m-l-10" variant="#ffffff" size="md" @click="onRemoveAll()" 
      style="border:2px solid #348fe2; border-radius: 10px!important;" :style="{ padding: '5px', height:'34px', textAlign: 'center', backgroundColor: '#ffffff', alignItems:'center', width:'55px', height: '100%', padding:0, color:'#348fe2'}" >
      <span>{{"일괄 삭제"}}</span>
    </b-button>  -->
    </div>
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";

export default {
    props: [],
    components: {},
    watch: {},
    data() {
        return {};
    },
    created() {},
    mounted() {},
    methods: {
        onSave() {
            this.$emit("save");
        },
        onSelectRemove() {
            this.$emit("deleteSelect");
        },
        onRemoveAll() {
            this.$emit("deleteAll");
        },

        // checkBasicCode(costBasicCode){
        //   costBasicCode
        //   if(costBasicCode == "elecCostResnt"){
        //     this.$refs.searchBtn.hidden = true;
        //     this.$refs.saveBtn.hidden = true;
        //     this.$refs.removeBtn.hidden = true;
        //     this.$refs.matchBtn.hidden = true;
        //   }else{
        //     this.$refs.searchBtn.hidden = false;
        //     this.$refs.saveBtn.hidden = false;
        //     this.$refs.removeBtn.hidden = false;
        //     this.$refs.matchBtn.hidden = false;
        //   }
        // }
    },
};
</script>

<style scoped></style>
