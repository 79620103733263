<template>
    <div v-if="!isEmpty(resultData)" class="MnvContainer">
        <div class="info borderStyle">
            {{
                "CxEMS의 M&V 분석 기능은 IPMVP (International Performance Measurement and Verification Protocol) 기준에 따라 제공됩니다."
            }}
        </div>
        <!-- color: #66bb6a; -->
        <div class="analysisTarget borderStyle">
            <div class="smallTitle"><i class="fa fas fa-check checkIcon" />{{ "분석 대상" }}</div>
            <div>
                <mnv-analysis-target-table
                    :resultData="resultData"
                    @updateFactor="onUpdateFactor"
                ></mnv-analysis-target-table>
            </div>
        </div>
        <div class="analysisPeriod borderStyle">
            <div class="smallTitle">
                <i class="fa fas fa-check checkIcon" />
                {{ "분석 기간" }}
            </div>
            <div>
                <mnv-analysis-period-table
                    :resultData="resultData"
                    :detailInfo="detailInfo"
                    @updatePeriodName="onUpdatePeriodName"
                ></mnv-analysis-period-table>
            </div>
        </div>
        <div class="baselinePeriodData borderStyle">
            <div class="smallTitle" style="display: flex; align-items: center; justify-content: space-between;">
                <div>
                    <i class="fa fas fa-check checkIcon" />
                    <span>{{ "베이스라인 기간 데이터 " }}</span>
                    <span>
                        {{
                            `(${resultData.data.baseline.regDt[0] ?? "-"} ~ 
                                ${resultData.data.baseline.regDt[resultData.data.baseline.regDt.length - 1] ?? "-"})`
                        }}
                    </span>
                </div>
                <div v-if="mode == 'setting'">
                    <button type="button" class="btn btn-sm btn-primary" @click="showPropMgmtModal">
                        {{ "변수 관리" }}
                    </button>
                </div>
                <span class="badge others errorBadge" v-if="baselineErrorVal">오류 {{ `${baselineErrorVal}` }}건</span>
            </div>
            <mnv-base-period-table :resultData="resultData" :detailInfo="detailInfo"></mnv-base-period-table>
        </div>
        <div class="reportPeriodData borderStyle">
            <div class="smallTitle" style="display: flex; align-items: center; justify-content: space-between;">
                <div>
                    <i class="fa fas fa-check checkIcon" />
                    <span>{{ "보고기간 데이터 " }}</span>
                    <span>
                        {{
                            `(${resultData.data.report[0].regDt[0] ?? "-"} ~ 
                            ${resultData.data.report[resultData.data.report.length - 1].regDt[
                                resultData.data.report[0].regDt.length - 1
                            ] ?? "-"})`
                        }}
                    </span>
                </div>
                <div v-if="mode == 'setting'">
                    <button type="button" class="btn btn-sm btn-primary" @click="showPropMgmtModal">
                        {{ "변수 관리" }}
                    </button>
                </div>
                <span class="badge others errorBadge" v-if="reportErrorVal">오류 {{ `${reportErrorVal}` || 0 }}건</span>
            </div>
            <mnv-report-period-table
                :resultData="resultData"
                :detailInfo="detailInfo"
                :periodName="periodName"
            ></mnv-report-period-table>
        </div>
        <div class="dataValidation borderStyle">
            <div class="smallTitle" style="display: flex; align-items: center; justify-content: space-between;">
                <div>
                    <i class="fa fas fa-check checkIcon" />
                    <span>{{ "데이터 유효성 검증" }}</span>
                </div>
                <div>
                    <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        @click="$refs['independ-scatter-modal'].show()"
                    >
                        {{ "상관도 보기" }}
                    </button>
                </div>
            </div>
            <div>
                <mnv-validation-table :resultData="resultData"></mnv-validation-table>
            </div>
        </div>
        <b-modal
            ref="independ-scatter-modal"
            id="independ-scatter-modal"
            centered
            @hide="$refs['independ-scatter-modal'].hide()"
            @shown="renderChartAfterModal"
        >
            <template #modal-header>
                <h5 class="m-0">{{ "독립변수 상관도 분석" }}</h5>
            </template>
            <div>
                <mnv-independ-var-scatter-panel
                    ref="independVarScatterPanel"
                    :resultData="resultData"
                    :detailInfo="detailInfo"
                ></mnv-independ-var-scatter-panel>
            </div>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        style="flex: 1;"
                        @click="$refs['independ-scatter-modal'].hide()"
                    >
                        {{ "닫기" }}
                    </button>
                </div>
            </template>
        </b-modal>
        <div class="selectModelFomula borderStyle">
            <div class="smallTitle"><i class="fa fas fa-check checkIcon" />분석 모델식 선택</div>
            <div v-for="(key, index) in mainKey" :key="`${key}-select-equation`">
                <div class="selectFomulaBox">
                    <div style="display: flex; justify-content: flex-end; width: 300px; margin-right: 10px;">
                        <span>{{ `${key} : ` }}</span>
                    </div>
                    <b-form-select
                        v-model="selectEquation[index]"
                        :options="[{ value: null, text: '분석 모델식을 선택하세요.' }, ...evalList[key]]"
                    ></b-form-select>
                    <button
                        type="button"
                        class="btn btn-secondary showSignificanceButton"
                        @click="showModelSignificanceModal(key)"
                    >
                        {{ "모델 유의성 평가" }}
                    </button>
                </div>
            </div>
            <div style="display: flex; flex: 1;">
                <button
                    type="button"
                    class="analysisButton btn btn-primary createReportButton"
                    :disabled="selectEquationIsNull"
                    @click="generateReport()"
                >
                    <b-spinner small v-if="isLoading" variant="primary" label="Spinning"></b-spinner>
                    <span v-else>{{ "보고서 생성" }}</span>
                </button>
            </div>
        </div>
        <!-- 모델 유의성 평가 모달달 -->
        <b-modal ref="modelSignificanceModal" centered id="significance-modal-body" @hide="hideModelSignificanceModal">
            <template #modal-header>
                <h5 class="modal-title">{{ "모델 유의성 평가" }}</h5>
            </template>
            <div v-if="modelKey" class="modelSignificance borderStyle">
                <div class="smallTitle" style="display: flex; justify-content: space-between; align-items: center;">
                    <div>
                        <i class="fa fas fa-check checkIcon" />
                        <span>{{ "모델 유의성 평가" }}</span>
                    </div>
                    <b-form-select
                        v-model="cutoff"
                        :options="[{ value: null, text: '모델 유의 기준 (pValue)' }, ...pValueCutoff]"
                        :style="{ width: '200px' }"
                    ></b-form-select>
                </div>
                <div style="font-size: 11px !important;">
                    <mnv-significance-table
                        :resultData="resultData"
                        :cutoff="cutoff"
                        :modelKey="modelKey"
                    ></mnv-significance-table>
                </div>
            </div>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        style="flex: 1;"
                        @click="hideModelSignificanceModal"
                    >
                        {{ "닫기" }}
                    </button>
                </div>
            </template>
        </b-modal>
        <!-- 분석 결과 -->
        <div v-if="isSuccess == 'Success'" class="MNVResult borderStyle">
            <div class="smallTitle">
                <i class="fa fas fa-check checkIcon" />
                <span class="mb-0">{{ "분석 결과" }}</span>
            </div>
            <mnv-analysis-result-panel
                v-if="!isLoading"
                :mnvReportData="mnvReportData"
                :resultData="resultData"
                :detailInfo="detailInfo"
                :periodName="periodName"
            />
            <b-spinner small v-else variant="primary" label="Spinning"></b-spinner>
        </div>
    </div>
</template>

<script>
import MnvAnalysisPeriodTable from "../tables/MnvAnalysisPeriodTable.vue";
import MnvAnalysisTargetTable from "../tables/MnvAnalysisTargetTable.vue";
import MnvSignificanceTable from "../tables/MnvSignificanceTable.vue";
import MnvValidationTable from "../tables/MnvValidationTable.vue";
import MnvBasePeriodTable from "../tables/MnvBasePeriodTable.vue";
import MnvReportPeriodTable from "../tables/MnvReportPeriodTable.vue";
import MnvIndependVarScatterPanel from "../charts/MnvIndependVarScatterPanel.vue";
import MnvAnalysisResultPanel from "../tables/MnvAnalysisResultPanel.vue";
import backEndApi from "@src/api/backEndApi";
export default {
    props: ["detailInfo", "resultData", "adjustData", "fixIndependData", "mode"],
    components: {
        MnvAnalysisPeriodTable,
        MnvAnalysisTargetTable,
        MnvSignificanceTable,
        MnvValidationTable,
        MnvBasePeriodTable,
        MnvReportPeriodTable,
        MnvIndependVarScatterPanel,
        MnvAnalysisResultPanel,
    },
    async mounted() {
        this.mainKey.forEach(() => {
            this.selectEquation.push(null);
        });

        this.resultData.variable.main.forEach((item) => {
            this.localFactor[item.key] = item.factor;
        });

        if (this.resultData) {
            this.initPeriodName();
            // 임시 주석 by woonsik 2025-01-02
            // this.calcTotalErrorVal();
        }
        // 임시 주석 by woonsik 2025-01-02
        // if (
        //     !this.isEmpty(this.detailInfo.modelOptions) &&
        //     this.resultData?.variable?.main?.length > 0 &&
        //     this.resultData?.model?.[this.resultData.variable.main[0]?.key]
        // ) {
        //     let find = this.resultData.model[this.resultData.variable.main[0].key].find(
        //         (item) => item.equation === this.detailInfo.modelOptions.equation
        //     );
        //     if (find) {
        //         this.selectEquation = this.detailInfo.modelOptions.equation;
        //         await this.generateReport();
        //     }
        // } else {
        //     this.mainKey.forEach(() => {
        //         this.selectEquation.push(null);
        //     });
        // }
    },
    data() {
        return {
            // selectEquation: null,
            selectEquation: [],
            cutoff: "0.05",
            isLoading: false,
            mnvReportData: null,
            // mnvReportData: {},
            isSuccess: null,
            localFactor: {
                ...this.resultData.variable.main.factor,
            },
            periodName: [],
            baselineErrorVal: 0,
            reportErrorVal: 0,
            modelKey: null,
        };
    },
    methods: {
        renderChartAfterModal() {
            if (this.$refs["independVarScatterPanel"]) {
                this.$refs["independVarScatterPanel"].renderCharts();
            }
        },
        showPropMgmtModal() {
            this.$emit("prop-mgmt-modal");
        },
        async showModelSignificanceModal(key) {
            this.modelKey = key;
            await this.$refs["modelSignificanceModal"].show();
        },
        async hideModelSignificanceModal() {
            await this.$refs["modelSignificanceModal"].hide();
            this.modelKey = null;
        },
        calcTotalErrorVal() {
            //베이스라인 기간 데이터, 보고기간 데이터 테이블에서 error 값 갯수계산
            this.baselineErrorVal = this.numberOfFalsy(this.resultData.data.baseline[this.mainKey]);
            this.resultData.data.report.forEach((item) => {
                this.reportErrorVal += this.numberOfFalsy(item[this.mainKey]);
            });
        },
        numberOfFalsy(arr) {
            return arr.reduce((acc, cur) => acc + (cur == undefined || cur === 0 || cur == null ? 1 : 0), 0);
        },
        onUpdatePeriodName(period) {
            this.periodName = [...period];
        },
        initPeriodName() {
            this.periodName.push("베이스라인 기간");
            this.resultData.data.report.forEach((_, i) => this.periodName.push(`보고기간${i + 1}`));
        },
        onUpdateFactor(factor, key) {
            this.localFactor[key] = factor;
        },
        async generateReport() {
            // const findMainVariable = this.detailInfo.variables.find((item) => item.roleType == "Depend");

            const mvnSearchInfo2 = {
                startOfYear: 1,
                ptIdx: this.detailInfo.ptIdx,
                timeDsvn: this.detailInfo.timeDsvn,
                fromDate: this.detailInfo.fromDate,
                toDate: this.detailInfo.toDate,
                variables: [],
                compareType: this.detailInfo.compareType,
                fromDateComp: this.detailInfo.fromDateComp,
                toDateComp: this.detailInfo.toDateComp,
                datasetMode: "Divided",
                dispUnit: this.detailInfo.dispUnit,
                modelContext: {},
                // baseline: {
                //     adjustData: {},
                // },
                // report: {
                //     adjustData: {},
                // },
                // baseline: null,
                // report: null,
                adjustData: {},
            };
            /**
             * modelContext -> modelOptions 변경,
             * modelOptions 에 담아서 모델 저장하면 나중에 모델 선택시
             * 해당 값 그대로 가져와서 분석 결과 바로 조회 할 수 있음 made by woonsik
             */
            // mvnSearchInfo2.modelContext[this.detailInfo.modelOptions.dependent] = this.detailInfo.modelOptions;
            // mvnSearchInfo2.modelContext[this.detailInfo.modelOptions.dependent].factor = this.localFactor;

            this.resultData.variable.main.forEach((item) => {
                mvnSearchInfo2.modelContext[item.key] = this.resultData.model[item.key].find((model) =>
                    this.selectEquation.some((eq) => eq == model.equation)
                );
                mvnSearchInfo2.modelContext[item.key].factor = this.localFactor[item.key];
            });

            this.detailInfo.variables.forEach((pt) => {
                mvnSearchInfo2.variables.push(pt);
            });

            if (!this.isEmpty(this.adjustData) && Object.keys(this.adjustData).length > 0) {
                mvnSearchInfo2.adjustData = this.adjustData;

                // mvnSearchInfo2.baseline = {
                //     adjustData: {},
                // };
                // mvnSearchInfo2.report = {
                //     adjustData: {},
                // };
                // Object.keys(this.adjustData).forEach((key) => {
                //     mvnSearchInfo2[key].adjustData[findMainVariable.name] = this.adjustData[key];
                // });
                // mvnSearchInfo2.baseline.adjustData[findMainVariable.name] = this.adjustData;
            }

            if (!this.isEmpty(this.fixIndependData) && Object.keys(this.fixIndependData).length > 0) {
                if (!this.isEmpty(this.fixIndependData.baseline)) {
                    Object.keys(this.fixIndependData.baseline).forEach((key) => {
                        mvnSearchInfo2.baseline[key] = this.fixIndependData.baseline[key];
                    });
                }
                if (!this.isEmpty(this.fixIndependData.report)) {
                    Object.keys(this.fixIndependData.report).forEach((key) => {
                        mvnSearchInfo2.report[key] = this.fixIndependData.report[key];
                    });
                }
            }
            console.log("mvnSearchInfo2 generate report", mvnSearchInfo2);
            await this.analyzeMNV(mvnSearchInfo2);
        },
        async analyzeMNV(searchInfo) {
            this.isSuccess = null;
            this.isLoading = true;
            try {
                let result = await backEndApi.analysisModel.analyzeMNV(searchInfo);

                if (result.status == 200) {
                    if (result.data.analysisStatus === "CheckVariableData") {
                        throw new Error(`유효하지 않은 데이터를 포함한 변수가 존재합니다.`);
                    } else if (result.data.analysisStatus === "CheckUserVariables") {
                        throw new Error(`사용자 정의 변수에 오류가 존재합니다.`);
                    } else if (result.data.analysisStatus === "Success") {
                        this.alertNoti("보고서 생성이 완료되었습니다!");
                        this.isSuccess = result.data.analysisStatus;
                        this.mnvReportData = result.data;
                        // result.data.variable.main.forEach((item) => {
                        //     this.mnvReportData[item.key] = result.data;
                        // });
                        console.log("this.mnvReportData", this.mnvReportData);
                    }
                } else {
                    // this.mnvReportData = {};
                    this.mnvReportData = null;
                    this.alertWarning(`${result.data.message}`, "", `${result.data.detail ?? ""}`);
                }
            } catch (e) {
                // 예외 처리
                console.error(e);
                this.alertNoti(`${e.message}`);
            } finally {
                this.isLoading = false;
            }
        },
    },
    watch: {
        adjustData(newVal) {
            if (newVal) {
                console.log("adjustData event mnvResultPanel", newVal);
            }
        },

        // selectEquation(newVal) {
        //     this.detailInfo.modelOptions = this.resultData.model[this.resultData.variable.main[0].key].find(
        //         (item) => item.equation === newVal
        //     );
        //     console.log("this.detailInfo.modelOptions watching selectEquation", this.detailInfo.modelOptions);
        //     // this.detailInfo.modelContext = this.resultData.model[this.resultData.variable.main[0].key].find(
        //     //     (item) => item.equation === newVal
        //     // );
        // },
        resultData(newVal) {
            if (newVal) {
                this.calcTotalErrorVal();
                console.log("resultData watching", newVal);
            }
        },
    },
    computed: {
        selectEquationIsNull() {
            return this.selectEquation.some((item) => item == null);
        },
        mainKey() {
            return this.resultData.variable.main.map((item) => item.key);
        },
        pValueCutoff() {
            const values = this.$store.state.commonCodes.PValueCutoff.map((item) => {
                return {
                    value: item.value,
                    text: item.text,
                };
            });
            return values ?? [];
        },
        evaluationSinificance() {
            const mainKey = this.resultData.variable.main[0].key;
            const modelList = this.resultData.model ? this.resultData.model[mainKey] : [];
            const list = modelList.map((item) => {
                // 모델의 p-value 테스트는 model의 pValue로만 하는 것이 맞는 것 같음. 임시로 true 설정 확정은 아님 by badblock.2024-12-25
                // const pValue = item.pValues.every((value) => value < this.cutoff);
                const pValue = true;
                const modelPValue = item.modelPValue < this.cutoff ? true : false;
                // const R2 = item.R2 > 50 ? true : false;
                // R2값과 adjustR2값이 백분율에서 0 ~ 1의 소수로 변경됨.
                const R2 = item.R2 > 0.5 ? true : false;

                // const adjustedR2 = item.adjustedR2 > 50 ? true : false;
                const adjustedR2 = item.adjustedR2 > 0.5 ? true : false;

                item.significance = pValue && modelPValue && R2 && adjustedR2;

                // console.warn( `evaluationSinificance: model pValue: ${modelPValue}, R2: ${R2}, adjusted R2: ${adjustedR2}` );

                return pValue && modelPValue && R2 && adjustedR2 ? true : false;
                // return true;
            });

            return list;
        },
        evalList() {
            // let list = [];
            let list = {};

            // const mainKey = this.resultData.variable.main[0].key;
            const mainKey = this.resultData.variable.main.map((item) => item.key);

            // const modelList = this.resultData.model ? this.resultData.model[mainKey] : [];
            let modelList = {};
            mainKey.forEach((key) => {
                modelList[key] = this.resultData.model ? this.resultData.model[key] : [];
            });

            // modelList.forEach((item) => {
            //     // 모델의 p-value 테스트는 model의 pValue로만 하는 것이 맞는 것 같음. 임시로 true 설정 확정은 아님 by badblock.2024-12-25
            //     // const pValue = item.pValues.every((value) => value < this.cutoff);
            //     const pValue = true;
            //     const modelPValue = item.modelPValue < this.cutoff ? true : false;
            //     const R2 = item.R2 > 0.5 ? true : false;
            //     const adjustedR2 = item.adjustedR2 > 0.5 ? true : false;

            //     item.significance = pValue && modelPValue && R2 && adjustedR2;
            // });
            mainKey.forEach((key) => {
                modelList[key].forEach((item) => {
                    const pValue = true;
                    const modelPValue = item.modelPValue < this.cutoff ? true : false;
                    const R2 = item.R2 > 0.5 ? true : false;
                    const adjustedR2 = item.adjustedR2 > 0.5 ? true : false;

                    item.significance = pValue && modelPValue && R2 && adjustedR2;
                });
            });

            // new Version by badblock.2024.12.26
            // list = modelList
            //     .filter((model) => model.significance)
            //     .sort((a, b) => b.adjustedR2 - a.adjustedR2)
            //     .map((filterd) => {
            //         return {
            //             value: filterd.equation,
            //             text: `[${(filterd.adjustedR2 * 100).toFixed(1)}%] ${filterd.equation}`,
            //         };
            //     });
            mainKey.forEach((key) => {
                list[key] = modelList[key]
                    .filter((model) => model.significance)
                    .sort((a, b) => b.adjustedR2 - a.adjustedR2)
                    .map((filterd) => {
                        return {
                            value: filterd.equation,
                            text: `[${(filterd.adjustedR2 * 100).toFixed(1)}%] ${filterd.equation}`,
                        };
                    });
            });

            console.warn("evalList : ", list);

            return list;
        },
    },
};
</script>

<style scoped>
.MnvContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
    padding: 10px;
}
.smallTitle {
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 5px;
}
.MNVtable {
    width: 100%;
}
.info,
.analysisTarget,
.analysisPeriod,
.baselinePeriodData,
.reportPeriodData,
.dataValidation,
.modelSignificance,
.selectModelFomula,
.MNVResult {
    width: 100%;
    min-height: 50px;
    flex-grow: 1;
    padding: 10px;
    box-sizing: border-box;
}
.borderStyle {
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.info {
    /* background-color: aqua; */
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}
.errorBadge {
    font-size: 10px;
    margin-left: 4px;
    background-color: #e57373;
    color: white;
}
.analysisTarget {
    /* background-color: beige; */
}

.analysisPeriod {
    width: 100%;
    /* background-color: blanchedalmond; */
}

.baselinePeriodData {
    /* background-color: blue; */
    /* color: white; */
}

.reportPeriodData {
    /* background-color: red; */
    /* color: white; */
}

.dataValidation {
    /* background-color: brown; */
    /* color: white; */
}
.modelSignificance {
    /* background-color: chartreuse; */
    /* color: white; */
}
.selectModelFomula {
    /* background-color: darkcyan; */
    /* color: white; */
}
.MNVResult {
    /* background-color: darkblue; */
    /* color: white; */
}
.selectFomulaBox {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}
.createReportButton {
    /* width: 100px;
    margin-left: 50px; */
    flex: 1;
}
.showSignificanceButton {
    width: 150px;
    margin-left: 50px;
}
.checkIcon {
    margin-right: 5px;
}
.independentVarScatter {
    width: 100%;
    min-height: 50px;
    flex-grow: 1;
    box-sizing: border-box;
}
</style>
