<template>
    <div>
        <date-picker
            ref="datePicker"
            v-model="internalValue"
            :type="calendarTypeFordatePicker"
            :range="rangeBoolean"
            :valueType="datePickerFormat"
            lang="ko"
            :placeholder="placeholder"
            @input="updateValue"
            :disabled="isDatePickerDisabled"
            :format="datePickerFormat"
            :minute-step="minuteStep"
            :disabled-date="disabledDate"
        ></date-picker>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";

dayjs.extend(isoWeek);
export default {
    components: {
        DatePicker,
    },
    props: [
        "value",
        "calendarType",
        "placeholder",
        "baseDayTime",
        "compareType",
        "start",
        "end",
        // "disabledCalendar",
        "detailInfo",
        "rangeBooleanSuper",
    ],
    data() {
        return {
            internalValue: this.value,
            calendarTypeFordatePicker: null,
            datePickerFormat: "",
            minuteStep: null,
            rangeBoolean: false,
            localCompareType: this.compareType,
            isInternalUpdate: false,
        };
    },
    computed: {
        isDatePickerDisabled() {
            if (
                !this.calendarType
                //  || this.disabledCalendar
            )
                return true;

            if (this.end && this.localCompareType !== "User" && this.localCompareType !== "Baseline") {
                return true;
            }

            return false;
        },
    },
    watch: {
        rangeBooleanSuper(newVal) {
            console.log("rangeBoolean Super is changed", newVal);
            this.rangeBoolean = newVal;
        },
        calendarType(newVal) {
            if (newVal) {
                // rangeBoolean 값 설정
                this.rangeBoolean =
                    (["MONTH", "DAY", "YEAR"].includes(newVal) && this.detailInfo?.analysisMethod !== "Goal") ||
                    this.detailInfo?.analysisMethod === "MNV";

                this.$emit("range", this.rangeBoolean);

                this.transformCalendarType(newVal);
                this.timeFormat(newVal);

                if (this.internalValue) {
                    this.internalValue = this.formatDate(this.internalValue, this.datePickerFormat);
                }
            } else {
                this.rangeBoolean = false;
                this.$emit("range", this.rangeBoolean);
            }
        },

        localCompareType(newVal) {
            if (this.end && (newVal === null || newVal === undefined || newVal !== "User") && newVal !== "Baseline") {
                this.$emit("input", null);
            }
        },
        compareType(newVal) {
            this.localCompareType = newVal;
        },
        value(newValue) {
            if (this.localCompareType == "Baseline" && this.end && typeof newValue === "string") {
                this.rangeBoolean = true;
                this.internalValue = [newValue, newValue];
            } else {
                this.internalValue = newValue;
            }
        },
        internalValue(newVal) {
            this.$emit("input", newVal);
        },
    },
    created() {
        if (this.calendarType) {
            this.transformCalendarType(this.calendarType);
            this.timeFormat(this.calendarType);
        }
    },
    methods: {
        adjustEndDateForMnv() {
            if (this.detailInfo?.analysisMethod === "MNV" && this.end) {
                const baseStart = this.baseDayTime?.[0] ? dayjs(this.baseDayTime[0]) : null;
                const baseEnd = this.baseDayTime?.[1] ? dayjs(this.baseDayTime[1]) : null;
                const start = dayjs(this.internalValue[0]);
                const end = dayjs(this.internalValue[1]);

                console.log("baseStart, baseEnd", baseStart, baseEnd);
                console.log("start, end", start.format(this.datePickerFormat), end.format(this.datePickerFormat));

                if (baseStart && baseEnd) {
                    let unit = "day";
                    let baseDuration = 0;

                    if (this.detailInfo.timeDsvn === "HOURBYDAY") {
                        unit = "day";
                        baseDuration = baseEnd.diff(baseStart, "day") + 1;
                    } else if (this.detailInfo.timeDsvn === "DAYBYMONTH") {
                        unit = "month";
                        baseDuration = baseEnd.diff(baseStart, "month") + 1;
                    } else if (this.detailInfo.timeDsvn === "MONTHBYYEAR") {
                        unit = "year";
                        baseDuration = baseEnd.diff(baseStart, "year") + 1;
                    }

                    console.log("[baseDuration]", baseDuration, "[unit]", unit);

                    if (baseDuration > 0) {
                        const selectedDuration = end.diff(start, unit) + 1;
                        console.log("[selectedDuration]", selectedDuration);

                        if (selectedDuration < baseDuration) {
                            const adjustedEnd = start.add(baseDuration - 1, unit);
                            const formattedAdjustedEnd = adjustedEnd.format(this.datePickerFormat);
                            console.log("[adjustedEnd - shorter case]", adjustedEnd);

                            this.$bvToast.toast(
                                `비교기간이 기본기간(${baseDuration} ${unit})보다 짧습니다. 기본기간으로 설정됩니다.`,
                                {
                                    title: "기간 조정됨",
                                    variant: "warning",
                                    solid: true,
                                }
                            );

                            // this.isInternalUpdate = true;
                            this.internalValue = [this.internalValue[0], formattedAdjustedEnd];
                            this.$emit("input", this.internalValue);
                            // this.$nextTick(() => {
                            //     this.isInternalUpdate = false;
                            // });
                            return;
                        }

                        const validMultiplier = Math.floor(selectedDuration / baseDuration);
                        console.log("[validMultiplier]", validMultiplier);

                        const adjustedEnd = start.add(validMultiplier * baseDuration - 1, unit);
                        console.log("[adjustedEnd]", adjustedEnd);

                        const formattedAdjustedEnd = adjustedEnd.format(this.datePickerFormat);
                        console.log("[formattedAdjustedEnd]", formattedAdjustedEnd);

                        if (formattedAdjustedEnd !== this.internalValue[1]) {
                            this.$bvToast.toast(
                                `선택한 날짜가 기준 기간의 ${baseDuration} ${unit}(단위)의 배수로 조정되었습니다.`,
                                {
                                    title: "기간 조정됨",
                                    variant: "secondary",
                                    solid: true,
                                }
                            );

                            // this.isInternalUpdate = true;
                            this.internalValue = [this.internalValue[0], formattedAdjustedEnd];
                            this.$emit("input", this.internalValue);
                            // this.$nextTick(() => {
                            //     this.isInternalUpdate = false;
                            // });
                        }
                    }
                }
            }
        },
        disabledDate(date) {
            const baseEnd = this.baseDayTime?.[1] ? dayjs(this.baseDayTime[1]) : null;
            const baseStart = this.baseDayTime?.[0] ? dayjs(this.baseDayTime[0]) : null;

            if (this.detailInfo?.analysisMethod === "MNV") {
                if (this.detailInfo.mnvMethod === "Forecast" && baseEnd) {
                    return !dayjs(date).isAfter(baseEnd);
                } else if (this.detailInfo.mnvMethod === "Backcast" && baseStart) {
                    return !dayjs(date).isBefore(baseStart);
                }
            }
            return false; // 날짜 제한 없음
        },
        formatDate(value, format) {
            if (Array.isArray(value)) {
                // 범위 데이터일 경우
                return value.map((date) => dayjs(date).format(format));
            }
            // 단일 데이터일 경우
            return dayjs(value).format(format);
        },
        timeFormat(calendarType) {
            if (calendarType === "YEAR" || calendarType === "MONTHBYYEAR") {
                this.datePickerFormat = "YYYY";
            } else if (calendarType === "MONTH" || calendarType === "DAYBYMONTH") {
                this.datePickerFormat = "YYYY-MM";
            } else {
                this.datePickerFormat = "YYYY-MM-DD";
                this.minuteStep = null;
            }
        },
        transformCalendarType(calendarType) {
            if (calendarType === "MONTH" || calendarType === "DAYBYMONTH") {
                this.calendarTypeFordatePicker = "month";
            } else if (calendarType === "YEAR" || calendarType === "MONTHBYYEAR") {
                this.calendarTypeFordatePicker = "year";
            } else {
                this.calendarTypeFordatePicker = "date";
            }
        },
        updateValue(val) {
            // if (this.isInternalUpdate) {
            //      this.isInternalUpdate = false; // 내부 업데이트 플래그 초기화
            //      return;
            //  }
            // 배열로 된 날짜 범위일 경우
            if (Array.isArray(val) && val.length === 2) {
                const start = dayjs(val[0]);
                const end = dayjs(val[1]);

                const maxRange = this.getMaxRange();
                const difference = end.diff(start, maxRange.unit);

                if (difference > maxRange.limit) {
                    setTimeout(async () => {
                        await this.alertNoti(`${maxRange.message} 범위를 초과하였습니다. 최대 범위로 자동 조정됩니다.`);
                        this.internalValue = this.getTrimmedRange(start, maxRange.limit, maxRange.unit);
                        this.$emit("input", this.internalValue);
                    }, 100);
                } else {
                    this.internalValue = val;
                    this.$emit("input", this.internalValue);

                    this.adjustEndDateForMnv();
                }
            } else {
                // 단일 날짜일 경우
                this.internalValue = val;

                this.$emit("input", this.internalValue);
                //  this.adjustEndDateForMnv();
            }
        },
        getMaxRange() {
            if (this.calendarType === "YEAR" || this.calendarType === "MONTHBYYEAR") {
                return { limit: 100, unit: "year", message: "100년" };
            } else if (this.calendarType === "MONTH" || this.calendarType === "DAYBYMONTH") {
                return { limit: 36, unit: "month", message: "36개월" };
            } else if (this.calendarType === "DAY" || this.calendarType === "HOURBYDAY") {
                return { limit: 365, unit: "day", message: "365일" };
            }
            return { limit: 0, unit: "", message: "" };
        },
        getTrimmedRange(start, limit, unit) {
            const baseStart = this.baseDayTime && this.baseDayTime[0] ? dayjs(this.baseDayTime[0]) : start;
            const trimmedEnd = baseStart.add(limit, unit);
            return [baseStart.format("YYYY-MM-DD"), trimmedEnd.format("YYYY-MM-DD")];
        },
    },
};
</script>
