<template>
    <div class="row m-0">
        <!-- :transCodes="transCodes" -->
        <!-- @row-click="handleItemClick" 변수 수정 모달 구현 완료시 적용 -->
        <TableList
            ref="table-list"
            :title="'데이터 조정'"
            :columns="columns"
            :rows="adjustList"
            :keyField="'idx'"
            :useFilter="false"
            :fixedHeader="false"
            :controls="controls"
            :mode="'edit'"
            :useMaxHeight="true"
            :customMaxHeight="'20vh'"
            :isSort="false"
            @button-click="handleListButtonClick"
        />

        <b-modal ref="addDataAdjustModal" centered @hidden="hideDataAdjustModal">
            <template #modal-header>
                <h5 class="mb-0">{{ "데이터 조정 추가" }}</h5>
            </template>
            <div class="row m-0">
                <div class="col-12">
                    <label for="adjustName">{{ "데이터 조정" }}</label>
                    <input v-model="adjust.adjustName" class="form-control" type="text" id="adjustName" />
                </div>
                <div class="col-12 mt-2">
                    <label for="adjustReason">{{ "조정 사유" }}</label>
                    <textarea
                        v-model="adjust.adjustReason"
                        class="form-control"
                        type="text"
                        id="adjustReason"
                        row="3"
                        style="resize: none;"
                    />
                </div>
                <div class="col-12 mt-2">
                    <label for="description">{{ "설명" }}</label>
                    <textarea
                        v-model="adjust.description"
                        class="form-control"
                        type="text"
                        id="description"
                        row="3"
                        style="resize: none;"
                    />
                </div>
            </div>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button
                        class="btn btn-sm btn-primary"
                        style="flex: 1; margin-right: 0.5rem;"
                        @click="addDataAdjust"
                    >
                        {{ "추가" }}
                    </button>
                    <button
                        class="btn btn-sm btn-secondary"
                        style="flex: 1; margin-left: 0.5rem;"
                        @click="hideDataAdjustModal"
                    >
                        {{ "닫기" }}
                    </button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import TableList from "@views/component/v2.1/ListDetailView/TableList.vue";

export default {
    props: ["adjustList"],
    components: {
        TableList,
    },
    data() {
        return {
            controls: [
                { event: "regist", label: "추가", class: "btn-primary" },
                { event: "delete", label: "삭제", class: "btn-danger" },
            ],
            transCodes: [
                {
                    field: "unit",
                    codes: this.$store.state.units,
                },
            ],
            columns: [
                {
                    label: this.$t("데이터 조정"),
                    field: "adjustName",
                    type: "text",
                },
                {
                    label: this.$t("조정 사유"),
                    field: "adjustReason",
                    type: "text",
                },
                {
                    label: this.$t("설명"),
                    field: "description",
                    type: "text",
                },
            ],
            // adjustList: [],
            adjust: {
                adjustName: null,
                adjustReason: null,
                description: null,
            },
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    // beforeDestroy() {
    //     this.adjustList = [];
    // },
    methods: {
        async handleListButtonClick(event, options) {
            switch (event) {
                case "regist":
                    await this.registNew();
                    break;
                case "delete":
                    await this.deleteChecked();
                    break;
                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
        async registNew() {
            console.log("registNew");
            this.$refs["addDataAdjustModal"].show();
        },
        hideDataAdjustModal() {
            this.$refs["addDataAdjustModal"].hide();
            this.initAdjust();
        },
        addDataAdjust() {
            // this.adjustList.push(this.adjust);
            // this.$refs["addDataAdjustModal"].hide();
            // this.adjustList.push({ ...this.adjust });
            this.$emit("add-adjust-list", this.adjust);
            this.$emit("add-adjust-column", this.adjust.adjustName); // 상위 컴포넌트에 이벤트 발생
            this.$refs["addDataAdjustModal"].hide();
            this.initAdjust();
        },
        initAdjust() {
            this.adjust = {
                adjustName: null,
                adjustReason: null,
                description: null,
            };
        },
        async deleteChecked() {
            console.log("deleteChecked");

            const checked = this.$refs["table-list"].getCheckedRows();
            console.log(checked);
            if (this.isEmpty(checked)) this.alertNoti("삭제할 항목을 선택해 주세요.");
            else {
                console.log("this.adjustList", this.adjustList);
                console.log("checked", checked);
                let confirm = await this.alertConfirmWarning("선택한 목록을 삭제하시겠습니까?");
                if (!confirm.value) return;
                this.adjustList = this.adjustList.filter(
                    (adjust) => !checked.some((check) => check.adjustName === adjust.adjustName)
                );
            }
        },
    },
};
</script>
