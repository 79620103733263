<template>
    <div style="width: 100%; height: 100%;">
        <div ref="waterfallChart" style="width: 100%; height: 100%"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
// import moment from "moment";

export default {
    props: ["detailInfo", "resultData", "chartResize"],
    components: {},
    data() {
        return {
            chart: null,
            xAxisLabel: [],
            baseDataName: "",
            baseData: [], // 실측값, 소비량
            accData: [], // 누적량
            offSetDataset: [],
            // (timeDsvn = 'MONTH') 월간 목표 설정 / (timeDsvn = 'YEAR') 연간 목표 설정
            goalDataName: "",
            goalData: null,
            // if predict true => 예상 소비량 else => 소비량
            predictName: "",
            predictData: null,

            unitMap: {},
            findTargetHeader: null,
            baseUnit: null,
        };
    },
    computed: {},
    watch: {
        resultData() {
            this.prepareChartData();
        },
        chartResize() {
            if (this.chart) {
                this.chart.resize(); // chartResize 변경 시 차트를 리사이즈
            }
        },
    },
    created() {},
    mounted() {
        this.prepareChartData();
    },
    methods: {
        isDateAfterOrEqual(xAxisValue, timeDsvn, currentDate) {
            if (timeDsvn === "MONTHBYYEAR") {
                // 현재 연월 기준 비교
                const currentYearMonth = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
                    2,
                    "0"
                )}`;
                return xAxisValue >= currentYearMonth;
            } else if (timeDsvn === "DAYBYMONTH") {
                // 현재 날짜 기준 비교
                const currentFullDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
                    2,
                    "0"
                )}-${String(currentDate.getDate()).padStart(2, "0")}`;
                return xAxisValue >= currentFullDate;
            }
            return false;
        },
        async prepareChartData() {
            this.predictData = [];
            const currentDate = new Date();
            const mainVariable = this.detailInfo.variables.find((variable) => variable.roleType === "Main");
            if (!mainVariable) {
                console.error("Main variable not found");
                return;
            }

            if (!this.isEmpty(this.resultData) && !this.isEmpty(this.resultData.headers)) {
                // x 축 라벨 세팅
                this.xAxisLabel = this.resultData.values.regDt;

                this.findTargetHeader = this.resultData.headers.find((header) => header.ptIdx == mainVariable.ptIdx);
                const findGoalHeader = this.resultData.headers.find((header) => header.roleType == "Goal");

                if (mainVariable.ptIdx) {
                    // 사용량
                    if (!this.isEmpty(this.findTargetHeader)) {
                        this.baseData = this.resultData.values[this.findTargetHeader.key].map((usage, index) => {
                            const xAxisValue = this.xAxisLabel[index];
                            return this.isDateAfterOrEqual(xAxisValue, this.detailInfo.timeDsvn, currentDate)
                                ? null
                                : usage;
                        });
                        this.baseDataName = this.findTargetHeader.ptName;
                        this.baseUnit = this.$store.state.units.find(
                            (unit) => unit.value == this.findTargetHeader.unitSmallCode
                        );
                        this.unitMap[this.baseDataName] = this.findTargetHeader.dispUnit
                            ? this.findTargetHeader.dispUnit
                            : this.baseUnit.text;
                        // 누적량
                        this.accData = this.resultData.accumulate[this.findTargetHeader.key].map((acc, index) => {
                            const xAxisValue = this.xAxisLabel[index];
                            if (this.isDateAfterOrEqual(xAxisValue, this.detailInfo.timeDsvn, currentDate)) {
                                return null; // 현재 기준 포함 이후 데이터는 숨김
                            }
                            const usage = this.resultData.values[this.findTargetHeader.key][index];
                            return usage ? (acc - usage).toFixed(1) : null;
                        });
                    }

                    // 비교 추이
                    this.offSetDataset = this.resultData.comparison.offset ?? [];
                    // 목표량
                    if (!this.isEmpty(findGoalHeader)) {
                        this.goalData = this.resultData.accumulate[findGoalHeader.key];
                        this.goalDataName = findGoalHeader.ptName ?? findGoalHeader.key;
                        const goalUnit = this.$store.state.units.find(
                            (unit) => unit.value == findGoalHeader.unitSmallCode
                        );
                        this.unitMap[this.goalDataName] = findGoalHeader.dispUnit
                            ? findGoalHeader.dispUnit
                            : goalUnit.text;
                    }
                    // 예측량
                    if (!this.isEmpty(this.resultData.predict)) {
                        this.predictData = Array(
                            this.resultData.values.regDt.length - this.resultData.predict.regDt.length
                        )
                            .fill(null)
                            .concat(this.resultData.predict[this.findTargetHeader.key]);
                    } else {
                        this.predictData = [];
                    }
                }
            }

            await this.renderChart();
        },
        renderChart() {
            if (this.chart) {
                // 기존 차트를 초기화
                this.chart.clear();
            } else {
                // ECharts 인스턴스 생성
                this.chart = echarts.init(this.$refs.waterfallChart);
            }

            const detailInfo = this.detailInfo;
            const unitMap = this.unitMap;
            const baseUnit = this.findTargetHeader.dispUnit ? this.findTargetHeader.dispUnit : this.baseUnit.text;
            const goalData = this.goalData;
            const predictData = this.predictData;

            const series = [
                {
                    name: "증감량",
                    type: "bar",
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                    data: this.offSetDataset,
                    itemStyle: {
                        color: (params) => (params.value >= 0 ? "#EF9A9A" : "#90CAF9"),
                    },
                },
                {
                    name: `${this.baseDataName} 누적량`,
                    type: "bar",
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    stack: "total",
                    itemStyle: {
                        color: "#ececec",
                    },
                    emphasis: {
                        itemStyle: {
                            color: "#ececec",
                        },
                    },
                    data: this.accData,
                },
                {
                    name: this.baseDataName,
                    type: "bar",
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    stack: "total",
                    label: {
                        show: true,
                        position: "inside",
                        formatter: (params) => {
                            return this.formatValue(params.data);
                        },
                    },
                    itemStyle: {
                        color: "#5470C6",
                    },
                    data: this.baseData,
                },
                {
                    name: this.goalDataName,
                    type: "line",
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    smooth: 0.4,
                    data: this.goalData,
                    itemStyle: {
                        color: "orange",
                    },
                    lineStyle: {
                        type: "dashed",
                        width: 1,
                    },
                    label: {
                        show: true,
                        position: "insideBottom",
                        formatter: (params) => {
                            if (params.dataIndex === goalData.length - 1) {
                                const label =
                                    detailInfo.timeDsvn == "DAYBYMONTH"
                                        ? `월간 목표 설정(${baseUnit}) : ${this.formatValue(
                                              goalData[goalData.length - 1]
                                          )}`
                                        : `연간 목표 설정(${baseUnit}) : ${this.formatValue(
                                              goalData[goalData.length - 1]
                                          )}`;
                                return label;
                            }
                            return "";
                        },
                        fontSize: 12,
                        fontWeight: "bold",
                    },
                },
            ];

            if (!this.isEmpty(this.predictData)) {
                series.push({
                    name: "예상 소비량",
                    type: "line",
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    smooth: 0.4,
                    data: this.predictData,
                    z: 1,
                    itemStyle: {
                        color: "#F48FB1",
                    },
                    lineStyle: {
                        type: "dashed",
                        width: 1,
                    },
                    label: {
                        show: true,
                        position: "insideTop",
                        formatter: (params) => {
                            if (params.dataIndex === predictData.length - 1) {
                                const label = `예상 소비량(${baseUnit}) : ${this.formatValue(
                                    predictData[predictData.length - 1]
                                )}`;
                                return label;
                            }
                            return "";
                        },
                        fontSize: 12,
                        fontWeight: "bold",
                    },
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: "#F48FB1" },
                            { offset: 1, color: "#F48FB1" + "00" },
                        ]),
                    },
                });
            }

            const option = {
                grid: [
                    { left: "9%", right: "9%", top: "5%", height: "5%" },
                    { left: "9%", right: "9%", top: "30%", bottom: "0%", height: "55%" },
                ],
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        label: {
                            backgroundColor: "#6a7985",
                        },
                    },
                    formatter: (params) => {
                        let result = `<div style="font-size: 12px; width: 250px;">
                                        <div class="mb-3">
                                            <p class="m-0 p-0">${params[0].axisValue}</p>`;
                        params.forEach((param) => {
                            result += `<div style="display: flex; justify-content: space-between;">
                                                <div>
                                                    ${param.marker}
                                                    <span>
                                                        ${param.seriesName} 
                                                        (${
                                                            unitMap[param.seriesName]
                                                                ? unitMap[param.seriesName]
                                                                : baseUnit
                                                        })
                                                    </span>
                                                </div>
                                                <span style="font-weight: bold;">
                                                    ${this.formatValue(param.data)}
                                                </span>
                                        </div>`;
                        });

                        result += `</div></div>`;
                        return result;
                    },
                },
                axisPointer: {
                    link: [
                        {
                            xAxisIndex: "all",
                        },
                    ],
                },
                xAxis: [
                    {
                        gridIndex: 0,
                        type: "category",
                        data: this.xAxisLabel,
                        boundaryGap: true,
                        axisLabel: { show: false },
                    },
                    {
                        gridIndex: 1,
                        type: "category",
                        boundaryGap: true,
                        axisLine: { onZero: true },
                        data: this.xAxisLabel,
                        axisLabel: {
                            align: "center",
                        },
                    },
                ],
                yAxis: [
                    { type: "value", gridIndex: 0, axisLabel: { show: false }, splitLine: { show: false } },
                    {
                        gridIndex: 1,
                        type: "value",
                        min: 0,
                        axisLabel: {
                            align: "right",
                            formatter: (value) => {
                                return this.formatValue(value);
                            },
                        },
                    },
                ],
                series: series,
            };
            this.chart.setOption(option);
            window.addEventListener("resize", () => {
                this.chart.resize();
            });
        },
        formatValue(value) {
            if (value >= 1e9) {
                return Number.isInteger(value / 1e9) ? value / 1e9 + "G" : (value / 1e9).toFixed(1) + "G";
            } else if (value >= 1e6) {
                return Number.isInteger(value / 1e6) ? value / 1e6 + "M" : (value / 1e6).toFixed(1) + "M";
            } else if (value >= 1e3) {
                return Number.isInteger(value / 1e3) ? value / 1e3 + "K" : (value / 1e3).toFixed(1) + "K";
            } else {
                return Number.isInteger(value) ? value.toString() : Number(value).toFixed(1);
            }
        },
    },
};
</script>
