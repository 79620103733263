<template>
    <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
        <b-button
            :ref="'searchBtn'"
            squared
            class="m-l-5 m-r-5"
            variant="#ffffff"
            size="md"
            @click="onSearch()"
            style="border:2px solid #348fe2; border-radius: 10px!important;"
            :style="{
                padding: '5px',
                height: '34px',
                textAlign: 'center',
                backgroundColor: '#ffffff',
                alignItems: 'center',
                width: '55px',
                height: '100%',
                padding: 0,
                color: '#348fe2',
            }"
        >
            <span>{{ $t("조회") }}</span>
        </b-button>

        <b-button
            :ref="'saveBtn'"
            squared
            class="m-l-5 m-r-5"
            variant="#ffffff"
            size="md"
            @click="onSave()"
            style="border:2px solid #348fe2; border-radius: 10px!important;"
            :style="{
                padding: '5px',
                height: '34px',
                textAlign: 'center',
                backgroundColor: '#ffffff',
                alignItems: 'center',
                width: '55px',
                height: '100%',
                padding: 0,
                color: '#348fe2',
            }"
        >
            <span>{{ $t("저장") }}</span>
        </b-button>

        <b-button
            :ref="'removeBtn'"
            squared
            class="m-l-5 m-r-5"
            variant="#ffffff"
            size="md"
            @click="onRemove()"
            style="border:2px solid #348fe2; border-radius: 10px!important;"
            :style="{
                padding: '5px',
                height: '34px',
                textAlign: 'center',
                backgroundColor: '#ffffff',
                alignItems: 'center',
                width: '55px',
                height: '100%',
                padding: 0,
                color: '#348fe2',
            }"
        >
            <span>{{ $t("삭제") }}</span>
        </b-button>
    </div>
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";

export default {
    props: [],
    components: {},
    watch: {},
    data() {
        return {};
    },
    created() {},
    mounted() {},
    methods: {
        onSearch() {
            this.$emit("search");
        },
        onSave() {
            this.$emit("save");
        },
        onRemove() {
            this.$emit("delete");
        },
        isValidation() {},
    },
};
</script>

<style scoped></style>
